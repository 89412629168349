import React from 'react';
import { Helmet } from 'react-helmet';
import { Concerts } from '../firebase';

export class DatabaseEditing extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      peopleIndex: {},
      concert: "",
      currentAction: ""
    }
  }

  async componentDidMount() {
    Concerts.getDatabaseConcerts()
      .then(newConcert => this.setState({ peopleIndex: newConcert }))
      .catch(error => console.error('Error fetching database concerts:', error.message));
  }

  updateActionToPeople = (key) => {
    this.setState({concert: key, currentAction: "peopleIndex"});
  }

  render() {
    let peopleIndex = [];
    peopleIndex.push(<p>This is where you can index the people for a given concert. </p>)
    if (this.state.peopleIndex.length > 0){
      for (let i = 0; i < this.state.peopleIndex.length; i++){
        peopleIndex.push(
          <p key={i}>{this.state.peopleIndex[i].key} {this.state.peopleIndex[i].programLink === "No Program" ? "No program" : "Has a program"} 
            <button onClick={() => this.updateActionToPeople(this.state.peopleIndex[i].key)}>Rewrite People</button></p>
        )
      }
    }
    return (
      <div id='databaseEditing'>
        <Helmet>
          <title>Database Editing</title>
        </Helmet>
        <h1>Database Editing</h1>
        {this.state.currentAction === "" && (
          <>
            <p>Hello! This page will allow you to do two things. The first is to index and create people for each concert, and the second is to 
              index into the database. Features are still coming, as I try to figure out the best way to do it. </p>
            {peopleIndex}
          </>
        )}
        {this.state.currentAction === "peopleIndex" && (
          <>
            <p>You are trying to index the people of a concert. </p>
          </>
        )}
        {this.state.currentAction === "databaseIndex" && (
          <>
            <p>You are trying to index a program (or lack thereof).</p>
          </>
        )}
      </div>
    );
  }
}
