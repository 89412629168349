import React from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from './_Context';
import { Helmet } from 'react-helmet';

export class Work extends React.Component {
  static contextType = AppContext;

  render() {
    const context = this.context;
    return (
      <div id='workPage'>
        <h1>Work</h1>
        <Helmet>
          <title>Work</title>
        </Helmet>
          {context.user.sl >= 3 && (
            <div id='fullButtons'>
              <Link to="/work/audits"><div><p>Audit</p></div></Link>
              {(context.user.sl === 4 || context.user.sl === 9) && (
                <Link to="/work/database"><div><p>Database</p></div></Link>
                )}
              {(context.user.sl === 5 || context.user.sl === 9) && (
                <Link to="/work/directors"><div><p>Directors</p></div></Link>
              )}
              {(context.user.sl === 5 || context.user.sl === 8 || context.user.sl === 9) && (
                <>
                  <Link to="/work/scorelibrary"><div><p>Score Library</p></div></Link>
                </>
              )}
              {(context.user.sl === 7 || context.user.sl === 9) && (
                <Link to="/work/sherry"><div><p>Sherry</p></div></Link>
              )}
            </div>
          )}
        <p>If you're seeing this (and expecting something more), check to make sure that you're signed in. Otherwise... you can request to help audit or
          do database stuff in your Account page.  </p>
      </div>
    );
  }
}
