import React from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';
import { AdminChecks, CalendarEvents } from '../firebase';
import { Helmet } from 'react-helmet';
import { AppContext } from './_Context';
import { formDataToObject } from "./_Tiny Functions.ts";

export class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      formCount: 0
    }
  }
  static contextType = AppContext;

  handleSubmit = async (event) => {
    event.preventDefault();
    const form = document.getElementById('calendarRequestForm');
    const formData = new FormData(form);

    let data = formDataToObject(formData);

    data["type"] = "calendarEventRequest";

    await AdminChecks.importAdminCheckCalendar(data);
    form.reset();
    document.getElementById('calendarPage').scrollTop = 0;
    this.setState({ formCount: this.state.formCount + 1 });
  }

  async componentDidMount() {
    try {
      const calendarEvents = await CalendarEvents.getCalendarEvents();
      this.setState({
        events: calendarEvents
      });
    } catch (error) {
      console.error('Error fetching calendar information:', error.message);
    }
  }

  handleEventClick = (event) => {
    // Do something with the event object
    console.log(event);
  };


  render() {
    let context = this.context;
    let events = this.state.events;
    if (events.length > 0) {
      for (let i = 0; i < events.length; i++) {
        let string = events[i].classNames.split(' ').join('');
        events[i]["classNames"] = events[i]["classNames"].split(' ').join('');
        events[i]["backgroundColor"] = switchBackgroundColor(string);
      }
    }
    return (
      <div id='calendarPage'>
        <Helmet>
          <title>Snow Calendar</title>
        </Helmet>
        <h1>Calendar</h1>
        <p>Please note that this is an unofficial calendar. The official Snow College music calendar <a href='https://www.snow.edu/academics/fineart/music/calendar.html' target='_blank' rel='noreferrer'>can be found here</a>.
          This calendar will just have what I and others submit, though the official calendar also has all the recitals. </p>
        <p>Locations: <span className='ConcertHall'>Concert Hall</span>, <span className='RecitalHall'>Recital Hall</span>, <span className='Theatre'>Jorgensen Theatre</span>, <span className='BlackBox'>Black Box</span>, and <span className='Other'>Other</span>.</p>
        <div id='forDesktopUsers'>
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView='dayGridMonth'
            weekends={true}
            events={events}
            eventContent={renderEventContent}
            eventClick={this.handleEventClick}
            contentHeight={"800px"}
          />
        </div>
        <div id='forMobileUsers'>
          <p>Please use the desktop site for a full calendar view. This is the list of events for the semester. </p>
          <FullCalendar
            plugins={[listPlugin]}
            initialView='listMonth'
            weekends={true}
            events={events}
            eventContent={renderEventContent}
          />
        </div>
        <div id='calendarRequest'>
          <p>Is there an event that isn't on the calendar? Please request it using this form: </p>
          <form id='calendarRequestForm'>
            <label htmlFor='submitter'>Your Name: </label>
            <input type='text' name='submitter' defaultValue={context.user.name} />
            <br />
            <label htmlFor='title'>Name of Concert: </label>
            <input type='text' name='title' required />
            <br />
            <label htmlFor='start'>Date: </label>
            <input type='datetime-local' name='start' required />
            <br />
            <label htmlFor='location'>Location: </label>
            <select name='location' required>
              <option value={"Concert Hall"}>Concert Hall</option>
              <option value={"Recital Hall"}>Recital Hall</option>
              <option value={"Black Box"}>Black Box Theatre</option>
              <option value={"Theatre"}>Jorgensen Theatre</option>
              <option value={"Other"}>Other</option>
            </select>
            <br />

            <button type='submit' onClick={this.handleSubmit}>Submit</button>
          </form>
          {this.state.formCount > 0 && (
            <p>Number of Concerts Requested: {this.state.formCount}</p>
          )}
        </div>
      </div>
    );
  }
};

function renderEventContent(eventInfo) {
  return (
    <span><b>{eventInfo.timeText}</b>  <span>{eventInfo.event.title}</span></span>
  )
}

function switchBackgroundColor(classname) {
  switch (classname) {
    case "ConcertHall":
      return "#add8e6";
    case "RecitalHall":
      return "#f08080";
    case "BlackBox":
      return "#d3d3d3";
    case "Theatre":
      return "#acf2ac";
    case "Other":
      return "#000000";
    default:
      return "#000000";
  }
}