import React from "react";
import { Link } from "react-router-dom";

export class Community extends React.Component {
  render() {
    return (
      <div id='communityPage'>
        <h1>Community</h1>
        <div id='fullButtons'>
          <Link to="composerchallenge"><div><p>Composition Challenge</p></div></Link>
        </div>
        <p>Hi! There might be more here later, but for now, there's just the monthly Composition Challenge. Every month,
          I'll update the challenge to something new, and I hope you can use it to exercise your composition ability or get
          back into composing. </p>
        <p>The first half of the year (Fall semester) will be assorted challenges, such as using a specific scale,
          having a specified topic, or otherwise having a restriction. The second half of the year (Spring semester),
          I'll be putting up major groups to write for. This is Choral, Band, Jazz Band, and Orchestra. You can choose to
          either write for a large group or a smaller subsection of them, such as a chamber group or quartet or trio. This
          is meant to help your portfolio.</p>
        <p>As for what you send to me (if you choose to display your composition to the public), I just need your name and
          your composition. For the most part, this is designed for sheet music and classical instruments, so I want a score
          and a recording (I would recommend MuseScore). If you're a DAW enthusiast, you can just send a recording of your piece. </p>
        <p>Please send your composition to: composition@codydhowell.com <br />
          I need what you want your name to be and a recording, with an optional score. I would suggest making a Drive folder and
          sending me the share link (make sure it's open to anyone with the link). </p>
        <p>For now, the best way I can display any public compositions is through a Drive folder of my own, though I hope that will change
          in the future. Have fun composing!</p>
      </div>
    )
  }
}