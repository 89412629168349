import React from 'react';
import { Helmet } from 'react-helmet';
import { AuthService, AdminChecks } from '../firebase';
import { AppContext } from './_Context';
import { HomeCalendarView } from './HomeCalendarView.tsx';

export class Account extends React.Component {
  static contextType = AppContext;
  handleEmailSubmission = (event) => {
    event.preventDefault();
    let email = document.querySelector("#email").value;
    AuthService.sendSignInLinkToEmail(email);
    document.querySelector('#emailSent').innerHTML = "Email sent!";
  }
  
  handleRequestUpdate = async (event) => {
    event.preventDefault();
    const context = this.context;
    let newSL = document.querySelector("#securityLevel").value;
    let explanationText = document.querySelector("#requestText").value;
    let obj = {};
    obj["cursl"] = context.user.sl;
    obj["newsl"] = newSL;
    obj["text"] = explanationText;
    obj["email"] = context.user.email;
    obj["uid"] = context.user.uid;
    await AdminChecks.addUserRequest(obj);

    window.location.reload();
  }

  updateName = async (event) => {
    event.preventDefault();
    let name = document.querySelector("#name").value;

    let obj = {};
    obj["name"] = name;
    obj["email"] = this.context.user.email;
    obj["uid"] = this.context.user.uid;

    await AdminChecks.addUserNameRequest(obj);
    window.location.reload();
  }


  render() {
    const context = this.context;
    return (
      <div id='accountPage'>
        <Helmet>
          <title>Account Page</title>
        </Helmet>
        <h1>Account</h1>

        {context.user.sl <= 0 ? (
          <>
            <p>This form will send you an email. It'll have a link in it, and once you open that, you'll be signed in ON THE BROWSER YOU OPEN THE EMAIL IN. Please keep that in mind.  </p>
            <form id='emailInputForm'>
              <label htmlFor='email'>Email: </label>
              <input type='email' name='email' id='email' />
              <button onClick={this.handleEmailSubmission}>Submit</button>
            </form>
    
            <p id='emailSent'></p>

            <p>The level of access you have is: 0</p>
            <p className='levelDescriptions'>0: Access to all audio/visual and database reading. Can see Calendar, and specific parts of the People input. </p>
            <p className='levelDescriptions'>1: Signed in with any email. Gives you access to Playlist features. Can create 3 playlists. </p>
            <p className='levelDescriptions'>2: Signed in with your Snow College email. Gives you access to everything above, as well as requests for 
            Challenges, Analysis, and more fine-tuned Database features (all coming soon). Can create 6 playlists. </p>
            <p className='levelDescriptions'>3: Level of Auditor. Allows you access to the page where concerts are posted before anyone checks them 
            and they go public. They could show up in this queue as soon as the night of. Once someone listens to it, it becomes public. Once enough people audit it,
            it is removed from the queue. Can create 9 playlists.  </p>
            <p className='levelDescriptions'>4: Level of Database Writer. Allows you access to write to the database. Can create 12 playlists. </p>
            <p className='levelDescriptions'>5: Level of Director. This allows you access to more specific (and more costly) Database functions for use
            of planning concerts, more in the People category, and perhaps eventually, access to a read-only database of the Score Library. Can create 15 playlists. </p>

            <hr/>
            <p>You can sign in with your preferred email, but if you're a Snow student and use that email, you get some additional benefits. Once you sign in, you can request further access levels, no matter your email. </p>
          </>
          ) : (
          <>
            <p>Signed in! The email you are signed in under is: {context.user.email}</p>
            <p>Your current display name (for Playlists, Requests, and Challenges): <input type='text' name='name' id='name' defaultValue={context.user.name} placeholder='Put name here'/> <button onClick={this.updateName}>Update Name</button></p>
            <p style={{padding: 3}}>Submitting a name will send a request to the Admin. It will be displayed once they accept your name request. Otherwise, the username shown will just be a random string of characters. </p>
            <p>Are you a student: <input type='checkbox' checked={context.user.isStudent} readOnly/></p>
            <p>The level of access you have is: {context.user.sl}</p>

            <button onClick={AuthService.signOutUser}>Sign Out Here</button>

            <p className='levelDescriptions'>0: Access to all audio/visual and database reading. Can see Calendar, and specific parts of the People input. </p>
            <p className='levelDescriptions'>1: Signed in with any email. Gives you access to Playlist features. Can create 3 playlists. </p>
            <p className='levelDescriptions'>2: Signed in with your Snow College email. Gives you access to everything above, as well as requests for 
            Challenges, Analysis, and more fine-tuned Database features (all coming soon). Can create 6 playlists. </p>
            <p className='levelDescriptions'>3: Level of Auditor. Allows you access to the page where concerts are posted before anyone checks them 
            and they go public. They could show up in this queue as soon as the night of. Once someone listens to it, it becomes public. Once enough people audit it,
            it is removed from the queue. Can create 9 playlists.  </p>
            <p className='levelDescriptions'>4: Level of Database Writer. Allows you access to write to the database. Can create 12 playlists. </p>
            <p className='levelDescriptions'>5: Level of Director. This allows you access to more specific (and more costly) Database functions for use
            of planning concerts, more in the People category, and perhaps eventually, access to a read-only database of the Score Library. Can create 15 playlists. </p>

            <hr/>
            <form id='upgradeUser'>
              <h2>Access Level Request</h2>
              <p>This form will go to the admin. Please let me know, if you haven't talked to me in another channel (find details in About), 
                why you're requesting the level you are. </p>
              <label htmlFor='securityLevel'>New Level: </label>
              <select name='securityLevel' id='securityLevel'>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </select>
              <br/>
              <div className='centeredInputs'>
                <label htmlFor='requestText'>Explain your request: </label>
                <textarea name='requestText' id='requestText' placeholder='I want this access because...'/>
              </div>
              <button onClick={this.handleRequestUpdate} >Submit</button>
            </form>
          </>
        )}
      </div>
    );
  }
};
