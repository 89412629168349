import React from 'react';
import { Canvas } from '@react-three/fiber';
import { Material, Mesh, Object3D, Object3DEventMap, Texture, Color, Vector3 } from 'three';
import { GroupedControlCanvas } from './GroupedControlCanvas.tsx';
import { StageWrapper, Cloud1Wrapper, Cloud2Wrapper, Cloud3Wrapper, Cloud4Wrapper } from './ModelWrapper';
import * as tf from '@tensorflow/tfjs';

type StageBuilderState = {
  lightDesign: {
    clouds: Array<RAGBColoredLight>,
    spotlights: Array<Spotlight>
  },
  stageDesign: {

  },
  ambientValue: number,
  advanced: boolean,
  amber: boolean,
  sandboxMode: boolean, 
  cloudHeight: Array<number>,
}


class StageBuilder extends React.Component<{}, StageBuilderState> {
  private color: Color;
  private lightPositions: Array<Array<number>>;
  private lowerLightObjects: Array<Object3D>;
  private upperLightObjects: Array<Object3D>;
  private spotlightObjects: Array<Object3D>;
  

  constructor(props) {
    super(props);
    let clouds = Array(22).fill({ color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 }); // Used to be defined as below, but since I'm overwriting anyways...
    let spotlights = Array(10).fill({ intensity: 0, positionX: 0, target: { x: 0, y: 0 }, angle: 0.4 });
    this.state = {
      stageDesign: {},
      lightDesign: {
        clouds: clouds,
        spotlights: spotlights
      },
      ambientValue: 0,
      advanced: true,
      amber: true,
      sandboxMode: false, 
      cloudHeight: [6, 6, 6, 6]
    }
    this.color = new Color(0x000000);
    this.lightPositions = [
      [2, 4.52], 
      [2, 2.41], 
      [2, 0], 
      [2, -2.41], 
      [2, -4.52],
      [4.5, 5],
      [4.5, 3],
      [4.5, 0],
      [4.5, -3],
      [4.5, -5],
      [7.3, 5.5],
      [7.3, 3],
      [7.3, 0],
      [7.3, -3],
      [7.3, -5.5],
      [9.7, 6],
      [9.7, 4.3],
      [9.7, 2.6],
      [9.7, 0],
      [9.7, -2.6],
      [9.7, -4.3],
      [9.7, -6]
    ]
    this.lowerLightObjects = this.lightPositions.map((position) => {
      let lowerPrimitive = new Object3D();
      lowerPrimitive.position.set(position[0], 0, position[1]);
      return lowerPrimitive;
    });
    this.upperLightObjects = this.lightPositions.map((position) => {
      let upperPrimitive = new Object3D();
      upperPrimitive.position.set(position[0], 20, position[1]);
      return upperPrimitive;
    });
    this.spotlightObjects = spotlights.map((value) => {
      let spotOb = new Object3D();
      spotOb.position.set(value.target.x, 0, value.target.y);
      return spotOb;
    })
  }

  updateAmbient = (event) => {
    this.setState({ ambientValue: event.target.value });
  }

  updateHeight = (event, index) => {
    let array = this.state.cloudHeight;
    array[index] = event.target.value;
    this.setState({cloudHeight: array});
  }

  toggleAdvanced = () => {
    this.setState({ advanced: !this.state.advanced });
  }

  toggleAmber = () => {
    this.setState({ amber: !this.state.amber });
  }

  toggleSandbox = () => {
    this.setState({ sandboxMode: !this.state.sandboxMode });
  }

  // updateStageDesign = (string) => {
  //   this.setState({stageDesign: string});
  // }

  updateLightDesign = (design: StageBuilderState["lightDesign"]) => { // THIS IS COOL
    this.setState({ lightDesign: design });
  }

  render() {
    let targets: Array<Object3D> = [];
    let primitives: React.ReactNode[] = [];
    for (let i = 0; i < this.state.lightDesign.spotlights.length; i++) {
      let spotlight = this.state.lightDesign.spotlights[i];
      let target = this.spotlightObjects[i];
      target.position.set(spotlight.target.x, 0.2, spotlight.target.y);
      targets.push(target);
      primitives.push(<primitive object={target} />);
    }

    let spotlights: React.ReactNode[] = [];
    for (let i = 0; i < this.state.lightDesign.spotlights.length; i++) {
      let spotlight = this.state.lightDesign.spotlights[i];
      spotlights.push(<spotLight position={[15, 10, spotlight.positionY]} angle={spotlight.angle} penumbra={1} decay={2} color={"#ffeeee"} intensity={Math.PI * 2} power={3000 * spotlight.intensity / 100} target={targets[i]} />)
    }

    let houseLights: React.ReactNode[] = [];

    for (let i = 0; i < 22; i++){
      let position = this.lightPositions[i];

      let cloud = this.state.lightDesign.clouds[i];
      let convertedColor = convertColor(cloud.color.r, cloud.color.g, cloud.color.b);

      let height: number;
      if (i < 5) {height = this.state.cloudHeight[0];}
      else if (i < 10) {height = this.state.cloudHeight[1];}
      else if (i < 15) {height = this.state.cloudHeight[2];}
      else {height = this.state.cloudHeight[3] + 0.1;}

      houseLights.push(
        <>
          <pointLight position={[position[0], height / 2, position[1]]} color={convertedColor} power={260 * cloud.intensity / 100} key={i + "houseArea"} />
          <spotLight position={[position[0], height, position[1]]} angle={0.3} penumbra={1} decay={2} color={convertedColor} intensity={Math.PI * 2} power={100 * cloud.intensity / 100} target={this.lowerLightObjects[i]} key={i + "lowerSpot"} />
          <spotLight position={[position[0], height - 2, position[1]]} angle={0.07} penumbra={1} decay={2} color={convertedColor} intensity={Math.PI * 2} power={200 * cloud.intensity / 100} target={this.upperLightObjects[i]} key={i + "upperSpot"} />
        </>
      )

      primitives.push(
      <>
        <primitive object={this.lowerLightObjects[i]} key={i + "lowerPrimitive"}/>
        <primitive object={this.upperLightObjects[i]} key={i + "upperPrimitive"}/>
      </>
      )

    }

    // let color = this.state.lightDesign.clouds[0].color;
    // let convertedColor = `#${Math.round(Math.sqrt(Math.sqrt(color.r / 100)) * 255).toString(16).padStart(2, '0')}${Math.round(Math.sqrt(Math.sqrt(color.g / 100)) * 255).toString(16).padStart(2, '0')}${Math.round(Math.sqrt(Math.sqrt(color.b / 100)) * 255).toString(16).padStart(2, '0')}`;
    // houseLights.push(<>
    //   <spotLight position={[5, 6, 0]} angle={0.4} penumbra={1} decay={2} color={convertedColor} intensity={Math.PI * 2} power={50 * this.state.lightDesign.clouds[0].intensity / 100} target={houseTargets[0]} key={1} />
    //   <pointLight position={[5, 3, 0]} color={convertedColor} power={400 * this.state.lightDesign.clouds[0].intensity / 100} key={2} />
    // </>);
    // primitives.push(<primitive object={houseTarget} />);

    return (
      <div id='stageBuilder'>
        <h1>Stage Builder (beta)</h1>
        <p>This page allows you to design lights (stage plots are coming later). Hopefully things are self explanatory. And it's all running
          on your computer, so you can do whatever you want! <br />
          When dealing with Grouped cloud lights, to force all values to be the same as the input, for Default it works automatically,
          but in Advanced, it only adjusts values you change on the sliders. Simply select Red, press Enter, press Tab, enter, tab, etc.
          I may be able to fix this in the future.  </p>
        <div style={{ border: "1px solid black", borderRadius: "5px", padding: "4px" }}>
          <label htmlFor='ambientSlider'>Ambient Light Slider: </label>
          <input type='range' name='ambientSlider' id='ambientSlider' defaultValue={this.state.ambientValue} min={0} max={1.5} step={0.05} onChange={this.updateAmbient} /> 
          <div id='cloudHeightSelector'>
            <label htmlFor='cloud1'>Cloud 1 Height: </label>
            <input type='range' name='cloud1' id='cloud1' defaultValue={this.state.cloudHeight[0]} min={1} max={6} step={0.05} onChange={(event) => this.updateHeight(event, 0)} /> 
            <label htmlFor='cloud2'>Cloud 2 Height: </label>
            <input type='range' name='cloud2' id='cloud2' defaultValue={this.state.cloudHeight[1]} min={0.5} max={6} step={0.05} onChange={(event) => this.updateHeight(event, 1)} /> 
            <label htmlFor='cloud3'>Cloud 3 Height: </label>
            <input type='range' name='cloud3' id='cloud3' defaultValue={this.state.cloudHeight[2]} min={0} max={6} step={0.05} onChange={(event) => this.updateHeight(event, 2)} /> 
            <label htmlFor='cloud4'>Cloud 4 Height: </label>
            <input type='range' name='cloud4' id='cloud4' defaultValue={this.state.cloudHeight[3]} min={-0.5} max={6} step={0.05} onChange={(event) => this.updateHeight(event, 3)} /> 
          </div>
          <button onClick={this.toggleAdvanced}>{this.state.advanced ? "Showing Advanced Color Picker" : "Showing Default Color Picker"}</button>
          {this.state.advanced && (<button onClick={this.toggleAmber}>{this.state.amber ? "Showing Amber" : "Hiding Amber"}</button>)}
          <br />
          <label htmlFor='sandboxModeCheckbox'>Sandbox Mode: </label>
          <input type='checkbox' onChange={this.toggleSandbox} checked={this.state.sandboxMode} />
          <span> ---&gt; Enables some extra features, such as moving the spotlight positions. <b>DO NOT ENABLE</b> sandbox mode
            if you're designing for a show, we will not move spotlights or do other sandbox features to fit your plan. </span>
        </div>
        {/* <button onClick={() => console.log(this.state)}>See State</button> */}
        <div id='threeDisplay'>
          <Canvas camera={{ fov: 90, near: 0.1, far: 1000, position: [25, 3, 0], zoom: 2 }} linear={true} scene={{ background: this.color }} style={{ width: "900px", height: "500px" }}>
            {/* <Canvas camera={{ fov: 100, near: 0.1, far: 1000, position: [20, 4.5, 0], zoom: 2 }} linear={true} scene={{ background: this.color }} style={{width: "900px", height: "500px"}}> */}
            {StageWrapper()}
            {Cloud1Wrapper(this.state.cloudHeight[0])}
            {Cloud2Wrapper(this.state.cloudHeight[1])}
            {Cloud3Wrapper(this.state.cloudHeight[2])}
            {Cloud4Wrapper(this.state.cloudHeight[3])}
            <ambientLight intensity={this.state.ambientValue} />
            {houseLights}
            {spotlights}
            {primitives}
          </Canvas>
        </div>
        <div id='lightDesigner'>
          <LightDesigner updateState={this.updateLightDesign} advanced={this.state.advanced} amber={this.state.amber} sandbox={this.state.sandboxMode} />
        </div>
      </div>
    );
  }
}

class LightDesigner extends React.Component<{ updateState: Function, advanced: boolean, amber: boolean, sandbox: boolean },
  { design: StageBuilderState["lightDesign"], openBools: Array<boolean> }> {
  private checkArray: Array<Array<number>>;
  private groupingArray: Array<Array<number>>;
  // state.design allows openBools to also exist while using the same type definition from the top state

  constructor(props) {
    super(props);
    this.state = {
      design: {
        clouds: [ // Attempted to use Array(22).fill({object}), but it forced them all to be the same object. 
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 },
          { color: { r: 92, a: 0, g: 83, b: 77 }, intensity: 100 }
        ],
        spotlights: [
          { intensity: 0, positionY: 6, target: { x: 4, y: 4 }, angle: 0.3 },
          { intensity: 0, positionY: 5, target: { x: 11, y: 4 }, angle: 0.4 },
          { intensity: 0, positionY: 3, target: { x: 12, y: 2 }, angle: 0.1 },
          { intensity: 0, positionY: 1, target: { x: 12, y: 1 }, angle: 0.1 },
          { intensity: 0, positionY: 0, target: { x: 12, y: 0 }, angle: 0.1 },
          { intensity: 0, positionY: -1, target: { x: 12, y: -1 }, angle: 0.1 },
          { intensity: 0, positionY: -2, target: { x: 12, y: -2 }, angle: 0.1 },
          { intensity: 0, positionY: -3, target: { x: 12, y: -3 }, angle: 0.1 },
          { intensity: 0, positionY: -5, target: { x: 11, y: -4 }, angle: 0.4 },
          { intensity: 0, positionY: -6, target: { x: 4, y: -4 }, angle: 0.3 }
        ],
      },
      openBools: [true, false]
    }
    this.checkArray = [
      [0, 1, 2, 3, 4, 5, 0],
      [0, 6, 7, 8, 9, 10, 0],
      [0, 11, 12, 13, 14, 15, 0],
      [16, 17, 18, 19, 20, 21, 22]
    ]
    this.groupingArray = [
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
      [1, 2, 3, 4, 5],
      [6, 7, 8, 9, 10],
      [11, 12, 13, 14, 15],
      [16, 17, 18, 19, 20, 21, 22],
      [1, 2, 3, 4, 5, 6, 10, 11, 15, 16, 17, 21, 22],
      [7, 8, 9, 12, 13, 14, 18, 19, 20],
      [1, 5, 6, 10, 11, 15, 17, 18, 19, 20, 21],
      [2, 3, 4, 7, 8, 9, 12, 13, 14],
      [1, 3, 5, 7, 9, 11, 13, 15, 17, 18, 20, 21],
      [2, 4, 6, 8, 10, 12, 14, 16, 19, 22]
    ]
  }

  // async componentDidMount() {
  //   // Define a sequential model
  //   const model = tf.sequential();

  //   // Add the first dense layer with 12 nodes and input shape of [4]
  //   model.add(tf.layers.dense({ units: 12, inputShape: [4], activation: 'relu'}));

  //   // Add the second dense layer with 3 nodes (output layer)
  //   model.add(tf.layers.dense({ units: 3, activation: 'linear' }));

  //   // Compile the model with an optimizer and loss function
  //   model.compile({
  //     optimizer: 'adam',
  //     loss: 'meanSquaredError',
  //     metrics: ['mse']
  //   });

  //   let dataArray = data.split('\n');
  //   let inputColors: Array<Array<number>> = [];
  //   let outputColors: Array<Array<number>> = [];
  //   for (let i = 0; i < dataArray.length; i++){
  //     let line = dataArray[i].split(',').map((value) => parseInt(value));
  //     inputColors.push([line[1], line[2], line[3], line[4]]);
  //     outputColors.push([line[6], line[7], line[8]]);
  //   }

  //   // Convert data to tensors
  //   const xs = tf.tensor2d(inputColors);
  //   const ys = tf.tensor2d(outputColors);

  //   // Train the model
  //   await model.fit(xs, ys, {
  //     epochs: 100,
  //     shuffle: true,
  //     // callbacks: {
  //     //   onEpochEnd: (epoch, logs) => {
  //     //     console.log(`Epoch ${epoch}: loss = ${logs!.loss}`);
  //     //   }
  //     // }
  //   })

  //   // Sample input to test the model
  //   const sampleInput = tf.tensor2d([[69, 100, 100, 40]]);

  //   // Predict the output
  //   const prediction = model.predict(sampleInput);

  //   console.log(prediction);

  // }

  toggleOpenBools = (index) => {
    let bools = this.state.openBools;
    bools[index] = !bools[index];
    this.setState({ openBools: bools });
  }

  updateLight = (index: number, color: 'r' | 'a' | 'g' | 'b' | 'i' | 'z', event) => {
    let clouds = this.state.design.clouds;
    if (color === 'z') {
      let value = event.target.value;

      let red = Math.round(parseInt(value.substring(1, 3), 16) / 255 * 100);
      let green = Math.round(parseInt(value.substring(3, 5), 16) / 255 * 100);
      let blue = Math.round(parseInt(value.substring(5, 7), 16) / 255 * 100);

      clouds[index].color = { r: red, g: green, b: blue, a: 0 };
    } else if (color === 'i') {
      clouds[index].intensity = Math.round(parseInt(event.target.value));
    } else {
      let value = parseInt(event.target.value);
      if (Number.isNaN(value) || value < 0) {
        value = 0;
      } else if (value > 100) {
        value = 100;
      }
      clouds[index].color[color] = value;
    }

    let newState = {
      design: {
        clouds: clouds,
        spotlights: this.state.design.spotlights
      }
    }
    this.setState(newState);
    this.props.updateState(newState.design); // .design is very important here.
  }

  updateManyLights = (indexes: Array<number>, color: 'r' | 'a' | 'g' | 'b' | 'i' | 'z', event) => {
    let clouds = this.state.design.clouds;
    for (let i = 0; i < indexes.length; i++) {
      let index = indexes[i] - 1;
      if (color === 'z') {
        let value = event.target.value;

        let red = Math.round(parseInt(value.substring(1, 3), 16) / 255 * 100);
        let green = Math.round(parseInt(value.substring(3, 5), 16) / 255 * 100);
        let blue = Math.round(parseInt(value.substring(5, 7), 16) / 255 * 100);

        clouds[index].color = { r: red, g: green, b: blue, a: 0 };
      } else if (color === 'i') {
        clouds[index].intensity = Math.round(parseInt(event.target.value));
      } else {
        let value = parseInt(event.target.value);
        if (Number.isNaN(value) || value < 0) {
          value = 0;
        } else if (value > 100) {
          value = 100;
        }
        clouds[index].color[color] = value;
      }
    }

    let newState = {
      design: {
        clouds: clouds,
        spotlights: this.state.design.spotlights
      }
    }
    this.setState(newState);
    this.props.updateState(newState.design);
  }

  updateSpotlight = (index: number, spotlight: Spotlight) => {
    let spotlights = this.state.design.spotlights;
    spotlights[index] = spotlight;
    let design = {
      clouds: this.state.design.clouds,
      spotlights: spotlights
    }
    this.setState({ design: design });
    this.props.updateState(design);
  }

  render() {
    let individualControls: React.ReactNode[] = [];
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 7; j++) {
        if (this.checkArray[i][j] === 0) { individualControls.push(<div key={`${i} ${j}`}></div>) }
        else {
          individualControls.push(<RGBDisplaySelector function={this.updateLight}
            key={"individualControl" + (this.checkArray[i][j] - 1)}
            functionIndex={this.checkArray[i][j] - 1}
            light={this.state.design.clouds[this.checkArray[i][j] - 1]}
            advanced={this.props.advanced}
            displayAmber={this.props.amber} />)
        }
      }
    }

    let groupedControls: React.ReactNode[] = [];
    for (let array of this.groupingArray) {
      groupedControls.push(
        <div className='groupedControl' key={"groupedControl" + array.toString()}>
          <RGBDisplaySelector function={this.updateManyLights}
            key={"groupedControl" + (array.toString())}
            functionIndex={array}
            light={this.state.design.clouds[array[0] - 1]}
            advanced={this.props.advanced}
            displayAmber={this.props.amber} />
          <GroupedControlCanvas filledValues={array} height={90} />
        </div>
      )
    }

    let spotlightControls: React.ReactNode[] = [];
    for (let i = 0; i < this.state.design.spotlights.length; i++) {
      let angleChange = false;
      if (i === 0 || i === 1 || i === 8 || i === 9) { angleChange = true; }
      spotlightControls.push(<SpotlightDisplaySelector function={this.updateSpotlight} functionIndex={i} allowAngleChange={angleChange} spotlight={this.state.design.spotlights[i]} key={"spotlight" + i} sandbox={this.props.sandbox} />)
    }

    return (
      <>
        {/* <button onClick={() => console.log(this.state)}>See Lights State</button> */}
        <div className='controlDisplay'>
          <h2 onClick={() => this.toggleOpenBools(0)} className='clickableHeader'>Cloud Lights {this.state.openBools[0] ? <>&#11205;</> : <>&#11206;</>}</h2>
          {this.state.openBools[0] && (
            <div id='innerCloud'>
              <div id='groupedControls'>
                <h3>Grouped Controls</h3>
                {groupedControls}
              </div>
              <div id='individualControls'>
                {individualControls}
              </div>
            </div>
          )}
        </div>
        <div className='controlDisplay'>
          <h2 onClick={() => this.toggleOpenBools(1)} className='clickableHeader'>Spotlights {this.state.openBools[1] ? <>&#11205;</> : <>&#11206;</>}</h2>
          {this.state.openBools[1] && (
            <div id='innerSpotlight'>
              {spotlightControls}
            </div>
          )}
        </div>
      </>
    )
  }
}

type SpotlightDSProps = {
  function: Function,
  functionIndex: number,
  allowAngleChange: boolean,
  spotlight: Spotlight,
  sandbox: boolean
}

class SpotlightDisplaySelector extends React.Component<SpotlightDSProps, {}> {
  gatherSpotlightInfo = (event) => {
    let intensity = parseInt((document.getElementById("intensity" + this.props.functionIndex) as HTMLInputElement).value);
    if ((event.target.id as string).includes("intensity")) { intensity = parseInt(event.target.value); }

    let angle: number | string = 0.1;
    if (this.props.allowAngleChange) {
      angle = (document.getElementById("angle" + this.props.functionIndex) as HTMLInputElement).value;
      if ((event.target.id as string).includes("angle")) { angle = event.target.value; }
    }

    let targetX = (document.getElementById("targetX" + this.props.functionIndex) as HTMLInputElement).value;
    if ((event.target.id as string).includes("targetX")) { targetX = event.target.value; }

    let targetY = (document.getElementById("targetY" + this.props.functionIndex) as HTMLInputElement).value;
    if ((event.target.id as string).includes("targetY")) { targetY = event.target.value; }

    let spotlightPosition;
    if (this.props.sandbox) {
      spotlightPosition = (document.getElementById("spotlightX" + this.props.functionIndex) as HTMLInputElement).value;
      if ((event.target.id as string).includes("spotlightX")) { spotlightPosition = event.target.value; }
    } else {
      spotlightPosition = this.props.spotlight.positionY
    }

    let spotlight: Spotlight = {
      intensity: intensity,
      angle: angle as unknown as number,
      positionY: spotlightPosition as unknown as number,
      target: {
        x: targetX as unknown as number,
        y: targetY as unknown as number
      }
    }

    // Call props function with index
    this.props.function(this.props.functionIndex, spotlight);
  }


  render() {
    let bNumber = Math.round((this.props.spotlight.intensity / 100 * 255)).toString(16).padStart(2, '0');
    let bColor = `#${bNumber}${bNumber}${bNumber}`;
    let color = "black";
    if (this.props.spotlight.intensity < 50) { color = "white"; }
    return (
      <div className='spotlightDisplaySelector' style={{ backgroundColor: bColor, color: color }}>
        <input type='range' min={0} max={100}
          value={this.props.spotlight.intensity}
          onChange={(event) => this.gatherSpotlightInfo(event)}
          name={"intensity" + this.props.functionIndex}
          id={"intensity" + this.props.functionIndex}
          className='spotlightIntensitySelector' />
        <label htmlFor={'targetX' + this.props.functionIndex}>X Target</label>
        <input type='range' min={-12} max={13} step={0.1}
          value={this.props.spotlight.target.x}
          onChange={(event) => this.gatherSpotlightInfo(event)}
          name={"targetX" + this.props.functionIndex}
          id={"targetX" + this.props.functionIndex}
          className='spotlightTargetSelector' />
        <label htmlFor={'targetY' + this.props.functionIndex}>Y Target</label>
        <input type='range' min={-8} max={8} step={0.1}
          value={this.props.spotlight.target.y}
          onChange={(event) => this.gatherSpotlightInfo(event)}
          name={"targetY" + this.props.functionIndex}
          id={"targetY" + this.props.functionIndex}
          className='spotlightTargetSelector' />
        <label htmlFor={'spotlightX' + this.props.functionIndex}>Spotbar Position</label>
        <input type='range' min={-7} max={7} step={0.1}
          value={this.props.spotlight.positionY}
          onChange={(event) => this.gatherSpotlightInfo(event)}
          name={"spotlightX" + this.props.functionIndex}
          id={"spotlightX" + this.props.functionIndex}
          className='spotlightTargetSelector' />
        {this.props.allowAngleChange && (
          <>
            <label htmlFor={'angle' + this.props.functionIndex}>Angle</label>
            <input type='range' min={0.05} max={0.6} step={0.01}
              value={this.props.spotlight.angle}
              onChange={(event) => this.gatherSpotlightInfo(event)}
              name={"angle" + this.props.functionIndex}
              id={"angle" + this.props.functionIndex}
              className='spotlightAngleSelector' />
          </>
        )}
      </div>
    )
  }
}


type RGBDisplaySelectorProps = {
  function: Function,
  functionIndex: number | Array<number>,
  light: RAGBColoredLight,
  displayAmber: boolean,
  advanced: boolean
}

class RGBDisplaySelector extends React.Component<RGBDisplaySelectorProps, {}> {
  handleFocus = (event) => {
    event.target.select();
  };

  checkForEnter = (color, event) => {
    if (event.key === 'Enter') {
      this.props.function(this.props.functionIndex, color, event);
    }
  };

  render() {
    // Convert RAGB to RGB here
    let color: RAGBColor = this.props.light.color;
    let fractionIntensity = this.props.light.intensity / 100;

    let convertedRed = color.r;
    let convertedGreen = color.g;
    let convertedBlue = color.b;

    let convertedColor: string = `#${Math.round((color.r / 100) * 255 * fractionIntensity).toString(16).padStart(2, "0")}${Math.round((color.g / 100) * 255 * fractionIntensity).toString(16).padStart(2, "0")}${Math.round((color.b / 100) * 255 * fractionIntensity).toString(16).padStart(2, "0")}`;

    let red: number, green: number, blue: number, amber: number;
    if (this.props.displayAmber) {
      red = color.r;
      green = color.g;
      blue = color.b;
      amber = color.a;
    } else {
      red = convertedRed;
      green = convertedGreen;
      blue = convertedBlue;
      amber = 0;
    }
    return (
      <div className='rgbDisplaySelector' style={{ backgroundColor: convertedColor }}>
        {this.props.advanced ? (
          <>
            <div style={{ backgroundColor: "red" }}>
              <input type='text' value={red} onChange={(event) => this.props.function(this.props.functionIndex, 'r', event)} onFocus={this.handleFocus} onKeyDown={(event) => this.checkForEnter('r', event)} />
              <input type='range' min={0} max={100}
                value={red}
                tabIndex={-1}
                onChange={(event) => this.props.function(this.props.functionIndex, 'r', event)} />
            </div>
            {this.props.displayAmber && (
              <div style={{ backgroundColor: "#ff9900" }}>
                <input type='text' value={amber} onChange={(event) => this.props.function(this.props.functionIndex, 'a', event)} onFocus={this.handleFocus} onKeyDown={(event) => this.checkForEnter('a', event)} />
                <input type='range' min={0} max={100}
                  value={amber}
                  tabIndex={-1}
                  onChange={(event) => this.props.function(this.props.functionIndex, 'a', event)} />
              </div>
            )}
            <div style={{ backgroundColor: "green" }}>
              <input type='text' value={green} onChange={(event) => this.props.function(this.props.functionIndex, 'g', event)} onFocus={this.handleFocus} onKeyDown={(event) => this.checkForEnter('g', event)} />
              <input type='range' min={0} max={100}
                value={green}
                tabIndex={-1}
                onChange={(event) => this.props.function(this.props.functionIndex, 'g', event)} />
            </div>
            <div style={{ backgroundColor: "blue" }}>
              <input type='text' value={blue} onChange={(event) => this.props.function(this.props.functionIndex, 'b', event)} onFocus={this.handleFocus} onKeyDown={(event) => this.checkForEnter('b', event)} />
              <input type='range' min={0} max={100}
                value={blue}
                tabIndex={-1}
                onChange={(event) => this.props.function(this.props.functionIndex, 'b', event)} />
            </div>
            <div style={{ backgroundColor: "white" }}>
              <input type='text' value={this.props.light.intensity} onChange={(event) => this.props.function(this.props.functionIndex, 'i', event)} onFocus={this.handleFocus} onKeyDown={(event) => this.checkForEnter('i', event)} />
              <input type='range' min={0} max={100}
                value={this.props.light.intensity}
                tabIndex={-1}
                onChange={(event) => this.props.function(this.props.functionIndex, 'i', event)} />
            </div>
          </>
        ) : (
          <>
            <input type='color' value={convertedColor} onChange={(event) => this.props.function(this.props.functionIndex, 'z', event)} /> <br />
            <span>Brightness: </span>
            <input type='text' value={this.props.light.intensity} onChange={(event) => this.props.function(this.props.functionIndex, 'i', event)} onFocus={this.handleFocus} />
            <input type='range' min={0} max={100}
              value={this.props.light.intensity}
              tabIndex={-1}
              onChange={(event) => this.props.function(this.props.functionIndex, 'i', event)} />
          </>
        )}
      </div>
    )
  }
}

function convertColor(red: number, green: number, blue: number): string {
  return `#${Math.round(Math.sqrt(Math.sqrt(red / 100)) * 255).toString(16).padStart(2, '0')}${Math.round(Math.sqrt(Math.sqrt(green / 100)) * 255).toString(16).padStart(2, '0')}${Math.round(Math.sqrt(Math.sqrt(blue / 100)) * 255).toString(16).padStart(2, '0')}`
}

type RAGBColoredLight = {
  color: RAGBColor,
  intensity: number
}

type Spotlight = {
  intensity: number,
  positionY: number,
  target: { x: number, y: number },
  angle: number
}

type RAGBColor = { r: number, a: number, g: number, b: number }; // Values go to 100, are converted for display
type RGBColor = { r: number, g: number, b: number };


// Freaking file readers not flipping working, garbage
const data = `0, 56, 62, 32, 99, , 80, 74, 100
1, 13, 35, 68, 89, , 75, 94, 90
2, 7, 29, 31, 75, , 67, 65, 91
3, 13, 2, 49, 22, , 20, 70, 50
4, 17, 26, 5, 14, , 56, 41, 28
5, 45, 49, 77, 0, , 70, 70, 15
6, 1, 57, 23, 95, , 70, 70, 80
7, 52, 45, 63, 36, , 80, 80, 75
8, 52, 70, 35, 54, , 56, 52, 45
9, 28, 38, 10, 39, , 51, 35, 24
10, 97, 14, 4, 95, , 77, 52, 86
11, 36, 1, 73, 56, , 50, 62, 65
12, 20, 19, 18, 34, , 50, 50, 50
0, 5, 63, 41, 82, , 75, 80, 90
1, 25, 49, 52, 52, , 78, 82, 90
2, 59, 97, 81, 44, , 90, 88, 78
3, 9, 2, 29, 9, , 60, 80, 70
0, 0, 100, 0, 0, , 100, 73, 0
1, 0, 100, 0, 100, , 100, 80, 100
2, 100, 100, 0, 100, , 100, 70, 92
3, 77, 54, 22, 8, , 100, 73, 10
4, 7, 52, 55, 89, , 80, 89, 98
5, 3, 15, 93, 94, , 10, 100, 100
6, 84, 9, 76, 53, , 100, 100, 93
7, 68, 67, 48, 49, , 100, 85, 85
8, 63, 31, 37, 93, , 95, 85, 100
9, 31, 34, 64, 71, , 95, 100, 100
10, 16, 71, 53, 58, , 100, 100, 100
11, 59, 78, 62, 51, , 100, 89, 87
12, 20, 15, 100, 100, , 75, 95, 100
13, 100, 69, 100, 0, , 100, 95, 0
14, 0, 0, 100, 100, , 0, 100, 100
15, 50, 100, 50, 100, , 100, 85, 90
16, 100, 100, 0, 0, , 100, 70, 0
17, 100, 100, 100, 0, , 100, 90, 0
18, 0, 100, 100, 0, , 97, 100, 40
19, 0, 64, 100, 25, , 90, 100, 75
20, 100, 100, 28, 63, , 100, 80, 85
21, 0, 21, 100, 47, , 70, 100, 87`

export { StageBuilder }
