import React from 'react';
import { Link } from 'react-router-dom';
import { AuthService } from '../firebase';
import { Helmet } from 'react-helmet';

export class FinishSignIn extends React.Component {
  async componentDidMount(){
    AuthService.completeSignIn();
    let user = await AuthService.getAuthInformation();
    if (user){
      this.props.updateUser(user.uid);
    }
  }

  render() {
    return (
      <div id='finishSignInPage'>
        <Helmet>
          <title>Thanks!</title>
        </Helmet>
        <h1>Sign-In</h1>
        <p>Hey, thanks! You should be finished signing in, so you now get some more options in your Account page. <Link to={"/account"}>Go there now!</Link></p>
        <p>PLEASE request your username as quickly as possible. It's used basically everywhere and helps me do some things in the backend. </p>
      </div>
    );
  }
}
