import React from 'react';
import { Concerts } from '../firebase';
import { IndividualConcert } from './Home';
import { Helmet } from 'react-helmet';
import { AppContext } from './_Context';
import { sumTimestamps } from './Home';


export class TechCheck extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      concerts: [],
      peopleCount: 0
    }
  }
  static contextType = AppContext;

  async componentDidMount(){
    try {
      const techCheckConcerts = await Concerts.getTechCheckConcerts();
      let users = returnCount(techCheckConcerts.length);
      this.setState({
          concerts: techCheckConcerts,
          peopleCount: users
      });
    } catch (error) {
        console.error('Error fetching tech concerts:', error.message);
    }
  }

  updateConcert = async (c) => {
    let filename = c.fileTitle;
    let concert = await Concerts.getExactConcert(filename); // Grabs the newest version of the concert
    let name = document.getElementById(`${filename}Name`).value;
    let comment = document.getElementById(`${filename}Comment`).value;
    concert.techsWhoChecked.push(name);
    concert.techsWhoCommented.push(comment);
    
    let updateCheckBool = concert.techsWhoChecked.length >= this.state.peopleCount; 
    
    await Concerts.updateTechCheckConcert(filename, concert, updateCheckBool);
    window.location.reload();
  }
  
  render() {
    const context = this.context;
    let listItems = [];
    let durations = [];
    if (this.state.concerts.length > 0){
      this.state.concerts.forEach(concert => {
        if (!concert.techsWhoChecked.includes(context.user.name)) {
          let techChecks = concert.techsWhoChecked.join(", ");
          let tracks = [];
          for (let i = 0; i < concert.trackURLs.length; i++){
            tracks.push({trackNumber: i+1, url: concert.trackURLs[i], concert: concert.fileTitle })
          }
          durations.push(concert.totalDuration);
          listItems.push(
            <div className='combinedConcertAndForm' key={concert.fileTitle}>
              <IndividualConcert concert={concert} queue={this.props.adjustQueue} talking={true} />
              <div  className='techCheckRow'>
                <p className='techCheckLength'>{concert.techsWhoChecked.length}</p>
                <p>{techChecks}</p>
                <div className='techCheckInputForm'>
                  <div>
                    {concert.toPublic && (<><label htmlFor='isPublic'>Is Public?</label> <input type='checkbox' defaultChecked={concert.toPublic}/></>)}
                  </div>
                  <div>
                    <label htmlFor={concert.fileTitle + "Name"}>Name :  </label>
                    <input type="text" id={concert.fileTitle + "Name"} name={concert.fileTitle + "Name"} defaultValue={context.user.name} placeholder='Defaults your name from Account'/>
                  </div>
                  <div>
                    <label htmlFor={concert.fileTitle + "Comment"}>Comment :  </label>
                    <input type="text" id={concert.fileTitle + "Comment"} name={concert.fileTitle + "Comment"} />
                  </div>
                  <button onClick={() => this.updateConcert(concert)}>Submit</button>
                </div>
              </div>
            </div>
          );
        }
      });
    }

    return (
      <div id='techCheckArea'>
        <Helmet>
          <title>Tech Audit</title>
        </Helmet>
        {context.user.sl >= 3 && (
          <>
          <h1>Tech Audit</h1>
          <p>Hey, thanks for helping! <b><i>IMPORTANT</i></b>: if there's a track longer than ~15 minutes, it won't play in the player. Open the Drive link, listen there, then come back and skip to the next track. <br/>
          All I need from you is to listen to the concert (check the video if there is one; just check the beginning and end), check for weird stereo problems, mix issues, etc., anything that might sound bad, and make a note of it. It'll also just make me feel better to have more ears listen to it as it goes out. If it's really bad, tell me so I can remove it before more people hear it.  <br/><br/>
          So! Press the play button, it'll add it to the queue, and should autoplay. It may take a moment to load the next one. Once you're done, fill out your name and any necessary comments at the end, and submit. <br/>
          Any that I've listened to (with my name on the left), you can probably assume that I generally know how it sounds, and either can't fix it or decided that not enough people would hear it to fix a minor error right now. <br/><br/>
          You should listen for, in particular: </p>
          <ul><li>~1 second silence coming in</li><li>~5 seconds of applause</li><li>No longer than 10 seconds of nothing interesting happening (silence, applause)</li><li>Stereo, mix issues, etc.</li></ul>
          <p>Stop and message me if: </p>
          <ul><li>A track contains 2 or more songs (you can tell if the applause doesn't get quieter by fade)</li><li>Something sounds particularly bad, bad enough it shouldn't go out to the public.</li></ul>
          <p>Concerts leave this queue when {`{${this.state.peopleCount}}`} people have listened to it. <b><i>IMPORTANT</i></b>: This won't display concerts that you've listened to if you've used your autofilled Account name. <br/>
             Here's how much audio is in this audit: {sumTimestamps(durations)}, of {durations.length} concerts waiting to be finished. There are {this.state.concerts.length} left in total. </p>
          {listItems}
          </>
        )}
      </div>
    );
  }
}

function returnCount(x) {
  if (x > 100) {return 2;}
  if (x > 50) {return 3;}
  return 4;
}