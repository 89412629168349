import React from 'react';
import { AdminChecks, PipelineRequests, AdminRequests } from '../firebase';
import { BrowserRouter as Router, Route, Routes,  Link, BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { AppContext } from './_Context';

export class InProgress extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      items: []
    }
  }
  static contextType = AppContext;

  async componentDidMount(){
    try {
      const inProgressItems = await PipelineRequests.getInProgressItems();
      this.setState({
          items: inProgressItems
      });
    } catch (error) {
        console.error('Error fetching in-Progress information:', error.message);
    }
  }
  
  handleRemove = async (key) => {
    await AdminRequests.removeAdminCheck(key);
    console.log("Removed item.");
  }

  submitInProgress = async () => {
    let obj = {};
    if (document.querySelector('#string').value !== ""){
      obj["string"] = document.querySelector('#string').value;
      obj["type"] = "inProgressItem";
      await AdminChecks.addInProgressItem(obj);
      document.querySelector('#string').value = "";
    }
  }


  render() {
    let inProgressItems = [];
    if (this.state.items.length > 0){
      this.state.items.forEach(item => {
        inProgressItems.push(
          <div className='inProgressItem' key={item.string}>{item.string} <button onDoubleClick={() => {this.handleRemove(item.key)}}>Remove</button></div>
        )
      });
    }
    const context = this.context;
    return (
      <div id='adminProgressPage'>
        <Helmet>
          <title>In Progress</title>
        </Helmet>
        {context.user.sl >= 9 && (
          <>
          <h1>In Progress</h1>
          <p><Link to="/admin">Back to Admin</Link></p>
          
          <div id='inProgressItems'>
            {inProgressItems}
          </div>
          <div id='inProgressForm'>
            <label htmlFor='string'>String:  </label>
            <input type='text' name='string' id='string' />
            <button onClick={this.submitInProgress}>Submit</button>
          </div>
          </>
        )}
      </div>
    );
  }
}
