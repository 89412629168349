import './scsscomp/App.css';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import React from 'react';
import { AuthService, GetSemesterInfo } from './firebase.js';
import { AppContext } from './components/_Context.js';
import { Init } from './firebase.js';
import icon from './Treble-clef.png';
import { LowerThird } from './components/LowerThird.js';
import { Home } from './components/Home.js';
import { About } from './components/About.tsx';
import { ConcertImport } from './components/ConcertImport.js';
import { Account } from './components/Account.js';
import { Calendar } from './components/Calendar.js';
// import { Web } from './components/Web';
import { TechCheck } from './components/TechCheck.js';
import { Admin } from './components/Admin.js';
import { Tools } from './components/Tech.js';
import { Lessons } from './components/Lessons.js';
import { Articles } from './components/Articles.js';
import { ExpandingSidebar } from './components/ExpandingSidebar.js';
import { ProductionVideos } from './components/ProductionVideos.js';
import { InProgress } from './components/InProgress.js';
import { UpdateConcert } from './components/UpdateConcert.js';
import { Pipeline } from './components/Pipeline.js';
import { PlaylistGeneration } from './components/PlaylistGeneration.js';
import { AdminLargeButtons } from './components/AdminLargeButtons.js';
import { FinishSignIn } from './components/FinishSignIn.js';
import { Work } from './components/Work.js';
import { Announcements } from './components/Announcements.js';
import { UserList } from './components/UserList.js';
import { Sherry } from './components/Sherry.js';
import { ScoreLibrary } from './components/ScoreLibrary.js';
import { Playlist } from './components/Playlists.js';
import { MinorUpdates } from './components/AdminMinorUpdates.js';
import { Directors } from './components/Directors.js';
import { Search } from './components/Search.js';
import { DatabaseEditing } from './components/DatabaseEditing.js';
import { StageBuilder } from './components/StageBuilder.tsx';
import { Community } from './components/Community.js';
import { CompChallenge } from './components/CompChallenge.tsx';
import SingleConcertDisplay from './components/SingleConcertDisplay.js';
import { AuditTotals } from './components/AuditTotals.js';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      audioTracks: [],
      user: {
        uid: "",
        email: "",
        name: "",
        sl: 0,
        isStudent: false
      },
      queryElements: {},
      messages: {},
      semesters: {}
    }
  }

  adjustQueue = (tracks, add = true) => {
    if (add) {
      let currentTracks = this.state.audioTracks;
      tracks.forEach(track => {
        currentTracks.push(track);
      });
      this.setState({ audioTracks: currentTracks });
    } else {
      this.setState({ audioTracks: tracks });
    }
  }

  removeTrackFromState = () => {
    this.setState(() => ({
      audioTracks: this.state.audioTracks.slice(1),
    }));
  };

  updateUser = (uid) => {
    AuthService.getUserInformation(uid).then((userInfo) => {
      let sl = parseInt(userInfo.sl);
      console.log(`User SL: ${sl}`);
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          uid: uid,
          email: userInfo.email,
          sl: sl,
          name: userInfo.name,
          isStudent: userInfo.isStudent
        }
      }));
    });
  }

  async componentDidMount() {
    // Check for the user's UID every 0.5 second for a maximum of 10 seconds
    let tries = 0;
    const interval = setInterval(async () => {
      let uid = AuthService.getAuthInformation();
      if (uid) {
        clearInterval(interval); // Stop the interval if the UID is found
        uid = uid.uid;
        this.updateUser(uid);
      } else {
        tries++;
        if (tries >= 20) {
          clearInterval(interval); // Stop the interval after 10 seconds
          console.log("User not authenticated after 10 seconds.");
        }
      }
    }, 500);
    const queryParams = new URLSearchParams(window.location.search);
    const semesterName = queryParams.get('semester'); // Get the semester query parameter
    const concert = queryParams.get('concert'); // Get the concert parameter
    const group = queryParams.get('group'); // Get the concert parameter

    let obj = {};
    obj["semester"] = semesterName;
    obj["concert"] = concert;
    obj["group"] = group;

    let messages = await Init.getMessages();
    let semesters = await GetSemesterInfo.getAllSemesterInformation();

    this.setState({
      queryElements: obj,
      messages: messages,
      semesters: semesters
    });
  }

  render() {
    return (
      <div id='webpage'>
        <AppContext.Provider value={this.state}>
          <BrowserRouter>
            <Sidebar />
            <Routes>
              <Route index element={<Home adjustQueue={this.adjustQueue} queryString={this.state.queryElements} initialSemesterInfo={this.state.semesters} />} />
              <Route path="account" element={<Account updateUser={this.updateUser} />} />
              <Route path="calendar" element={<Calendar />} />
              {/* <Route path="web" element={<Web />} /> */}
              <Route path="about" element={<About />} />
              <Route path="admin" element={<Admin />} />
              <Route path="admin/concertimport" element={<ConcertImport />} />
              <Route path="admin/inprogress" element={<InProgress />} />
              <Route path="admin/updateconcert" element={<UpdateConcert />} />
              <Route path="work/audits" element={<TechCheck adjustQueue={this.adjustQueue} />} />
              <Route path='tools' element={<Tools />} />
              <Route path='tools/stagebuilder' element={<StageBuilder />} />
              <Route path='community' element={<Community />} />
              <Route path='community/composerchallenge' element={<CompChallenge />} />
              {/* <Route path='community/lessons' element={<Lessons />} /> */}
              {/* <Route path='community/articles' element={<Articles />} /> */}
              {/* <Route path='community/announcements' element={<Announcements />} /> */}
              <Route path='concerts/production' element={<ProductionVideos />} />
              <Route path='concert/:concert' element={<SingleConcertDisplay adjustQueue={this.adjustQueue} />} />
              <Route path='concerts/pipeline' element={<Pipeline initialSemesterInfo={this.state.semesters} />} />
              <Route path='account/finishsignin' element={<FinishSignIn updateUser={this.updateUser} />} />
              <Route path='work' element={<Work />} />
              <Route path='admin/userlist' element={<UserList />} />
              <Route path='work/sherry' element={<Sherry />} />
              <Route path='work/directors' element={<Directors />} />
              <Route path='work/scorelibrary' element={<ScoreLibrary />} />
              {/* <Route path='playlist' element={<Playlist adjustQueue={this.adjustQueue}/>} /> */}
              {/* <Route path='playlist/generation' element={<PlaylistGeneration adjustQueue={this.adjustQueue}/>} /> */}
              <Route path='admin/expensivebuttons' element={<AdminLargeButtons />} />
              <Route path='admin/minorupdates' element={<MinorUpdates />} />
              {/* <Route path='concerts/search' element={<Search adjustQueue={this.adjustQueue} queryString={this.state.queryElements}/>} /> */}
              <Route path='work/database' element={<DatabaseEditing />} />
              {this.state.user.sl >= 8 && (
                <Route path="work/audits/total" element={<AuditTotals />} />

              )}

              <Route path="*" element={<MissingPage />} />
            </Routes>
            <LowerThird tracks={this.state.audioTracks} removeTrackFromState={this.removeTrackFromState} />
          </BrowserRouter>
        </AppContext.Provider>
      </div>
    );
  }
}

class Sidebar extends React.Component {
  static contextType = AppContext;
  render() {
    const context = this.context;
    let workmPath = [];
    let workmLabel = [];
    let al = context.user.sl;
    if (al > 2) {
      workmPath.push("/work/audits");
      workmLabel.push("Audit");
      if (al === 4 || al === 9) {
        workmPath.push("/work/database");
        workmLabel.push("Database");
      }
      if (al === 5 || al === 9) {
        workmPath.push("/work/directors");
        workmLabel.push("Directors");
      } if (al === 5 || al === 8 || al === 9) {
        workmPath.push("/work/scorelibrary");
        workmLabel.push("Score Library");
      }
      if (al === 7 || al === 9) {
        workmPath.push("/work/sherry");
        workmLabel.push("Sherry");
      }
    }
    return (
      <div id='sideBar'>
        <Link to="/"><img src={icon} alt='icon' /></Link>
        <nav>
          <ul>
            <Link to="/account"><li>Account</li></Link>
            <ExpandingSidebar Mpath="/" Mlabel="Home" mPath={["/", "/concerts/pipeline", "/concerts/production"]} mLabel={["Concerts", "Pipeline", "Production"]} />
            {/* <ExpandingSidebar Mpath="/database" Mlabel="Database" mPath={["/database/concerts", "database/people"]} mLabel={["Concert Database", "People"]}/> */}
            {/* <li><Link to="/web">Web</Link></li> */}
            <Link to="/calendar"><li>Calendar</li></Link>
            {/* <Link to="/playlist"><li>Playlists</li></Link> */}
            <ExpandingSidebar Mpath="/community" Mlabel="Community" mPath={["/community/composerchallenge"]} mLabel={["Composer Challenge"]} />
            <ExpandingSidebar Mpath="/tools" Mlabel="Tools" mPath={["/tools/stagebuilder"]} mLabel={["Stage Builder (beta)"]} />
            <ExpandingSidebar Mpath="/work" Mlabel="Work" mPath={workmPath} mLabel={workmLabel} />
            <Link to="/about"><li>About</li></Link>
            {context.user.sl === 9 && (<Link to="/admin"><li>Admin</li></Link>)}
          </ul>
        </nav>
      </div>
    );
  }
}

class MissingPage extends React.Component {
  render() {
    return (
      <div id='missingPage'>
        <h1>Missing Page</h1>
        <p>Did you mean to navigate here? </p>
        <p><Link to={"/"}>Go back home</Link></p>
      </div>
    )
  }
}

export default App;