import React from 'react';
import { Concerts, CalendarEvents } from '../firebase';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import playButton from '../Play button.png';
import addToQueuebutton from '../Add to Queue.png';
import downloadButton from '../Download Symbol.png';
import { Helmet } from 'react-helmet';
import shareImage from "../Share image.png";
import reportButton from '../Report Option.png';
import { ReportPopup } from './Popup Window';
import { AppContext } from './_Context';
import { HomeCalendarView } from './HomeCalendarView.tsx';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      semesters: {},
      totalDuration: "",
      newestConcert: {},
      todayConcertEvents: [],
      showTalking: true,
      currentView: "semester"
    }
  }
  static contextType = AppContext;

  async componentDidMount() {
    Concerts.getNewestConcert()
      .then(newConcert => this.setState({ newestConcert: newConcert }))
      .catch(error => console.error('Error fetching newest concert:', error.message));

    CalendarEvents.getTodayCalendarEvents()
      .then(calendarEvents => this.setState({ todayConcertEvents: calendarEvents }))
      .catch(error => console.error('Error fetching today\'s calendar events:', error.message));

    const interval = setInterval(async () => {
      if (JSON.stringify(this.props.initialSemesterInfo) !== "{}") {
        this.setState({ semesters: this.props.initialSemesterInfo });
        clearInterval(interval);
      }
    }, 500);
  }

  toggleTalking = () => {
    let bool = !this.state.showTalking;
    this.setState({ showTalking: bool });
  }

  updateView = (event) => {
    this.setState({ currentView: event.target.value });
  }

  render() {
    const { semesters } = this.state;
    var semesterList = [];
    if ("trackCount" in semesters) {
      for (let i = 0; i < semesters.trackCount.length; i++) {
        let object = {};
        object["name"] = this.state.semesters.array[i];
        object["duration"] = this.state.semesters.duration[i];
        object["trackCount"] = this.state.semesters.trackCount[i];
        object["concertCount"] = this.state.semesters.concertCount[i];

        semesterList.unshift(object);
      }
    }

    return (
      <div id='concertPage'>
        <Helmet>
          <title>Home Page</title>
        </Helmet>
        <h1>Concerts</h1>
        <div id='fullButtons'>
          {/* <Link to="concerts/search"><div><p>Search</p></div></Link> */}
          <Link to="concerts/pipeline"><div><p>Pipeline</p></div></Link>
          <Link to="concerts/production"><div><p>Production</p></div></Link>
        </div>


        <p>{this.context.messages.home}</p>
        <div id='talkingState' onClick={this.toggleTalking}>
          {this.state.showTalking ? (<p className='showing'>Showing Talking</p>) : (<p className='hiding'>Hiding Talking</p>)}
        </div>
        {JSON.stringify(this.state.todayConcertEvents) !== '[]' && (<p id='todayCalendarEvent' ><span>There are calendar events today! It is: </span>
          {this.state.todayConcertEvents.map((calendar, key) => <>
            <span key={key} className={calendar.classNames.split(' ').join('')}>   {calendar.title}</span><span>, at </span>
            {new Date(calendar.start).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }
            )}, </>)
          }<Link to="/calendar">go check it out (to Calendar)!</Link></p>)}
        <div className='concertRow newestConcert'>
          <span>Newest Concert:</span>
          {JSON.stringify(this.state.newestConcert) !== '{}' && (<IndividualConcert concert={this.state.newestConcert} queue={this.props.adjustQueue} talking={this.state.showTalking} />)}
        </div>

        {/* Make this select a part of state, keep most recent concert and other items above. Adjust the display to have a concert
                display by calendar. Make it a new component; state should have an array of objects with some encoded timestamp as a key,
                and the concerts as an array of them to display. On click, open a concert below (or above?) it. The array should also be 
                duplicated whenever the calendar month changes. Maybe I should have two Selects for selecting years and months 
                (perhaps programatically) so it takes the current month and day and encodes back in time up until 2008. I could just 
                take the current year and subtract until 2008, then fills the options with that. The calendar, then, shouldn't have any 
                controls on it, but you can search by year and month, then the calendar displays that by input (that better be 
                an option in their props). From there fill it with options of selectable (onClick events) that takes in the entire 
                object and displays it beneath. If my props have an option to make it auto-open, I should do that. 
                */}
        <p>Use this selector to switch between views.</p>
        <select id='viewSelect' onChange={this.updateView}>
          <option value="semester">Semester View</option>
          <option value="calendar">Calendar View</option>
        </select>
        {this.state.currentView === "semester" ? (
          <>
            {semesterList.map((semester, key) => <IndividualSemester key={key} semester={semester}
              adjustQueue={this.props.adjustQueue} queryString={this.props.queryString} talking={this.state.showTalking} />)}
          </>
        ) : (
            <HomeCalendarView adjustQueue={this.props.adjustQueue} talking={this.state.showTalking} />
        )}
      </div>
    );
  }
};

class IndividualSemester extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
      dataGathered: false,
      concerts: []
    }
  }

  notInnerRow = async () => {
    let newValue = !this.state.isOpened;
    this.setState({ isOpened: newValue });
    if (!this.state.dataGathered) {
      let arrayIn = await Concerts.getExactConcerts(this.props.semester.name);
      this.setState({ dataGathered: true, concerts: arrayIn });
    }
  }

  handleShareButton = async (name) => {
    try {
      let queryString = `semester=${name}`;

      // Create a URL with the generated query string
      const url = `concerts.codydhowell.com/?${queryString}`;
      await navigator.clipboard.writeText(url);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  componentDidMount() {
    let queryString = this.props.queryString;
    if (queryString.semester && queryString.semester === this.props.semester.name) {
      this.notInnerRow();

      // Scroll to the target element with an offset of 20 pixels
      const targetElement = document.getElementById(this.props.semester.name);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth', // Optional: Add smooth scrolling behavior
          block: 'start', // Scroll to the top of the element
        });
      }
    }
    if (!queryString.concert) {
      window.history.replaceState({}, "", window.location.pathname);
    }
  }

  render() {
    let listItems = [];
    let concerts = [...this.state.concerts];
    for (let i = 0; i < concerts.length; i++) {
      let uniqueID = concerts[i].fileTitle + "" + i;
      if (concerts[i].concertGrouping === "") {
        listItems.push(
          <div key={uniqueID} className='concertRow'>
            <IndividualConcert concert={concerts[i]} queue={this.props.adjustQueue} queryString={this.props.queryString} talking={this.props.talking} />
          </div>
        );
      } else {
        let groupName = concerts[i].concertGrouping;
        let groupedConcerts = [];
        groupedConcerts.push(concerts.splice(i, 1)[0]);
        for (let j = i; j < concerts.length; j++) {
          if (concerts[j].concertGrouping === groupName) {
            groupedConcerts.push(concerts.splice(j, 1)[0]);
            j--;
          }
        }
        listItems.push(
          <div key={uniqueID} className='groupedConcertRow'>
            <GroupedConcert concerts={groupedConcerts} queue={this.props.adjustQueue} queryString={this.props.queryString} talking={this.props.talking} />
          </div>
        )
        i--;
      }
    }

    return (
      <div className='semesterDisplay' id={this.props.semester.name}>
        <div className='semesterOuterRow' onClick={this.notInnerRow}>
          <p>Semester: {this.props.semester.name}</p>
          <p>Concert Count: {this.props.semester.concertCount}</p>
          <p>Total Duration: {this.props.semester.duration}</p>
          <p>Track Count: {this.props.semester.trackCount}</p>
        </div>
        {this.state.isOpened && (
          <div className='semesterInnerRow'>
            <span>To link someone directly to this semester, click this button. </span>
            <button className='shareButton' onClick={() => { this.handleShareButton(this.props.semester.name) }}>Share Button</button>
            <div className='concertHeaderRow'>
              <p className='notForMobile'></p>
              <p>File Title</p>
              <p>Date</p>
              <p>Tags</p>
              <p>Duration</p>
              <p># of Tracks</p>
              <p className='notForMobile'></p>
              <p>Groups Performing</p>
            </div>
            {listItems}
            <p>Please wait for the concerts to load. If the count is different from what you see, they may be awaiting audit by techs (see Home&gt;Pipeline).</p>
          </div>
        )}
      </div>
    );
  }
}

class IndividualConcert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInnerRowVisible: false,
      showPopup: false,
      trackNumber: 0
    }
  }

  toggleInnerRow = () => {
    this.setState((prevState) => ({
      isInnerRowVisible: !prevState.isInnerRowVisible
    }));
  };

  handleShareButton = async (concert) => {
    try {
      let concertQuery = concert.fileTitle;
      while (concertQuery.includes(' ')) {
        concertQuery = concertQuery.replace(' ', '_');
      }

      // Create a URL with the generated query string
      const url = `concerts.codydhowell.com/concert/${concertQuery}`;
      await navigator.clipboard.writeText(url);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  handleReportButton = async (i) => {
    this.setState({ showPopup: true, trackNumber: i });
  }

  handlePopupCompletion = async () => {
    this.setState({ showPopup: false });
  }

  processTimestamp = (match) => {
    const timestamp = match.substring(1, match.length - 1); // Remove the surrounding '|'
    const [hours, minutes, seconds] = timestamp.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    const stringOut = `#t=${totalMinutes}m${seconds}s`

    // Return the result of your processing
    return stringOut;
  }

  componentDidMount() {
    if (this.props.queryString) {
      let queryString = this.props.queryString;
      if (queryString.concert && queryString.concert === this.props.concert.fileTitle) {
        this.setState((prevState) => ({
          isInnerRowVisible: true
        }));

        // Scroll to the target element with an offset of 20 pixels
        const targetElement = document.getElementById(this.props.concert.fileTitle);
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: 'smooth', // Optional: Add smooth scrolling behavior
            block: 'start', // Scroll to the top of the element
          });
        }

      }
      window.history.replaceState({}, "", window.location.pathname);
    }
  }

  render() {

    const groupsList = this.props.concert.groupsArray.join(", ");
    const featuredGuestsList = this.props.concert.featuredGuestsArray.join(", ");
    const featuredSoloistsList = this.props.concert.featuredSoloistsArray.join(", ");
    let outerTracks = [];
    for (let i = 0; i < this.props.concert.trackURLs.length; i++) {
      if (!(this.props.talking) && this.props.concert.trackTalking[i]) { continue; }
      outerTracks.push({ trackNumber: i + 1, url: this.props.concert.trackURLs[i], concert: this.props.concert.fileTitle })
    }
    var tracks = [];
    for (let i = 0; i < this.props.concert.trackLengths.length; i++) {
      if (!(this.props.talking) && this.props.concert.trackTalking[i]) { continue; }
      let classString = "playTracksRow";
      if (this.props.concert.trackTalking[i]) { classString += " talking"; }
      let concert = [{ trackNumber: i + 1, url: this.props.concert.trackURLs[i], concert: this.props.concert.fileTitle }]
      tracks.push(
        <div className={classString} key={i}>
          <div>
            <p className='pButton' onClick={() => this.props.queue(concert, false)}><img src={playButton} alt='Play' className='pButtonImage' name="Play" /></p>
            <p className='pButton' onClick={() => this.props.queue(concert)}><img src={addToQueuebutton} alt='Add to Queue' className='pButtonImage' /></p>
          </div>
          <div><p>Track Number: {i + 1}</p></div>
          <div>
            {this.props.talking && (<><p>Talking:</p>
              <input type='checkbox' checked={this.props.concert.trackTalking[i]} readOnly name='isTalkingTrack' /></>)}
          </div>
          <div>
            <p>Duration: </p>
            <p>{" " + this.props.concert.trackLengths[i]}</p>
          </div>
          <div>
            <img src={reportButton} alt='Report' className='clickableButton' onClick={() => this.handleReportButton(i + 1)} />
            <a href={this.props.concert.trackURLs[i]}><img className='clickableButton' src={downloadButton} alt='Download' /></a>
          </div>
        </div>
      );
    }
    let performers = [];
    for (let i = 0; i < this.props.concert.performersArray.length; i++) {
      let key = i + "performer";
      performers.push(
        <span key={key}>{this.props.concert.performersArray[i].replace(/~/g, '-')}</span>
      )
    }

    const description = this.props.concert.concertDescription.replace(/;/g, '<br/>');

    const timestampPattern = /\|(\d+:\d+:\d+)\|/g;

    // Replace the timestamp pattern with the processed result
    const descriptionWithLinks = description.replace(timestampPattern, (match) => {
      const processedResult = this.processTimestamp(match);
      return `<a href="${this.props.concert.videoLink}${processedResult}" target=_blank>${match.slice(1, 8)}</a>`;
    });

    return (
      <div className='homeConcertDisplay' id={this.props.concert.fileTitle}>
        {this.state.showPopup && (<ReportPopup removeElement={this.handlePopupCompletion} concertName={this.props.concert.fileTitle} trackNumber={this.state.trackNumber} />)}
        <div className='outerRow' onClick={this.toggleInnerRow}>
          <div className='outerButtons'>
            <p className='pButton' onClick={() => this.props.queue(outerTracks, false)}><img src={playButton} alt='Play' className='pButtonImage' name="Play" /></p>
            <p className='pButton' onClick={() => this.props.queue(outerTracks)}><img src={addToQueuebutton} alt='Add to Queue' className='pButtonImage' /></p>
          </div>
          <p>{this.props.concert.fileTitle.slice(11)}</p>
          <p>{new Date(this.props.concert.concertDateTime).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
          <p>{this.props.concert.tags.join(", ")}</p>
          <p>{this.props.concert.totalDuration}</p>
          <p>{this.props.concert.trackLengths.length + " tracks"}</p>
          <div className='outerInfoCheck'>
            <p className={this.props.concert.trackLengths.length > 0 ? "greenBox" : "redBox"}>Audio</p>
            <p className={this.props.concert.videoLink !== "" ? "greenBox" : "redBox"}>Video</p>
            <p className={this.props.concert.programLink !== "" && this.props.concert.programLink !== "No Program" ? "greenBox" : "redBox"}>Program</p>
          </div>
          <p>{this.props.concert.groupsArray.join(", ")}</p>
        </div>
        {this.state.isInnerRowVisible && (<div className='innerRow'>
          <div className='infoArea'>
            <div className='shareArea'>
              <p>To link someone directly to this concert, click this button. Or report something about this concert. </p>
              <img src={shareImage} alt='Share' onClick={() => this.handleShareButton(this.props.concert)} />
              <img src={reportButton} alt='Report' onClick={() => this.handleReportButton(0)} />

            </div>
            {performers.length > 1 && (<div className='performerArea'>
              <p><b>Performers:</b> Name - Instr. 1/Instr. 2 - Group 1/Group 2</p>
              <div className='performersList'>
                {performers}
              </div>
            </div>)}
            <div className='concertArea'>
              <div className='innerRowTopButtonsRow'>
                {this.props.concert.backupGoogleLink && (<><a className='innerRowTopButtons' href={this.props.concert.backupGoogleLink} target='_blank' rel='noreferrer'>Google Drive Folder</a><br /></>)}
                {this.props.concert.programLink !== "" && this.props.concert.programLink !== "No Program" && this.props.concert.programLink !== "In Progress" && (<><a className='innerRowTopButtons' href={this.props.concert.programLink} target='_blank' rel='noreferrer'>Program Link</a><br /></>)}
                {this.props.concert.videoLink && (<><a className='innerRowTopButtons' href={this.props.concert.videoLink} target='_blank' rel='noreferrer'>Video Link</a><br /></>)}
                {this.props.concert.databaseLink && (<><a className='innerRowTopButtons' href={this.props.concert.databaseLink} target='_blank' rel='noreferrer'>Database Link</a><br /></>)}
                {this.props.concert.extraMarketingLink && (<><a className='innerRowTopButtons' href={this.props.concert.extraMarketingLink} target='_blank' rel='noreferrer'>Marketing Link</a><br /></>)}
              </div>
              {this.props.concert.concertTitle && (<><span><b>Concert Title: </b></span>{this.props.concert.concertTitle}<br /></>)}
              {this.props.concert.concertDescription && (<p className="indivConcertDescription" dangerouslySetInnerHTML={{ __html: descriptionWithLinks }} />)}
              {groupsList && (<><span><b>Groups: </b></span>{groupsList}<br /></>)}
              {this.props.concert.concertGrouping !== "" && (<><span><b>Grouping: </b></span>{this.props.concert.concertGrouping}<br /></>)}
              {featuredGuestsList && (<><span><b>Featured Guests: </b></span>{featuredGuestsList}<br /></>)}
              {featuredSoloistsList && (<><span><b>Featured Soloists: </b></span>{featuredSoloistsList}</>)}
            </div>
          </div>
          {tracks}

        </div>)}
      </div>

    );
  }
}

class GroupedConcert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false
    }
  }

  componentDidMount() {
    if (this.props.queryString) {
      let queryString = this.props.queryString;
      if (queryString.group && queryString.group === this.props.concerts[0].concertGrouping) {
        this.setState((prevState) => ({
          isOpened: true
        }));
      }
      window.history.replaceState({}, "", window.location.pathname);
    }
  }

  handleOpenState = () => {
    this.setState({ isOpened: !this.state.isOpened });
  }

  render() {
    let concertItems = [];
    for (let i = 0; i < this.props.concerts.length; i++) {
      let uniqueID = this.props.concerts[i].fileTitle + "" + i;
      concertItems.push(
        <div key={uniqueID} className='concertRow'>
          <IndividualConcert concert={this.props.concerts[i]} queue={this.props.queue} queryString={this.props.queryString} talking={this.props.talking} />
        </div>
      )
    }
    return (
      <>
        <div className='outerGroupedConcert' onClick={this.handleOpenState}>
          <p>Grouped Concert: </p>
          <p>{this.props.concerts[0].concertGrouping}</p>
          <p>Number of Concerts: {this.props.concerts.length}</p>
        </div>
        {this.state.isOpened && (<div className='innerGroupedConcert'>
          {concertItems}
        </div>)}
      </>
    )
  }
}

// Copied from ConcertImport
export function sumTimestamps(timestamps) {

  // Initialize variables to hold the total hours, total minutes, and total seconds
  let totalHours = 0;
  let totalMinutes = 0;
  let totalSeconds = 0;

  // Iterate through the timestamps and add up the hours, minutes, and seconds
  for (const timestamp of timestamps) {
    if (timestamp.length < 6) {
      const [minutes, seconds] = timestamp.split(':');
      totalMinutes += parseInt(minutes, 10);
      totalSeconds += parseInt(seconds, 10);
    } else {
      const [hours, minutes, seconds] = timestamp.split(':');
      totalHours += parseInt(hours, 10);
      totalMinutes += parseInt(minutes, 10);
      totalSeconds += parseInt(seconds, 10);
    }
  }

  // Handle any excess seconds and minutes
  totalMinutes += Math.floor(totalSeconds / 60);
  totalSeconds %= 60;

  totalHours += Math.floor(totalMinutes / 60);
  totalMinutes %= 60;

  // Format the result as a string in the 'h:mm:ss' format
  const result = `${totalHours}:${totalMinutes.toString().padStart(2, '0')}:${totalSeconds.toString().padStart(2, '0')}`;

  return result;
}

export { Home, IndividualConcert };