import React from 'react';

export class About extends React.Component<{}, { copied: boolean }> {
  constructor(props: {}){
    super(props);
    this.state = {
      copied: false
    }
  }

  emailIntoClipboard = async () : Promise<void> => {
    try {
      await navigator.clipboard.writeText("feedback@codydhowell.com");
      this.setState({copied: true})
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  componentDidMount() : void {
    document.title = "About Page";
  }

  /*
    TO DO OPTIONS: 
    IMPORTANT: Audio displays error message but can play. Or maybe not? 
      * Decide how to do the Database
          * Probably a bunch of arrays
          * Simply make the tool for the builder, not even worrying about needing Admin to check it
          * IMPORTANTLY: decide how to do People. Here's what I'm thinking: 
              * Every concert goes through 2 steps.
                  * 1. Parse through the visual People inputs listed in each Concert, generating People if they don't already have an entry. 
                      * This should have some major standardizing in what options can be selected for specifically instrumentation (check!)
                  * 2. That parse is used to effeciently show where people played (hopefully discernable via program)
                      * I'm thinking of having a large checkbox above with the groups, and all the people in each group (listed separately, but linked per person)
                      * Select what people played, then click a button that applies that group input to the line on the entry.
          * See bottom of this page
          * Probably, no one's going to finish it in one go, so I'll have to allow for partial databases to be written to. Which means my update code has to be amazing. 
              * Which, if everything just writes to State and everything is always rendered from state, then I should be fine. 
      * Implement Filters
          * I might have to figure out how to re-render everything? I could probably just pass things through components, since the render runs every time. 
          * Or just ignore it and use filters only in the Database view. 
      * Implement Sherry's Page
          * IDEALLY, she could upload a file, it would go through Google Drive, add it to even a generic folder, get the share link from it, and upload that to the doc. 
          * I can create a new function that takes in the file, has all the stuff I need to upload to Drive (hopefully), can get what I need, and update the database on it's own. 
          * She also needs to be able to mark a concert as incompletable, which would just set it to "No Program".
      * Find a way to display what's in the queue
          * Click a button, and have something pop up that shows everything top to bottom. Use indexes, concert titles, and track numbers. That should be it. 
          * Maybe I could clean up the name via.. getting the first 10 digits, convert it into a nice date, and put it at the end? 
      * Build a new data type for Programs
          * Shouldn't add to count, should be viewable in the Database. 
          * All the people fields, concert title, timestamp, semester (important for the People getter), and program link. 
      * NEW IDEA: Tools
          * Timpani Tuner: Sets 3-5 drums pitches, then shows a random note for you to play. You have to look at it, know what drum can hit it (or if you're already tuned to it), then hit it. 
              * This would also keep my job for longer, developing tools for students to use :) 
          * Include a page for the Binary counting method
          * Display etudes
              * Generate random number strings for stickings
              * Request other methods for practicing tools
          * Stage Designer
              * Instruments have the following fields (in the dropdown for selection)
                  * Title
                  * Description
                  * 3D Object (if 2D map is not based on 3D visualizer, a 2D map is also required)
              * Get measurements of the stage & common instruments 
                  * Massive grand piano
                  * Upright piano
                  * Keyboard setup w/ chair
                  * Drumset rug
                  * Risers (get counts as well)
                  * Single Chair
                  * Single Stand
                  * Small Chair (small distance)
                  * Medium Chair
                  * Large Chair
                  * PERCUSSION EQUIPMENT
                  * Vocalist/Soloist Microphone Stand
                  * Monitors
              * Make preset with full choir risers
              * Make preset with rows of chair that can be number-changed
              * Generate arcs and numbers with a specific number of stands. 
          * Stage Light Designer
              * It might be as simple as having two dozen cones with an opacity filter (or for closer ones, just an oval on the ground)
              * In the future, this might be a 3D render with lights coming in, small box lights on the floor or adjustable stand, and everything combined. I'll look into it :)
      * FEATURE: Messages to individual users? Maybe I can do it in UserList. 
          * You can just add it as a number in the sidebar, and to make sure they see it, make it a popup in Home too. Surely they would see it by that point. Just a simple one-point remove. 
          * Messages should be held in the master App so you don't have to get it multiple times. It should be held in Context. 
      * NEED TO ADD: Security features. I think every time the app updates, it will check the user's authentication, add in security level from the Database, then..
          * I REALLY need to add security rules to each of the collections 
      * Search should be able to select between multiple (modular) things with one database call, sending the field to check and an array of items to return. 
          * This means that I could select multiple tags to return (return a union of the results, not an intersection), though I could possibly implement that too. 
          * I'm thinking tags, directors, and groups to start, since they're easiest to check.  
      * Adjust Calendar to inlcude admission prices, and an onclick handler to grab the event from the array and display a dropdown right beneath it for extra information (inlcude a direct reference to location)
      * Sort items into allowed and unallowed areas directly in App. 
  */

  render() {
    
    return (
      <div id='aboutPage'>
        <h1>About</h1>
        <p id="AboutText">
          Welcome to Snow College's Concert and Performance Website, where we showcase the amazing talent of our
          students and faculty. This site is a hub for all things related to music at Snow College.
          Here, you'll find information about upcoming concerts and performances, as well as a calendar of events so
          you can plan your attendance. Additionally, our site features a database of past performances, so you can
          relive your favorite moments. There are also a number of features coming for everyone to enjoy, such as the Musical
          Web, Challenges and Requests, and a database of sheet music to look through to find something new to play. <br />
          This website is managed and run by students, with support from the performing arts faculty at Snow College.
          We are dedicated to promoting the arts at our college and providing a platform for our talented musicians,
          actors, and other performers to shine. <br />
          So whether you're a current student, alumni, or just a fan of the arts, we invite you to explore our website
          and join us for the next exciting performance at Snow College.
        </p>
        <p>If you'd like to support the Music Program at Snow by donating, <a href='https://www.snow.edu/musicgiving' target='_blank' rel='noreferrer'>
          you can do that here</a>! Thank you for your support of Snow College.</p>
          <hr/>
        <p>If you encounter any issues or have any feedback about the site as a whole, you can email me at: <span id='underline' onClick={this.emailIntoClipboard}>feedback@codydhowell.com</span>  <button onClick={this.emailIntoClipboard}>Copy into Clipboard</button>  {this.state.copied && (<span id='copied'>Copied!</span>)}</p>

        <hr />
        Version History for this website:
        <ul>
          <li>1.1.1 - Added a new page for direct concert links, and removed the code to get it through the Homepage. Added a "Missing Page" page for unknown links. </li>
          <li>1.1 - Composition challenges are now available! See Community &gt; Composer Challenge. Also available is the beta for the Lighting Designer! It is quite slow for now, but the interface is up. It can be found in Tools &gt; Stage Builder (beta). Also, removed Playlists.  </li>
          <li>1.0.10 - Added some backend functionality. </li>
          <li>1.0.8 - Removed Community for the forseeable future, added in Tech (with a little light playground that will hopefully become much more useful), adjusted how share links are generated. </li>
          <li>1.0.5 - Updated Score library, added Filters (Sort by), made it look a bit cleaner. </li>
          <li>1.0 - Finally! I didn't make the Database, but I did make a Score Library, so I'm calling it 1.0. I also adjusted the audio player to better show you what's happening with the loading, and if you should go to Drive. </li>
          <li>0.9.7.9.2 - Converted website into the school's primary font, Museo. Adjusted some more aspects of the new Pipeline feature. </li>
          <li>0.9.7.9 - Added a Show Talking button in Home that removes any talking track from display or the "Play All" buttons for concerts. </li>
          <li>0.9.7.8 - Adjusted the backend Audit feature. Adjusted some aspects of the new Pipeline feature. </li>
          <li>0.9.7.5 - Added a feature in Pipeline to show the most recent concerts that have been added. </li>
          <li>0.9.7.2 - Fixed the Grouping bug. Adjusted some Admin features. Implemented a Search page that allows you to see all the concerts with a particular tag.  </li>
          <li>0.9.7 - Added Report buttons. Changed a few things in the backend. Made forms autofill with your username. Added the Directors page. + Found a bug that share links don't go thorugh Grouped Concerts. </li>
          <li>0.9.6.1 - Okay, more of the story. Chrome adjusted how they work with third-party Cookies, which apparently affects my calls to get audio from Drive. I quickly worked around it to get it going again, by running the data through a Cloud Function, which seemed to work, but now longer audio files break because they can't go through the function all the way. I see no simple way of enlarging the function to handle that file size so.. I'm in a lot of trouble. </li>
          <li>0.9.6 - Rewrote some functions due to updates to Chrome (and possibly fixing audio on other browsers). Future updates about audio to come. </li>
          <li>0.9.5.6 - Minor improvement to Playlist Generating UI. Added download buttons to Playlist tracks. </li>
          <li>0.9.5.5 - Groups now show in the Home view (making it easier for multiple days to be seen and ESPECIALLY festival days). </li>
          <li>0.9.5.2 - Playlists now show which user created them, either by their desired name (in Account) or by a random tag assigned to that user. </li>
          <li>0.9.5 - Implemented Playlists! You can have up to 3x your access level (in your account) as a number of playlists, and up to 1000 tracks in a playlist. I've made a few that I'll try and keep updated. Last thing before 1.0 is Databases! ..and People as a part of that. It may be a while. </li>
          <li>0.9.3.5 - Implemented names for the accounts, so you can set your name. The admin will see it before it goes to the public so you can't set it to whatever you want.. but that name will be displayed for Playlists, Challenges, Analyses, etc. </li>
          <li>0.9.3 - Implemented Share buttons for semesters and concerts, it'll open and take you right to that area. </li>
          <li>0.9.2 - Implemented Accounts! There's some more to do, but you can sign in with your actual email or your Snow email (more benefits for the latter) and NO PASSWORDS. I will not have passwords in case of a data breach. See Account for more. </li>
          <li>0.9.0.6 - Added the Pipeline page to show items that are in progress or awaiting audit. Other minor fixes to the backend. Added Download buttons to tracks. Maybe in the future, I'll add a "Download All" on the concert itself. </li>
          <li>0.9.0.3 - Added some more quality of life, reimplemented Lessons, made it so multiple calendar events can show up on the Home page in the same day. Added the Production button for mobile users. </li>
          <li>0.9.0.2.1 - More backend, but I can finally edit concerts that have been posted. I can now upload concerts without programs much easier :P</li>
          <li>0.9.0.2 - Added "In Progress" items at the top of Home (as a dropdown) so that you know what is currently in the pipeline to be uploaded. </li>
          <li>0.9.0.1 - Removed Lessons temporarily. Added a Calendar info spot at the top of the homepage if there's a concert today. </li>
          <li>0.9.0.0 - Made a Custom Audio element! The last large things before 1.0 is creating Accounts, Playlists, and the Database. </li>
          <li>0.8.10.4 - Reworked the look for some backend pages. </li>
          <li>0.8.10.3 - Descriptions that include references to their videos now link directly to the timestamp listed (on desktop, for now).  </li>
          <li>0.8.10.2 - Hovering over some menu items (with a mouse) now allows you to click pages further down (like an actual menu). </li>
          <li>0.8.10 - Implemented Lessons! If you have something you want to share as a piece of advice, you can submit a form for that to be done. </li>
          <li>0.8.9.5.1 - Fixed a bug for concert colors to display incorrectly, just hardcoded an easy catch-all solution, though I may try to fix it.  </li>
          <li>0.8.9.5 - Got more done behind the scenes, added AVP boxes on concerts to immediately see if a concert has audio, video, or a program to see. Debugged some more.  </li>
          <li>0.8.9.2 - Added the Newest Concert field, adjusted Calendar form, many small CSS tweaks for mobile. Added the Performer view to see who performed (may be incorrect). </li>
          <li>0.8.9 - Refactored a lot of background stuff, made it much easier to add. Added a bunch of small touches. </li>
          <li>0.8.4 - Calendar page has been created! Please request concerts and recitals that aren't on the calendar yet. </li>
          <li>0.8.0 - Audio interface at the bottom with a (invisible) queue for playing multiple tracks. Have a display to add individual tracks and an Add All button. </li>
          <li>0.5.0 - Shows concert links to Drive folders. </li>
        </ul>
      </div>
    );
  }
};

export default About;

/*
  databaseEntry: {
    key: "",
    indexedPeopleKey: "",
    concertTitle: "",
    trackIndex: [], // Can either be an int (0 - trackURLs.length), empty/null if possible or < -1 (signifying it belongs to tracks above it), or -1, siginifying it should break and display as it's own line. 
    videoIndex: [], // Shows what timestamp that track is in the video, if applicable
    pieceTitle: [],
    movementNumber: [],
    movementName: [],
    composer: [],
    arranger: [], // Both strings should look like Person, Name Of (possible year composed), removing the year and allowing two years to be indexed (though less easily searchable)
    soloist: [], // Separator can be (-)
    accompanist: [],
    groupName: [],
    director: [],
    names: [], // Each string should look like: "Person I - Inst. 1; Person II - Inst. 1"
    isntruments: [], // STANDARDIZED LIST, see above
    fromShoworOpera: [], // Either "" or "Barber of Seville", etc.
    period: [],
    country: [],
    lyricsOrTranscription: [], // Lyrics if a song, transcription if it's dialogue (talking)
    lyricTranslation: [],
    majorGenre: [],
    subGenre: [], // both standardized
    notes: []
  }

  indexedPeopleEntry: {
    key: "", 
    concertTitle: "",
    peopleKeys: [],
    peopleNames: [],
    peopleRoles: [],
    peopleGroups: [] // Above indexes are each person, individually indexed as all their roles and groups using separators
  }

  personEntry: {
    key: "",
    primaryName: "",
    otherNames: [],
    playedConcerts: [], // Should contain strings such as "2023-11-10 The Magic Flute - Flute/Oboe/Percussion - Snow College Symphony Orchestra/Snow College Chamber Orchestra"
    playedSemesters: []  // Probably invisible, but excellent for searching and adding names into Admin
  }
*/