import { Link } from 'react-router-dom';
import React from 'react';
import { AdminChecks, AdminRequests } from '../firebase';
import { Helmet } from 'react-helmet';
import { AppContext } from './_Context';


export class Admin extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      checks: []
    }
  }
  static contextType = AppContext;


  async componentDidMount(){
    try {
      let getChecks = await AdminChecks.getAdminChecks();
      this.setState({
          checks: getChecks
      });
    } catch (error) {
        console.error('Error fetching check information:', error.message);
    }
  }

  updateAndRemoveCalendar = async (title, key) => {
    let obj = {};
    obj["title"] = document.getElementById(title + "title").value;
    obj["start"] = document.getElementById(title + "start").value;
    obj["classNames"] = document.getElementById(title + "location").value;
    
    await AdminRequests.updateAndRemoveCalendarEvent(obj, key);
    window.location.reload();
  }

  updateAndRemoveLesson = async (title, key) => {
    let obj = {};
    obj["name"] = document.getElementById(title + "name").value;
    obj["title"] = document.getElementById(title + "title").value;
    obj["dateCreated"] = document.getElementById(title + "dateCreated").innerText;
    obj["introText"] = document.getElementById(title + "introText").value;
    obj["youtubeURL"] = document.getElementById(title + "youtubeURL").value;
    obj["tag"] = document.getElementById(title + "tag").value;
    
    await AdminRequests.updateAndRemoveLesson(obj, key);
    window.location.reload();
  }

  updateAndRemoveUR = async (title, key) => {
    let obj = {};
    obj["email"] = document.getElementById(title + "email").value;
    obj["sl"] = document.getElementById(title + "dal").value;
    
    await AdminRequests.updateAndRemoveUR(obj, key);
    window.location.reload();
  }

  updateAndRemoveUNR = async (title, key) => {
    let obj = {};
    obj["name"] = document.getElementById(title + "name").value;
    obj["uid"] = document.getElementById(title + "uid").value;
    
    await AdminRequests.updateAndRemoveUNR(obj, key);
    window.location.reload();
  }

  removeCheck = async (key) => {
    await AdminRequests.removeAdminCheck(key);
    window.location.reload();
  }

  render() {
    let calendarChecks = [];
    let lessonChecks = [];
    let userRequests = [];
    let usernameRequests = [];
    let userReports = [];
    if (this.state.checks.length > 0){
      for (let i = 0; i < this.state.checks.length; i++){
        let uniqueID = this.state.checks[i].title;
        if (this.state.checks[i].type === "calendarEventRequest"){
          calendarChecks.push(
            <div key={uniqueID} className='calendarCheck'>
                 <label htmlFor={uniqueID + 'name'}>Name: </label>
                 <input type='text' name='name' id={uniqueID + 'name'} defaultValue={this.state.checks[i].name}/>
                 <br/>
                 <label htmlFor={uniqueID + 'title'}>Name of Concert: </label>
                 <input type='text' name='title' id={uniqueID + 'title'} defaultValue={this.state.checks[i].title}/>
                 <br/>
                 <label htmlFor={uniqueID + 'start'}>Date: </label>
                 <input type='datetime-local' name='start' id={uniqueID + 'start'} defaultValue={this.state.checks[i].start}/>
                 <br/>
                 <label htmlFor='location'>Location: </label>
                 <select name='location' id={uniqueID + 'location'} defaultValue={this.state.checks[i].location}>
                   <option value={"Concert Hall"}>Concert Hall</option>
                   <option value={"Recital Hall"}>Recital Hall</option>
                   <option value={"Black Box"}>Black Box Theatre</option>
                   <option value={"Theatre"}>Jorgensen Theatre</option>
                   <option value={"Other"}>Other</option>
                 </select>
                 <br/>
                 <button onClick={() => this.updateAndRemoveCalendar(uniqueID, this.state.checks[i].key)}>Allow</button>
                 <button onClick={() => this.removeCheck(this.state.checks[i].key)}>Remove</button>
            </div>
          );
        } else if (this.state.checks[i].type === "lessonRequestForm"){
          lessonChecks.push(
            <div key={uniqueID} className='lessonCheck'>
              <label htmlFor='name'>Name: </label>
              <input type='text' name='name' id={uniqueID + 'name'} defaultValue={this.state.checks[i].name}/>
              <br/>
              <label htmlFor='title'>Name of Lesson: </label>
              <input type='text' name='title' id={uniqueID + 'title'} defaultValue={this.state.checks[i].title}/>
              <br/>
              <label htmlFor='youtubeURL'>YouTube Link: </label>
              <input type='url' name='youtubeURL' id={uniqueID + 'youtubeURL'} readOnly value={this.state.checks[i].youtubeLink}/>
              <a href={this.state.checks[i].youtubeLink}>Test</a>
              <br/>
              <label htmlFor='introText'>Introductory Text: </label>
              <textarea name='introText' id={uniqueID + 'introText'} defaultValue={this.state.checks[i].introText}/>
              <br/>
              <label htmlFor='tag'>Tag: </label>
              <input name='tag' id={uniqueID + 'tag'} defaultValue={this.state.checks[i].tag} readOnly={true}/>
              <p>Timestamp: <span id={uniqueID + 'dateCreated'}>{new Date(this.state.checks[i].dateCreated).toString()}</span></p>


              <button onClick={() => this.updateAndRemoveLesson(uniqueID, this.state.checks[i].key)}>Allow</button>
              <button onClick={() => this.removeCheck(this.state.checks[i].key)}>Remove</button>
            </div>
          );
        } else if (this.state.checks[i].type === "userAccessRequest"){
          userRequests.push(
            <div key={uniqueID} className='userCheck'>
              <label htmlFor='email'>Email: </label>
              <input type='text' name='email' id={uniqueID + 'email'} defaultValue={this.state.checks[i].user}/>
              <br/>
              <label htmlFor='cal'>Current Access Level: </label>
              <input type='number' name='cal' id={uniqueID + 'cal'} defaultValue={this.state.checks[i].currentSL}/>
              <br/>
              <label htmlFor='dal'>Desired Access Level: </label>
              <input type='number' name='dal' id={uniqueID + 'dal'} readOnly value={this.state.checks[i].desiredSL}/>
              <br/>
              <label htmlFor='explainText'>Explanatory Text: </label>
              <textarea name='explainText' id={uniqueID + 'explainText'} defaultValue={this.state.checks[i].text}/>

              <button onClick={() => this.updateAndRemoveUR(uniqueID, this.state.checks[i].key)}>Accept</button>
              <button onClick={() => this.removeCheck(this.state.checks[i].key)}>Remove</button>
            </div>
          );
        } else if (this.state.checks[i].type === "userNameRequest"){
          usernameRequests.push(
            <div key={uniqueID} className='nameAccept'>
              <label htmlFor='email'>Email: </label>
              <input type='text' name='email' id={uniqueID + 'email'} defaultValue={this.state.checks[i].user}/>
              <br/>
              <label htmlFor='name'>Desired Name: </label>
              <input type='text' name='name' id={uniqueID + 'name'} defaultValue={this.state.checks[i].name}/>
              <br/>
              <label htmlFor='uid'>UID: </label>
              <input type='text' name='uid' id={uniqueID + 'uid'} defaultValue={this.state.checks[i].uid}/>

              <button onClick={() => this.updateAndRemoveUNR(uniqueID, this.state.checks[i].key)}>Allow</button>
              <button onClick={() => this.removeCheck(this.state.checks[i].key)}>Remove</button>
            </div>
          );
        } else if (this.state.checks[i].type === "userReport"){
          userReports.push(
            <div key={uniqueID} className='userReport'>
              <p>Concert: {this.state.checks[i].concert}</p>
              <p>Option: {this.state.checks[i].options}</p>
              {this.state.checks[i].other !== "" && (<p>Other Text: {this.state.checks[i].other}</p>)}
              <p>User: {this.state.checks[i].user}</p>
              <p>Timestamp: {new Date(this.state.checks[i].time).toLocaleString()}</p>

              <button onClick={() => this.removeCheck(this.state.checks[i].key)}>Remove</button>
            </div>
          );
        }
      }
    }
    const context = this.context;
    return (
      <div id='admin'>
        <Helmet>
          <title>Admin</title>
        </Helmet>
        {context.user.sl >= 9 ? (
        <>
          <h1>Admin Dashboard</h1>
          <div id='fullButtons'>
            <Link to="/admin/concertimport"><div><p>To Concert Import</p></div></Link>
            <Link to="/admin/inprogress"><div><p>To In Progress</p></div></Link>
            <Link to="/admin/updateconcert"><div><p>To Update Concert</p></div></Link>
            <Link to="/admin/userlist"><div><p>To User List</p></div></Link>
            <Link to="/admin/minorupdates"><div><p>To Minor Updates</p></div></Link>
            <Link to="/admin/expensivebuttons"><div><p>To Expensive Buttons</p></div></Link>
          </div>
          <br/>
          {/* <div id='adminWebsiteInfo'>
            <p>This website runs off of React and Firebase calls. The structure of the files is mostly in /src, and you need to run build to go to the build folder (delete the .map at the bottom for Firebase privacy) and 
              then pretty much everything is modularized. App.js holds the main file and routing, and everything else pops in from their own file in /components. The CSS is accomplished with App.scss being compiled and including
              all of the other SCSS files in /scsscomp. For each new one, you have to make sure it has an _ at the start, include the library, create the mixin, and import it in App. 
            </p>
          </div> */}
          <div id='requestBlock'>
            {calendarChecks.length > 0 && (
              <div id='calendarRequest'>
                <h2>Calendar Requests</h2>
                {calendarChecks}
              </div>
            )}
            {lessonChecks.length > 0 && (
              <div id='lessonRequest'>
                <h2>Lesson Requests</h2>
                {lessonChecks}
              </div>
            )}
            {userRequests.length > 0 && (
              <div id='userRequest'>
                <h2>User Requests</h2>
                {userRequests}
              </div>
            )}
            {usernameRequests.length > 0 && (
              <div id='usernameRequest'>
                <h2>Username Requests</h2>
                {usernameRequests}
              </div>
            )}
            {userReports.length > 0 && (
              <div id='userReports'>
                <h2>Reports</h2>
                {userReports}
              </div>
            )}
          </div>
        </>
        ) : (
          <div>
            <h1>Admin</h1>
            <p>You don't have access to be here. Did you make a mistake?  </p>
          </div>
        )}
      </div>
    );
  }
};
