let instruments = [
  { "value": "Alto", "label": "Alto" },
  { "value": "Bass", "label": "Bass" },
  { "value": "Harp", "label": "Harp" },
  { "value": "Horn", "label": "Horn" },
  { "value": "Oboe", "label": "Oboe" },
  { "value": "Tech", "label": "Tech" },
  { "value": "Tuba", "label": "Tuba" },
  { "value": "Cello", "label": "Cello" },
  { "value": "Drums", "label": "Drums" },
  { "value": "Flute", "label": "Flute" },
  { "value": "Organ", "label": "Organ" },
  { "value": "Piano", "label": "Piano" },
  { "value": "Tenor", "label": "Tenor" },
  { "value": "Viola", "label": "Viola" },
  { "value": "Alto I", "label": "Alto I" },
  { "value": "Bass I", "label": "Bass I" },
  { "value": "Encore", "label": "Encore" },
  { "value": "Guitar", "label": "Guitar" },
  { "value": "Horn I", "label": "Horn I" },
  { "value": "Oboe I", "label": "Oboe I" },
  { "value": "Singer", "label": "Singer" },
  { "value": "Violin", "label": "Violin" },
  { "value": "Vocals", "label": "Vocals" },
  { "value": "Alto II", "label": "Alto II" },
  { "value": "Bass II", "label": "Bass II" },
  { "value": "Bassoon", "label": "Bassoon" },
  { "value": "Cello I", "label": "Cello I" },
  { "value": "Horn II", "label": "Horn II" },
  { "value": "Horn IV", "label": "Horn IV" },
  { "value": "Marimba", "label": "Marimba" },
  { "value": "Oboe II", "label": "Oboe II" },
  { "value": "Piccolo", "label": "Piccolo" },
  { "value": "Soloist", "label": "Soloist" },
  { "value": "Soprano", "label": "Soprano" },
  { "value": "Tenor I", "label": "Tenor I" },
  { "value": "Timpani", "label": "Timpani" },
  { "value": "Trumpet", "label": "Trumpet" },
  { "value": "Viola I", "label": "Viola I" },
  { "value": "Cello II", "label": "Cello II" },
  { "value": "Clarinet", "label": "Clarinet" },
  { "value": "Director", "label": "Director" },
  { "value": "Horn III", "label": "Horn III" },
  { "value": "Keyboard", "label": "Keyboard" },
  { "value": "Tenor II", "label": "Tenor II" },
  { "value": "Trombone", "label": "Trombone" },
  { "value": "Viola II", "label": "Viola II" },
  { "value": "Violin I", "label": "Violin I" },
  { "value": "Vocalist", "label": "Vocalist" },
  { "value": "Beatboxer", "label": "Beatboxer" },
  { "value": "Euphonium", "label": "Euphonium" },
  { "value": "Horn in F", "label": "Horn in F" },
  { "value": "Saxophone", "label": "Saxophone" },
  { "value": "Soprano I", "label": "Soprano I" },
  { "value": "Trumpet I", "label": "Trumpet I" },
  { "value": "Trumpet V", "label": "Trumpet V" },
  { "value": "Violin II", "label": "Violin II" },
  { "value": "Xylophone", "label": "Xylophone" },
  { "value": "Alto Flute", "label": "Alto Flute" },
  { "value": "Bass Flute", "label": "Bass Flute" },
  { "value": "Clarinet I", "label": "Clarinet I" },
  { "value": "Lead Vocal", "label": "Lead Vocal" },
  { "value": "Percussion", "label": "Percussion" },
  { "value": "Soprano II", "label": "Soprano II" },
  { "value": "Trombone I", "label": "Trombone I" },
  { "value": "Trumpet II", "label": "Trumpet II" },
  { "value": "Trumpet IV", "label": "Trumpet IV" },
  { "value": "Vibraphone", "label": "Vibraphone" },
  { "value": "Accompanist", "label": "Accompanist" },
  { "value": "Bass Guitar", "label": "Bass Guitar" },
  { "value": "Bb Clarinet", "label": "Bb Clarinet" },
  { "value": "Cast Member", "label": "Cast Member" },
  { "value": "Clarinet II", "label": "Clarinet II" },
  { "value": "Double Bass", "label": "Double Bass" },
  { "value": "Eb Clarinet", "label": "Eb Clarinet" },
  { "value": "French Horn", "label": "French Horn" },
  { "value": "Lead Guitar", "label": "Lead Guitar" },
  { "value": "String Bass", "label": "String Bass" },
  { "value": "Trombone II", "label": "Trombone II" },
  { "value": "Trumpet III", "label": "Trumpet III" },
  { "value": "Alto Soloist", "label": "Alto Soloist" },
  { "value": "Bass Soloist", "label": "Bass Soloist" },
  { "value": "Choir Member", "label": "Choir Member" },
  { "value": "Clarinet III", "label": "Clarinet III" },
  { "value": "English Horn", "label": "English Horn" },
  { "value": "Lead Trumpet", "label": "Lead Trumpet" },
  { "value": "Trombone III", "label": "Trombone III" },
  { "value": "Alto Clarinet", "label": "Alto Clarinet" },
  { "value": "Backup Vocals", "label": "Backup Vocals" },
  { "value": "Bass Clarinet", "label": "Bass Clarinet" },
  { "value": "Bass Trombone", "label": "Bass Trombone" },
  { "value": "Tenor Soloist", "label": "Tenor Soloist" },
  { "value": "Alto Saxophone", "label": "Alto Saxophone" },
  { "value": "Aux. Percussion", "label": "Aux. Percussion" },
  { "value": "Soprano Soloist", "label": "Soprano Soloist" },
  { "value": "Tenor Saxophone", "label": "Tenor Saxophone" },
  { "value": "Alto Saxophone I", "label": "Alto Saxophone I" },
  { "value": "Baritone Soloist", "label": "Baritone Soloist" },
  { "value": "Eb Alto Clarinet", "label": "Eb Alto Clarinet" },
  { "value": "Alto Saxophone II", "label": "Alto Saxophone II" },
  { "value": "Alto Saxophone IV", "label": "Alto Saxophone IV" },
  { "value": "Assitant Director", "label": "Assitant Director" },
  { "value": "Soprano Saxophone", "label": "Soprano Saxophone" },
  { "value": "Tenor Saxophone I", "label": "Tenor Saxophone I" },
  { "value": "Alto Saxophone III", "label": "Alto Saxophone III" },
  { "value": "Baritone Saxophone", "label": "Baritone Saxophone" },
  { "value": "Tenor Saxophone II", "label": "Tenor Saxophone II" },
  { "value": "Tenor Saxophone IV", "label": "Tenor Saxophone IV" },
  { "value": "Soprano Saxophone I", "label": "Soprano Saxophone I" },
  { "value": "Tenor Saxophone III", "label": "Tenor Saxophone III" },
  { "value": "Baritone Saxophone I", "label": "Baritone Saxophone I" },
  { "value": "Contra-Alto Clarinet", "label": "Contra-Alto Clarinet" },
  { "value": "Soprano Saxophone II", "label": "Soprano Saxophone II" },
  { "value": "Soprano Saxophone IV", "label": "Soprano Saxophone IV" },
  { "value": "Baritone Saxophone II", "label": "Baritone Saxophone II" },
  { "value": "Mezzo-Soprano Soloist", "label": "Mezzo-Soprano Soloist" },
  { "value": "Soprano Saxophone III", "label": "Soprano Saxophone III" },
  { "value": "Eb Contra-Alto Clarinet", "label": "Eb Contra-Alto Clarinet" }]


export { instruments };