import React from 'react';
import { BrowserRouter as Router, Route, Routes,  Link, BrowserRouter } from 'react-router-dom';
import { Concerts, GetSemesterInfo } from '../firebase';
import { AppContext } from './_Context';
import { Helmet } from 'react-helmet';
import CreatableSelect from 'react-select/creatable';
import { instruments } from "./_Instruments";

export class UpdateConcert extends React.Component {
  constructor(props){
    super(props);
    this.selectRef1 = React.createRef();
    this.selectRef2 = React.createRef();
    this.selectRef3 = React.createRef();
    this.state = {
      concertInfo: {},
      possibleSemesters: [],
      trackNumber: 0, 
      invalidName: false,
      performers: [],
      performerNames: [],
      currentPerformer: { name: "", instruments: "", groups: "" },
      groups: []
    }
  }
  static contextType = AppContext;

  async componentDidMount(){
    try {
      const semesterList = await GetSemesterInfo.getPossibleSemesters();
      this.setState({
        possibleSemesters: semesterList
      });
    } catch (error) {
      console.error('Error fetching semesters:', error.message);
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();


    const filename = document.getElementById("fileTitle").value;

    const formElement = document.getElementById("tagCheckboxImportForm");
    const formData = new FormData(formElement);
    var tagArray = [];
    formData.forEach((value, key) => {
      tagArray.push(key);
    });

    const formElement2 = document.getElementById("mainConcertInfoForm");
    const formData2 = new FormData(formElement2);
    formData2.forEach((value, key) => {
      formData[key] = value;
    });

    const semester = document.getElementById("semesterSelected").value;
    formData["semesterSelected"] = semester;
    
    const performersArray = this.state.performers;
    const directorsArray = document.getElementById('directorsArray').value.split(',').map(item => item.trim());
    const groupsArray = document.getElementById('groupsArray').value.split(',').map(item => item.trim());
    const featuredGuestsArray = document.getElementById('featuredGuestsArray').value.split(',').map(item => item.trim());
    const featuredSoloistsArray = document.getElementById('featuredSoloistsArray').value.split(',').map(item => item.trim());
    const accompanistsArray = document.getElementById('accompanistsArray').value.split(',').map(item => item.trim());

    formData["performersArray"] = performersArray;
    formData["directorsArray"] = directorsArray;
    formData["groupsArray"] = groupsArray;
    formData["featuredGuestsArray"] = featuredGuestsArray;
    formData["featuredSoloistsArray"] = featuredSoloistsArray;
    formData["accompanistsArray"] = accompanistsArray;
    formData["toPublic"] = document.getElementById("toPublic").checked;
    formData["techCheck"] = document.getElementById("techCheck").checked;

    var numberOfTracks = document.getElementById("numberOfTracks").value;
    let durationArray = [];
    let trackURLArray = [];
    let talkingArray = [];
    for (let i = 1; i <= numberOfTracks; i++){
      const singleTrackURL = document.getElementById(`form-${i}url`).value;
      const singleDuration = document.getElementById(`form-${i}length`).value;
      const talkingCheck = document.getElementById(`form-${i}talking`).checked;
      talkingArray.push(talkingCheck);
      durationArray.push(singleDuration);
      trackURLArray.push(extractFileIdFromUrl(singleTrackURL));
    }

    let totalDuration = sumTimestamps(durationArray);
    formData["totalDuration"] = totalDuration;
    formData["trackURLs"] = trackURLArray;
    formData["trackLengths"] = durationArray;
    formData["trackTalking"] = talkingArray;

    formData["fileTitle"] = filename;
    formData["tags"] = tagArray;
    formData["databaseLink"] = "";

    formData["techsWhoChecked"] = []; //Empty array for getting checked by techs
    formData["techsWhoCommented"] = []; //Empty array for comments by techs

    await Concerts.updateConcertDocument(formData);
    console.log("SilDEBUG: Concert uploaded.");

    var semesterInfo = await GetSemesterInfo.getAllSemesterInformation(); // Updates the array read off of in Home for 1 document read.
    for (let i = 0; i < semesterInfo.array.length; i++){
      if (semesterInfo.array[i] === formData["semesterSelected"]){
        var durationAddition = [semesterInfo.duration[i]];
        durationAddition.push(this.state.concertInfo.totalDuration);
        let finalDurationString = sumTimestamps(durationAddition, true); // Subtract previous concert duration from current duration listed
        durationAddition = [finalDurationString, totalDuration];
        finalDurationString = sumTimestamps(durationAddition);
        semesterInfo.duration[i] = finalDurationString;
        semesterInfo.trackCount[i] -= parseInt(this.state.concertInfo.trackURLs.length); 
        semesterInfo.trackCount[i] += parseInt(numberOfTracks); 
      }
    }

    await Concerts.updateInitialConcertValues(semesterInfo);

    window.location.reload();
  }

  handleDelete = async () => {
    var semesterInfo = await GetSemesterInfo.getAllSemesterInformation(); // Updates the array read off of in Home for 1 document read.
    for (let i = 0; i < semesterInfo.array.length; i++){
      if (semesterInfo.array[i] === this.state.concertInfo["semesterSelected"]){
        var durationAddition = [semesterInfo.duration[i]];
        durationAddition.push(this.state.concertInfo.totalDuration);
        let finalDurationString = sumTimestamps(durationAddition, true); // Subtract previous concert duration from current duration listed
        semesterInfo.duration[i] = finalDurationString;
        semesterInfo.trackCount[i] -= parseInt(this.state.concertInfo.trackURLs.length); 
        semesterInfo.concertCount[i]--;
      }
    }
    await Concerts.updateInitialConcertValues(semesterInfo);
    await Concerts.deleteConcert(this.state.concertInfo.fileTitle);
    window.location.reload();
  }

  renderForms = () => {
    const forms = [];    
    for (let i = 0; i <= this.state.trackNumber; i++) {
      // Generate a unique identifier for each form element
      const uniqueId = `form-${i+1}`;
      forms.push(
        <>
          <label htmlFor={uniqueId + "url"}>Database Line {i+1}:</label>
          <input type="url" id={uniqueId + "url"} name={uniqueId + "url"} defaultValue={this.state.concertInfo.trackURLs[i]} />
          <label htmlFor={uniqueId + "length"}>Length {i+1}:</label>
          <input type="text" id={uniqueId + "length"} name={uniqueId + "length"} defaultValue={this.state.concertInfo.trackLengths[i]}/>
          <label htmlFor={uniqueId + "talking"}>Talking {i+1}:</label>
          <input type="checkbox" id={uniqueId + "talking"} name={uniqueId + "talking"} defaultChecked={this.state.concertInfo.trackTalking[i]}/>
        </>
      );
    }

    return forms;
  }

  handleConcertFetch = async () => {
    const key = document.querySelector('#concertName').value;
    let obj = await Concerts.getExactConcert(key);
    if (obj !== undefined){
      let performerNames = [];
      for (let i = 0; i < obj.performersArray.length; i++){
        let name = obj.performersArray[i].split(' - ')[0];
        performerNames.push(name);
      }
      this.setState({concertInfo: obj, trackNumber: obj.trackURLs.length-1, invalidName: false, performers: obj.performersArray, performerNames: performerNames});
    } else {
      document.querySelector('#concertName').value = "";
      this.setState({concertInfo:{}, invalidName: true});
    }
  }

  updateTrackNumberState = () => {
    var numberOfTracks = document.getElementById("numberOfTracks").value - 1;
    if (numberOfTracks){
      this.setState({trackNumber: numberOfTracks});
    }
  }

  inputPerformer = () => {
    let performers = [...this.state.performers];
    let performerName = [...this.state.performerNames];
    if (!(performerName.includes(this.state.currentPerformer.name))) {
      let string = `${this.state.currentPerformer.name} - ${this.state.currentPerformer.instruments} - ${this.state.currentPerformer.groups}`;
      performers.push(string);
      performerName.push(this.state.currentPerformer.name);
      this.setState({ performers: performers });
      this.setState({ performerNames: performerName });
      this.setState({ currentPerformer: { name: "", instruments: "", groups: "" } });
    } else {
      this.updateName(performers);
    }
    this.selectRef1.current.setValue("");
    this.selectRef2.current.setValue("");
    this.selectRef3.current.setValue("");
    this.selectRef1.current.focus();
  }

  removePerformer = (i) => {
    let array = [...this.state.performers];
    array.splice(i, 1);
    this.setState({ performers: array });
  }

  handleName = (name) => {
    let obj = this.state.currentPerformer;
    obj["name"] = name.value;
    this.setState({ currentPerformer: obj }); 
  };

  handleInstruments = (instr) => {
    let obj = this.state.currentPerformer;
    let instruments = [];
    for (let i = 0; i < instr.length; i++) {
      instruments.push(instr[i].value);
    }
    let string = instruments.join("/");
    obj["instruments"] = string;
    this.setState({ currentPerformer: obj }); 
  };

  handleGroups = (groups) => {
    let obj = this.state.currentPerformer;
    let groupNames = [];
    for (let i = 0; i < groups.length; i++) {
      groupNames.push(groups[i].value);
    }
    let string = groupNames.join("/");
    obj["groups"] = string;
    this.setState({ currentPerformer: obj }); 
  };

  updateGroups = () => {
    let string = document.querySelector('#groupsArray').value;
    let array = string.split(", ");
    let groups = [];
    for (let i = 0; i < array.length; i++) {
      let string = array[i];
      let innerArray = string.split(" ");
      let outString = "";
      for (let j = 0; j < innerArray.length; j++) {
        outString += innerArray[j][0];
      }
      let obj = {};
      obj["value"] = outString;
      obj["label"] = array[i];
      obj["isFixed"] = true;
      groups.push(obj);
    }
    this.setState({ groups: groups });
  }

  updateName = (performers) => {
    for (let i = 0; i < performers.length; i++) {
      let instrument, name, group;
      [name, instrument, group] = performers[i].split(' - ');
      if (name === this.state.currentPerformer.name) {
        let instruments = instrument.split('/');
        let groups = group.split('/');
        let currentInstruments = this.state.currentPerformer.instruments.split("/");
        let currentGroups = this.state.currentPerformer.groups.split("/");
        for (let j = 0; j < currentInstruments.length; j++) {
          if (!(instruments.includes(currentInstruments[j]))) { instruments.push(currentInstruments[j]); }
        }
        for (let j = 0; j < currentGroups.length; j++) {
          if (!(groups.includes(currentGroups[j]))) { groups.push(currentGroups[j]); }
        }
        let string = `${name} - ${instruments.join("/")} - ${groups.join("/")}`
        performers[i] = string;
        this.setState({ performers: performers });
      }
    }
  }
  
  render() {
    let performers = [];
    for (let i = 0; i < this.state.performerNames.length; i++) {
      let obj = { value: this.state.performerNames[i], label: this.state.performerNames[i] };
      performers.push(obj);
    }
    let completedPerformers = [];
    for (let i = 0; i < this.state.performers.length; i++) {
      completedPerformers.push(<span key={i + "performer"}>{this.state.performers[i]} <button onClick={() => this.removePerformer(i)}>Remove</button><br /></span>);
    }
    const semesterOptions = this.state.possibleSemesters.map((semester, key) => <option key={key} value={semester}>{semester}</option>);
    var formattedDateTime;
    if (this.state.concertInfo.concertDateTime){
      const concertDateTime = new Date(this.state.concertInfo.concertDateTime);
      const timezoneOffset = concertDateTime.getTimezoneOffset();
      const adjustedDateTime = new Date(concertDateTime.getTime() - timezoneOffset * 60000);
      formattedDateTime = adjustedDateTime.toISOString().slice(0, 16);
    }
    const context = this.context;
    return (
      <div id='updateConcertPage'>
        <Helmet>
          <title>Update Concert</title>
        </Helmet>
        {context.user.sl >= 9 && (
          <>
          <h1>Update Concert</h1>
          <p><Link to="/admin">Back to Admin</Link></p>
  
          <label htmlFor='concertName'>Concert Name:</label>
          <input type='text' name='concertName' id='concertName'/>
          <button onClick={this.handleConcertFetch}>Submit</button>
          {this.state.invalidName && (<><br/><span id='invalidPara'>That was an invalid name. Please try again.</span></>)}
          {JSON.stringify(this.state.concertInfo) !== '{}' && (
              <div id='concertImport'>
              <label htmlFor='fileTitle'>File Title: </label>
              <input type='text' id='fileTitle' name='fileTitle' placeholder='20XX-XX-XX Concert Title' defaultValue={this.state.concertInfo.fileTitle} disabled={true}/>
              
              <div id='tagsCheckboxImport'>
                <h3>Tags:  </h3>
                <form id='tagCheckboxImportForm'>
                  <div className='individualTags'>
                    <label htmlFor='Commercial-Music-Ensemble'>Commercial Music Ensemble</label>
                    <input type='checkbox' className='tagCheckboxImport' id='Commercial-Music-Ensemble' name='Commercial Music Ensemble' value="true" defaultChecked={this.state.concertInfo.tags.includes("Commercial Music Ensemble")}/>
                  </div>
                  <div className='individualTags'>
                    <label htmlFor='Orchestral'>Orchestral</label>
                    <input type='checkbox' className='tagCheckboxImport' id='Orchestral' name='Orchestral' value="true" defaultChecked={this.state.concertInfo.tags.includes("Orchestral")}/>
                  </div>
                  <div className='individualTags'>
                    <label htmlFor='Spotlight'>Spotlight</label>
                    <input type='checkbox' className='tagCheckboxImport' id='Spotlight' name='Spotlight' value="true" defaultChecked={this.state.concertInfo.tags.includes("Spotlight")}/>
                  </div>
                  <div className='individualTags'>
                    <label htmlFor='Jazz'>Jazz</label>
                    <input type='checkbox' className='tagCheckboxImport' id='Jazz' name='Jazz' value="true" defaultChecked={this.state.concertInfo.tags.includes("Jazz")}/>
                  </div>
                  <div className='individualTags'>
                    <label htmlFor='Chamber'>Chamber</label>
                    <input type='checkbox' className='tagCheckboxImport' id='Chamber' name='Chamber' value="true" defaultChecked={this.state.concertInfo.tags.includes("Chamber")}/>
                  </div>
                  <div className='individualTags'>
                    <label htmlFor='Large-Ens'>Large Ens.</label>
                    <input type='checkbox' className='tagCheckboxImport' id='Large-Ens' name='Large Ens.' value="true" defaultChecked={this.state.concertInfo.tags.includes("Large Ens.")}/>
                  </div>
                  <div className='individualTags'>
                    <label htmlFor='Band'>Band</label>
                    <input type='checkbox' className='tagCheckboxImport' id='Band' name='Band' value="true" defaultChecked={this.state.concertInfo.tags.includes("Band")}/>
                  </div>
                  <div className='individualTags'>
                    <label htmlFor='Recital'>Recital</label>
                    <input type='checkbox' className='tagCheckboxImport' id='Recital' name='Recital' value="true" defaultChecked={this.state.concertInfo.tags.includes("Recital")}/>
                  </div>
                  <div className='individualTags'>
                    <label htmlFor='Choral'>Choral</label>
                    <input type='checkbox' className='tagCheckboxImport' id='Choral' name='Choral' value="true" defaultChecked={this.state.concertInfo.tags.includes("Choral")}/>
                  </div>
                  <div className='individualTags'>
                    <label htmlFor='Opera_Musical'>Opera/Musical</label>
                    <input type='checkbox' className='tagCheckboxImport' id='Opera_Musical' name='Opera/Musical' value="true" defaultChecked={this.state.concertInfo.tags.includes("Opera/Musical")}/>
                  </div>
                  <div className='individualTags'>
                    <label htmlFor='Local'>Local</label>
                    <input type='checkbox' className='tagCheckboxImport' id='Local' name='Local' value="true" defaultChecked={this.state.concertInfo.tags.includes("Local")}/>
                  </div>
                  <div className='individualTags'>
                    <label htmlFor='Faculty'>Faculty</label>
                    <input type='checkbox' className='tagCheckboxImport' id='Faculty' name='Faculty' value="true" defaultChecked={this.state.concertInfo.tags.includes("Faculty")}/>
                  </div>
                  <div className='individualTags'>
                    <label htmlFor='Special'>Special</label>
                    <input type='checkbox' className='tagCheckboxImport' id='Special' name='Special' value="true" defaultChecked={this.state.concertInfo.tags.includes("Special")}/>
                  </div>
                  <div className='individualTags'>
                    <label htmlFor='Soloist'>Soloist</label>
                    <input type='checkbox' className='tagCheckboxImport' id='Soloist' name='Soloist' value="true" defaultChecked={this.state.concertInfo.tags.includes("Soloist")}/>
                  </div>
                  <div className='individualTags'>
                    <label htmlFor='Artist'>Artist</label>
                    <input type='checkbox' className='tagCheckboxImport' id='Artist' name='Artist' value="true" defaultChecked={this.state.concertInfo.tags.includes("Artist")}/>
                  </div>
                </form>
              </div>
  
  
              <h2>Main Concert Info:</h2>
              <div id='mainConcertInfo'>
                <form id='mainConcertInfoForm'>
                  <div>
                    <label htmlFor='concertTitle'>Concert Title:  </label>
                    <input type='text' id='concertTitle' name='concertTitle' placeholder='Specific concert title' defaultValue={this.state.concertInfo.concertTitle}/>
                  </div>
                  <div>
                    <label htmlFor='semesterSelected'>Semester:  </label>
                    <select id='semesterSelected' name='semesterSelected' defaultValue={this.state.concertInfo.semesterSelected} disabled={true}>
                    <option>Test Semester</option>
                    {semesterOptions}
                    </select>
                  </div>
                  <div>
                    <label htmlFor='concertDateTime'>Date:  </label>
                    <input type='datetime-local' id='concertDateTime' name='concertDateTime' defaultValue={formattedDateTime}/><br/>
                  </div>
                  <div>
                    <label htmlFor='concertDescription'>Description:  </label>
                    <textarea id='concertDescription' name='concertDescription' placeholder='Generic description text with (;) linebreaks and |h:mm:ss| timecodes.' defaultValue={this.state.concertInfo.concertDescription}/><br/>
                  </div>
                  <div>
                    <label htmlFor='programLink'>Program Link:  </label>
                    <input type='url' id='programLink' name='programLink' placeholder='drive.google.com/d/' defaultValue={this.state.concertInfo.programLink}/><br/>
                  </div>
                  <div>
                    <label htmlFor='extraMarketingLink'>Extra Marketing:  </label>
                    <input type='url' id='extraMarketingLink' name='extraMarketingLink' placeholder='drive.google.com/d/' defaultValue={this.state.concertInfo.extraMarketingLink}/><br/>
                  </div>
                  <div>
                    <label htmlFor='videoLink'>Video Link:  </label>
                    <input type='url' id='videoLink' name='videoLink' placeholder='drive.google.com/d/' defaultValue={this.state.concertInfo.videoLink}/><br/>
                  </div>
                  <div>
                    <label htmlFor='backupGoogleLink'>Google Drive folder backup link:  </label>
                    <input type='url' id='backupGoogleLink' name='backupGoogleLink' placeholder='IMPORTANT drive.google.com/d/' defaultValue={this.state.concertInfo.backupGoogleLink}/><br/>
                  </div>
                  <div>
                    <label htmlFor='concertGrouping'>Grouping (for festivals, etc.):  </label>
                    <input type='text' id='concertGrouping' name='concertGrouping' placeholder='Specific Group Name' defaultValue={this.state.concertInfo.concertGrouping}/><br/>
                  </div>
                </form>
              </div>
  
  
                <h2>People:</h2>
                <div id='peopleSection'>
                  <div>
                    <div id='selectForms'>
                      <CreatableSelect options={performers} onChange={this.handleName} ref={this.selectRef1} className='selectForm' placeholder='Person' />
                      <CreatableSelect isMulti options={instruments} onChange={this.handleInstruments} ref={this.selectRef2} className='selectForm' placeholder='Instruments' />
                      <CreatableSelect isMulti options={this.state.groups} onChange={this.handleGroups} ref={this.selectRef3} className='selectForm' placeholder='Groups' />
                    </div>
                    <button onClick={this.inputPerformer}>Submit</button> <br />
                    <label htmlFor='performersArray'>Performers:  (count: {this.state.performers.length})</label>
                    {this.state.performers.length > 0 && (<div id='completedPerformersArea'>{completedPerformers}</div>)}
                  </div>
                  <p>Make sure not to leave any open commas after an item (so something like Dr. Brent Smith, ), as that will add more entries than needed.
                    This applies to all people inputs. </p>
                  <div>
                    <label htmlFor='directorsArray'>Directors:  </label>
                    <input type='text' id='directorsArray' name='directorsArray' placeholder='Director I, Director II' defaultValue={this.state.concertInfo.directorsArray.join(', ')}/><br />
                  </div>
                  <div>
                    <label htmlFor='groupsArray'>Groups:  </label>
                    <input type='text' id='groupsArray' name='groupsArray' placeholder='Group I, Group II' onLoadedData={this.updateGroups} onBlur={this.updateGroups} defaultValue={this.state.concertInfo.groupsArray.join(', ')} /><br />
                  </div>
                  <div>
                    <label htmlFor='featuredGuestsArray'>Featured Guests:  </label>
                    <input type='text' id='featuredGuestsArray' name='featuredGuestsArray' placeholder='Guest I, Guest II' defaultValue={this.state.concertInfo.featuredGuestsArray.join(', ')} /><br />
                  </div>
                  <div>
                    <label htmlFor='featuredSoloistsArray'>Featured Soloists:  </label>
                    <input type='text' id='featuredSoloistsArray' name='featuredSoloistsArray' placeholder='Soloist I, Soloist II' defaultValue={this.state.concertInfo.featuredSoloistsArray.join(', ')} /><br />
                  </div>
                  <div>
                    <label htmlFor='accompanistsArray'>Accompanists:  </label>
                    <input type='text' id='accompanistsArray' name='accompanistsArray' placeholder='Accomp. I, Accomp. II' defaultValue={this.state.concertInfo.accompanistsArray.join(', ')} /><br />
                  </div>
                </div>
  
              <div id='bottomCheckboxes'>
                <div>
                  <label htmlFor='toPublic'>Show to public immediately?</label>
                  <input type='checkbox' id='toPublic' name='toPublic' defaultChecked={this.state.concertInfo.toPublic}/><br/>
                </div>
                <div>
                  <label htmlFor='techCheck'>Mark as completed by techs?</label>
                  <input type='checkbox' id='techCheck' name='techCheck' defaultChecked={this.state.concertInfo.techCheck}/><br/>
                </div>
              </div>
  
              <br/>
  
              <label htmlFor='numberOfTracks'>Number of Tracks:  </label>
              <input type='number' id='numberOfTracks' name='numberOfTracks' defaultValue={this.state.concertInfo.trackURLs.length}/><br/>
              <div id='formsArray'>
                  {this.renderForms()}
              </div>
  
              <button id='trackNumberButton' onClick={this.updateTrackNumberState}>Update Track Number</button>
              <button type="submit" onDoubleClick={this.handleSubmit}>(Double-Click) Submit</button>
              <button id='redDeleteButton' onDoubleClick={this.handleDelete}>(Double-Click) Delete</button>
  
          </div>
          )}
          </>
        )}
      </div>
    );
  }
}

// Some assorted functions
function sumTimestamps(timestamps, sub = false) {

  // Initialize variables to hold the total hours, total minutes, and total seconds
  let totalHours = 0;
  let totalMinutes = 0;
  let totalSeconds = 0;

  // Iterate through the timestamps and add up the hours, minutes, and seconds
  for (let i = 0; i < timestamps.length; i++){
    if (i % 2 !== 0 && sub){ // Subtracts every other if sub is true
      if (timestamps[i].length < 6){
        const [minutes, seconds] = timestamps[i].split(':');
        totalMinutes -= parseInt(minutes, 10);
        totalSeconds -= parseInt(seconds, 10);
      } else {
        const [hours, minutes, seconds] = timestamps[i].split(':');
        totalHours -= parseInt(hours, 10);
        totalMinutes -= parseInt(minutes, 10);
        totalSeconds -= parseInt(seconds, 10);
      }
      if (totalSeconds < 0) {totalMinutes--; totalSeconds += 60;} // Account for overflow. Took me long enough.
      if (totalMinutes < 0) {totalHours--; totalMinutes += 60;}
    } else {
      if (timestamps[i].length < 6){
        const [minutes, seconds] = timestamps[i].split(':');
        totalMinutes += parseInt(minutes, 10);
        totalSeconds += parseInt(seconds, 10);
      } else {
        const [hours, minutes, seconds] = timestamps[i].split(':');
        totalHours += parseInt(hours, 10);
        totalMinutes += parseInt(minutes, 10);
        totalSeconds += parseInt(seconds, 10);
      }
    }
  }
  
  // Handle any excess seconds and minutes
  totalMinutes += Math.floor(totalSeconds / 60);
  totalSeconds %= 60;

  totalHours += Math.floor(totalMinutes / 60);
  totalMinutes %= 60;

  // Format the result as a string in the 'h:mm:ss' format
  const result = `${totalHours}:${totalMinutes.toString().padStart(2, '0')}:${totalSeconds.toString().padStart(2, '0')}`;

  return result;
}

function extractFileIdFromUrl(url) {
  if (url.slice(0, 31) === "https://drive.google.com/file/d"){
    const regex = /\/d\/([^/]+)\/view\?/;
    const match = url.match(regex);
    
    if (match && match[1]) {
        const fileId = match[1];
        const newUrl = `https://drive.google.com/uc?export=download&id=${fileId}`;
        return newUrl;
    } else {
        return null; // You can customize this part based on your requirements
    }
  } else {
    return url;
  }
}