import React from "react";


type GCArray1to22 = Array<number>;

export class GroupedControlCanvas extends React.Component <{filledValues: GCArray1to22, height: number}, {}> {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  ctx: CanvasRenderingContext2D;
  checkArray: Array<Array<number>>;
  constructor(props){
    super(props);
    this.canvasRef = React.createRef();
    this.checkArray = [
      [0, 1, 2, 3, 4, 5, 0],
      [0, 6, 7, 8, 9, 10, 0],
      [0, 11, 12, 13, 14, 15, 0],
      [16, 17, 18, 19, 20, 21, 22]
    ]
  }

  componentDidMount() {
    const canvas = this.canvasRef.current!;
    this.ctx = canvas.getContext('2d')!;
    this.renderFrame();
  }

  renderFrame = () => {
    // Clear the canvas
    this.ctx.clearRect(0, 0, this.props.height * 1.75, this.props.height);

    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 7; j++) {
        // i = x, j = y
        if (this.props.filledValues.includes(this.checkArray[i][j])) { this.ctx.fillStyle = "#000000"; this.ctx.strokeStyle = "#ffffff"; }
        else { this.ctx.fillStyle = "#ffffff"; this.ctx.strokeStyle = "#000000"; }

        let part = this.props.height / 4;
        this.ctx.fillRect(j * part, i * part, part, part);
        this.ctx.strokeRect(j * part, i * part, part, part);
      }
    }
  }

  render(){
    return (
      <canvas
        id='nextBox'
        ref={this.canvasRef}
        width={this.props.height * 1.75}
        height={this.props.height}
        style={{ border: '1px solid black', borderRadius: '3px' }}
      />
    )
  }
}