import React from "react";
import { withRouter } from "./RouterHelperFunction.js";
import { IndividualConcert } from "./Home";
import { Concerts } from "../firebase";


class SingleConcertDisplay extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      concert: "",
      concertData: {},
      retrieved: false
    }
  }

  async componentDidMount() {
    const { concert } = this.props.params;
    let concertValue = concert.split('_').join(' ');
    this.setState({concert: concertValue});

    let grabbedConcert = await Concerts.getExactConcert(concertValue);
    this.setState({concertData: grabbedConcert, retrieved: true});
  }

  render() {
    return (
      <div id='singleConcertPage'>
        <h1>Concert: {this.state.concert}</h1>
        {this.state.retrieved && this.state.concertData !== undefined ? (
          <IndividualConcert concert={this.state.concertData} queue={this.props.adjustQueue} talking={true}/>
        ) : (
          <p>No concert was found.</p>
        )}
      </div>
    )
  }
}

export default withRouter(SingleConcertDisplay);