import React from "react";
import { useGLTF } from "@react-three/drei";
import { Material, TextureLoader, MeshStandardMaterial } from "three";

export function StageWrapper() {
  const { nodes, materials } = useGLTF("/stage/Final_Stage.glb");
  return (<Stage nodes={nodes} materials={materials} />);
}

export function Cloud1Wrapper(height) {
  const { nodes, materials } = useGLTF("/stage/Cloud 1.glb");
  return (<Cloud1 nodes={nodes} materials={materials} height={height} />);
}
export function Cloud2Wrapper(height) {
  const { nodes, materials } = useGLTF("/stage/Cloud 2.glb");
  return (<Cloud2 nodes={nodes} materials={materials} height={height} />);
}
export function Cloud3Wrapper(height) {
  const { nodes, materials } = useGLTF("/stage/Cloud 3.glb");
  return (<Cloud3 nodes={nodes} materials={materials} height={height} />);
}
export function Cloud4Wrapper(height) {
  const { nodes, materials } = useGLTF("/stage/Cloud 4.glb");
  return (<Cloud4 nodes={nodes} materials={materials} height={height} />);
}

class Stage extends React.Component {
  render() {
    const { nodes, materials } = this.props;
    let loader = new TextureLoader();
    let frontTexture = loader.load('/stage/TestFront.png');
    const frontMaterial = new MeshStandardMaterial({ map: frontTexture });
    let wallTexture = loader.load('/stage/TestWall.png');
    const wallMaterial = new MeshStandardMaterial({ map: wallTexture });
    let floorTexture = loader.load('/stage/TestWoodFloor.png');
    const floorMaterial = new MeshStandardMaterial({ map: floorTexture });
    floorMaterial.roughness = 0.7;
    return (
      <group dispose={null}>
        <group position={[0, 0, 0]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube_1.geometry}
            material={materials['Walls']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube_2.geometry}
            material={floorMaterial}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube_3.geometry}
            material={wallMaterial}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube_4.geometry}
            material={frontMaterial}
          />
        </group>
      </group>
    );
  }
}

class Cloud1 extends React.Component {
  render() {
    const { nodes, materials } = this.props;
    let loader = new TextureLoader();
    let wallTexture = loader.load('/stage/TestWall.png');
    const wallMaterial = new MeshStandardMaterial({ map: wallTexture });
    return (
      <group dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001.geometry}
          material={wallMaterial}
          position={[2.032, this.props.height, 0]}
          rotation={[0, 0, Math.PI / 6]}
        />
      </group>
    )
  }
}

class Cloud2 extends React.Component {
  render() {
    const { nodes, materials } = this.props;
    let loader = new TextureLoader();
    let wallTexture = loader.load('/stage/TestWall.png');
    const wallMaterial = new MeshStandardMaterial({ map: wallTexture });
    return (
      <group dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube002.geometry}
          material={wallMaterial}
          position={[2.032, this.props.height, 0]}
          rotation={[0, 0, Math.PI / 6]}
        />
      </group>
    )
  }
}

class Cloud3 extends React.Component {
  render() {
    const { nodes, materials } = this.props;
    let loader = new TextureLoader();
    let wallTexture = loader.load('/stage/TestWall.png');
    const wallMaterial = new MeshStandardMaterial({ map: wallTexture });
    return (
      <group dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube003.geometry}
          material={wallMaterial}
          position={[2.032, this.props.height, 0]}
          rotation={[0, 0, Math.PI / 6]}
        />
      </group>
    )
  }
}

class Cloud4 extends React.Component {
  render() {
    const { nodes, materials } = this.props;
    let loader = new TextureLoader();
    let wallTexture = loader.load('/stage/TestWall.png');
    const wallMaterial = new MeshStandardMaterial({ map: wallTexture });
    return (
      <group dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube004.geometry}
          material={wallMaterial}
          position={[2.032, this.props.height, 0]}
          rotation={[0, 0, Math.PI / 6]}
        />
      </group>
    )
  }
}
