import React from 'react';
import { AdminChecks } from '../firebase';
import { AppContext } from './_Context';
import { Helmet } from 'react-helmet';

export class UserList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      users: []
    }
  }
  static contextType = AppContext;

  async componentDidMount() {
    AdminChecks.getAllUsers()
    .then(userList => this.setState({ users: userList }))
    .catch(error => console.error('Error fetching users:', error.message));

  }

  render() {
    let userList = [];
    for (let i = 0; i < this.state.users.length; i++){
      userList.push(
        <div className='userRow' key={this.state.users[i].email}>
          <p>Email: {this.state.users[i].email}</p>
          <p>Display Name: {this.state.users[i].name}</p>
          <p>Student: <input type='checkbox' readOnly checked={this.state.users[i].isStudent}/></p>
          <p>SL: {this.state.users[i].sl}</p>
        </div>
      )
    }
    const context = this.context;
    return (
      <div id='userList'>
        <Helmet>
          <title>User List</title>
        </Helmet>
        <h1>User List</h1>
        {context.user.sl >= 9 ? (
            <div id='userIndex'>
              {userList}
            </div>
        ) : (
          <p>You can't be here. </p>
        )}
      </div>
    );
  }
}
