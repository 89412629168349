import React from 'react';
import { Helmet } from 'react-helmet';
import { AppContext } from './_Context';

export class Announcements extends React.Component {
  static contextType = AppContext;
  render() {
    const context = this.context;
    return (
      <div id='announcementPage'>
        <Helmet>
          <title>Announcements</title>
        </Helmet>
        <h1>Announcements</h1>
        <p>Currently under construction.</p>
        <p>Will hope to display text versions of all the important posters around, from auditions to events to times available for tutoring, etc. <br/>
          May also contain times submitted by directors for call time for current concerts, though that feature isn't implemented and may not happen. </p>

        {context.user.sl > 1 && (
          <div id='calendarSection'>
            <h1>Studio Calendars</h1>
            <h2>Eccles Calendar</h2>
            <iframe src='https://calendar.google.com/calendar/u/0/embed?src=psbj0etie4hba30s42vr51ri20@group.calendar.google.com&ctz=America/Denver&fbclid=IwAR2tj_whBc7FuvGsWCl90bKj4UQpVn4ckwwLiw_vjko9CT-vGBRDK9WQn78' title='Studio A' width="100%" height="500px" />
            <h2>Studio A Calendar</h2>
            <iframe src='https://calendar.google.com/calendar/u/0/embed?src=snowcollegemusictech@gmail.com&ctz=America/Denver&fbclid=IwAR3V4H_x7dMG5akswjchogg1P7bC-3NULApWFNvu0yttbkbuoDBszoo1Z9c&pli=1' title='Studio A' width="100%" height="500px" />
            <h2>Studio B Calendar</h2>
            <iframe src='https://calendar.google.com/calendar/u/0/embed?src=lncg1uk8npnq197gv934hr7jak@group.calendar.google.com&ctz=America/Denver&fbclid=IwAR2bA195P_7xl4jX3L6YJHEu4YfwSg9xI5fdCJbWVQpXzbfXlfWCGaNbCHI' title='Studio A' width="100%" height="500px" />
          </div>
        )}
      </div>
    );
  }
}
