import React from 'react';
import Select from 'react-select';
import { tagOptions } from './_Search Options';
import { Concerts, Init } from '../firebase';
import { IndividualConcert } from './Home';

export class Search extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentTag: "",
      selection: "tags",
      searchOptions: [],
      data: []
    }
  }

  async componentDidMount() {
    const sO = await Init.getSearchOptions();
    this.setState({ searchOptions: sO });
  }

  handleTagSelect = (value) => {
    this.setState({currentTag: value.value});
  }

  handleSelection = (selection) => {
    this.setState({selection: selection.target.value});
  }

  search = async () => {
    let concerts;
    concerts = await Concerts.searchConcerts(this.state.currentTag);
    this.setState({data: concerts});
  }

  render() {
    let options = [];
    if (this.state.searchOptions){
      let key = Object.keys(this.state.searchOptions);
      key.forEach(key => {
        if (key !== "update"){
          options.push(<option key={key} value={key}>{key}</option>)
        }
      });
    }
    let concerts = [];
    for (let i = 0; i < this.state.data.length; i++){
      concerts.push(
        <IndividualConcert concert={this.state.data[i]} queue={this.props.adjustQueue} queryString={this.props.queryString} />
      )
    }
    return (
      <div id='searchPage'>
        <h1>Search</h1>
        <p>This page has been broken and needs rework. Come back later!</p>
        {/* <p>This page will allow you to search via a variety of parameters, but for now it will just allow you to search by Tags. You can only 
          do one tag at a time, but it will return every concert that has that tag listed. For example, "Spotlight" or "Faculty".</p>
          Search by: <select onChange={this.handleSelection}>
            {options}
          </select>
        <Select placeholder="Tag Select" options={tagOptions} onChange={this.handleTagSelect}/>
        <button onDoubleClick={this.search}>Search (double-click)</button>
        <div id='concertArea'>
          {concerts}
        </div> */}
      </div>
    );
  }
}
