import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, getDocs, setDoc, addDoc, updateDoc, deleteDoc, query, where, orderBy, limit } from "firebase/firestore";
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, signOut } from "firebase/auth";
import { getStorage, ref, listAll } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBu6rhRVddgMrmBkMyaA-d0fZTxxewMl5k",
  authDomain: "snowcollegeconcerts.firebaseapp.com",
  projectId: "snowcollegeconcerts",
  storageBucket: "snowcollegeconcerts.appspot.com",
  messagingSenderId: "461655817604",
  appId: "1:461655817604:web:3adf4ab1dee9ab8fa471d1",
  measurementId: "G-Y9RQFZ7JYZ"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const storageRef = ref(storage);
const root = storageRef.root;

// Some initialization functions
class GetSemesterInfo {
  static async getPossibleSemesters() {
    const docRef = doc(db, "initialize", "semesters");
    const docSnap = await getDoc(docRef);

    let arrayExport = docSnap.data().array;
    return arrayExport;
  }

  static async getAllSemesterInformation() {
    const docRef = doc(db, "initialize", "semesters");
    const docSnap = await getDoc(docRef);

    let arrayExport = docSnap.data();
    return arrayExport;
  }

  static async writeAllSemesterInfo(info) {
    await updateDoc(doc(db, 'initialize', "semesters"), {
      array: info.array,
      concertCount: info.concertCount,
      duration: info.duration,
      trackCount: info.trackCount
    });
  }
}

class Init {
  static async getProductionInfo() {
    const docRef = doc(db, "initialize", "production");
    const docSnap = await getDoc(docRef);

    let valueExport = docSnap.data();
    return valueExport;
  }

  static async writeProductionInfo(string) {
    await updateDoc(doc(db, 'initialize', "production"), {
      timestamp: string
    });
  }

  static async getMessages() {
    const docRef = doc(db, "initialize", "announcements");
    const docSnap = await getDoc(docRef);

    let valueExport = docSnap.data();
    return valueExport;
  }

  static async setAnnouncements(obj) {
    await updateDoc(doc(db, 'initialize', "announcements"), {
      directors: obj.directors,
      home: obj.home
    });
  }

  static async getRecentConcerts() {
    const docRef = doc(db, "initialize", "recentConcerts");
    const docSnap = await getDoc(docRef);

    let valueExport = docSnap.data();
    return valueExport;
  }

  static async setRecentConcerts(obj) {
    await updateDoc(doc(db, 'initialize', "recentConcerts"), {
      timestamp: obj.timestamp, 
      fiveArray: obj.fiveArray,
      fifteenArray: obj.fifteenArray,
      thirtyArray: obj.thirtyArray
    });
  }

  static async getSearchOptions() {
    const docRef = doc(db, "initialize", "searchOptions");
    const docSnap = await getDoc(docRef);

    let valueExport = docSnap.data();
    return valueExport;
  }
}

class AuthService {
  static sendSignInLinkToEmail(email) {
    const actionCodeSettings = {
      // URL you want to redirect back to
      url: 'https://concerts.codydhowell.com/account/finishsignin',
      handleCodeInApp: true,
    };

    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // Save the email locally to complete the sign-in process
        window.localStorage.setItem('emailForSignIn', email);
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
      });
  }

  static completeSignIn() {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User needs to provide email again
        email = window.prompt('Please provide your email for confirmation');
      }

      signInWithEmailLink(auth, email, window.location.href)
        .then(async (result) => {
          // Signed in successfully
          window.localStorage.removeItem('emailForSignIn');

          let email = result.user.email;
          let uid = result.user.uid;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.data() !== undefined) {
            console.log('User document already exists:', docSnap.data());
          } else {
            console.log("Document doesn't exist");
            // The document does not exist, create a new one
            let sl = 1;
            let isStudent = false;
            if (email.endsWith("students.snow.edu")) {
              sl = 2;
              isStudent = true;
            }
            let obj = {};
            obj["email"] = email;
            obj["sl"] = sl;
            obj["isStudent"] = isStudent;
            obj["awaitingSignOut"] = false;
            await setDoc(doc(db, "users", uid), obj);
          }
        })
        .catch((error) => {
          console.error('Error checking user document:', error);
        });
    }
  }

  static async getUserInformation(uid) {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  static getAuthInformation() {
    return auth.currentUser;
  }

  static signOutUser() {
    signOut(auth);
    window.location.reload();
  }
}

class StorageService {
  static async listItems(){
    console.log(root);
    listAll(root)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
        });
        res.items.forEach((itemRef) => {
          console.log(itemRef);
          // All the items under listRef.
        });
      }).catch((error) => {
        // Uh-oh, an error occurred!
      });
    // let value = storage.listAll();
    // console.log(value);
    // return value;
  }
}

class CalendarEvents {
  static async getCalendarEvents() {
    const querySnapshot = await getDocs(collection(db, "calendar"));
    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      let obj = doc.data();
      obj["key"] = doc.id;
      arrayExport.push(obj);
    });
    return arrayExport;
  }

  static async getTodayCalendarEvents() {
    const timeZoneOffset = -7 * 60; // -7 hours offset for GMT-7
    const now = new Date(Date.now() + timeZoneOffset * 60000); // Convert minutes to milliseconds
    const today = now.toISOString().slice(0, 10);  // Current date
    let arrayExport = [];

    const concertsCollectionRef = collection(db, 'calendar');
    const orderedQuery = query(concertsCollectionRef, where("start", ">=", today), where("start", "<=", today + "T23:59"));
    const querySnapshot = await getDocs(orderedQuery);

    querySnapshot.forEach((doc) => {
      arrayExport.push(doc.data());
    });
    return arrayExport;
  }

  static async removeCalendarEvent(key) {
    await deleteDoc(doc(db, "calendar", key));
  }

  static async addDirectEvent(event) {
    await addDoc(collection(db, "calendar"), event);
  }
}

class LessonRequests {
  // Get lessons
  static async getLessonEvents() {
    const querySnapshot = await getDocs(collection(db, "lessons"), orderBy('dateCreated', 'desc'));
    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      arrayExport.push(doc.data());
    });
    return arrayExport;
  }
}

class PipelineRequests {
  // (Home) Get In Progress Items
  static async getInProgressItems() {
    const querySnapshot = await getDocs(collection(db, "adminImport"), where("type", "==", "inProgressItem"), orderBy("string", "desc"));
    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      let obj = doc.data();
      obj["key"] = doc.id;
      if (obj.type === "inProgressItem"){
        arrayExport.push(obj);
      }
    });
    return arrayExport;
  }
}

class AdminRequests {
  static async updateAndRemoveCalendarEvent(calendarEvent, keyToRemove) {
    await AdminRequests.removeAdminCheck(keyToRemove);
    await addDoc(collection(db, "calendar"), calendarEvent);
  }

  static async updateAndRemoveLesson(lesson, keyToRemove) {
    await AdminRequests.removeAdminCheck(keyToRemove);
    await addDoc(collection(db, "lessons"), lesson);
  }

  static async updateAndRemoveUR(userRequest, keyToRemove) {
    try {
      const userCollectionRef = collection(db, "users");
      const q = query(
        userCollectionRef,
        where("email", "==", userRequest.email)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userDocRef = querySnapshot.docs[0].ref;
        await updateDoc(userDocRef, {
          sl: userRequest.sl, // Set the new sl value
        });
        await AdminRequests.removeAdminCheck(keyToRemove);
        console.log("Document updated successfully.");
      } else {
        console.error("No document found for the provided email.");
      }
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }

  static async updateAndRemoveUNR(userRequest, keyToRemove){
    await updateDoc(doc(db, "users", userRequest.uid), {
      name: userRequest.name
    });
    await AdminRequests.removeAdminCheck(keyToRemove);
  }

  static async removeAdminCheck(key) {
    await deleteDoc(doc(db, "adminImport", key));
  }
}

class AdminChecks {
  static async getAdminChecks() {
    const querySnapshot = await getDocs(collection(db, "adminImport"));
    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      let obj = doc.data();
      obj["key"] = doc.id;
      arrayExport.push(obj);
    });
    return arrayExport;
  }

  static async getDirectorMessages() {
    const querySnapshot = await getDocs(collection(db, "adminImport"), where("type", "==", "directorMessage"));
    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      let obj = doc.data();
      obj["key"] = doc.id;
      arrayExport.push(obj);
    });
    return arrayExport;
  }
  
  static async getAllUsers(){
    let docs = await getDocs(collection(db, "users"));
    let arrayExport = [];
    docs.forEach(doc => {
      arrayExport.push(doc.data());
    });
    return arrayExport;
  }

  // Add admin items
  static async importAdminCheckCalendar(requestObject) {
    await addDoc(collection(db, "adminImport"), {
      name: requestObject.submitter,
      title: requestObject.title,
      start: requestObject.start,
      location: requestObject.location,
      type: requestObject.type
    });
  }

  static async importAdminCheckLesson(lessonRequest) {
    await addDoc(collection(db, "adminImport"), {
      name: lessonRequest.name,
      title: lessonRequest.title,
      dateCreated: lessonRequest.dateCreated,
      introText: lessonRequest.introText,
      youtubeLink: lessonRequest.youtubeLink,
      tag: lessonRequest.tag,
      type: lessonRequest.type
    });
  }

  static async addInProgressItem(progress) {
    await addDoc(collection(db, "adminImport"), {
      string: progress.string,
      type: progress.type
    });
  }

  static async addUserRequest(obj) {
    await addDoc(collection(db, "adminImport"), {
      user: obj.email,
      currentSL: obj.cursl,
      desiredSL: obj.newsl,
      text: obj.text,
      type: "userAccessRequest",
      title: obj.email
    })
  }

  static async addUserNameRequest(obj) {
    await addDoc(collection(db, "adminImport"), {
      email: obj.email,
      name: obj.name,
      uid: obj.uid,
      type: "userNameRequest",
      title: obj.email
    })
  }

  static async addDirectorMessage(obj) {
    await addDoc(collection(db, "adminImport"), {
      body: obj.body,
      name: obj.name,
      header: obj.title,
      type: obj.type
    })
  }

  static async addUserReport(obj) {
    await addDoc(collection(db, "adminImport"), {
      concert: obj.concertString,
      options: obj.options,
      other: obj.otherOption,
      user: obj.user,
      time: obj.time,
      type: "userReport"
    })
  }
}

class Playlists {
  static async updateOrAddPlaylist(playlist){
    if ("key" in playlist){
      await updateDoc(doc(db, 'playlists', playlist.key), {
        uid: playlist.uid,
        title: playlist.title,
        description: playlist.description,
        totalDuration: playlist.totalDuration,
        trackURLs: playlist.trackURLs,
        trackLengths: playlist.trackLengths,
        trackTitles: playlist.trackTitles
      });
    } else {
      await addDoc(collection(db, "playlists"), {
        uid: playlist.uid,
        title: playlist.title,
        description: playlist.description,
        totalDuration: playlist.totalDuration,
        trackURLs: playlist.trackURLs,
        trackLengths: playlist.trackLengths,
        trackTitles: playlist.trackTitles
      })
    }
  }

  static async getUserPlaylists(uid){
    const concertsCollectionRef = collection(db, 'playlists');
    const orderedQuery = query(concertsCollectionRef, where("uid", "==", uid));
    const querySnapshot = await getDocs(orderedQuery);

    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      let object = doc.data();
      object["key"] = doc.id;
      arrayExport.push(object);
    });

    return arrayExport;
  }

  static async getAllPlaylists(){
    let docs = await getDocs(collection(db, "playlists"));
    let arrayExport = [];
    let uids = [];
    docs.forEach(async (doc) => {
      let object = doc.data();
      object["key"] = doc.id;
      arrayExport.push(object);
      if (!(uids.includes(object.uid))){
        uids.push(object.uid)
      }
    });
    for (let i = 0; i < uids.length; i++){
      let name = uids[i].slice(0, 5);
      // Some logic to decide on the screen name of the user
      try {
        const userReturn = await AuthService.getUserInformation(uids[i]);
        if (userReturn.name !== "") {
          name = userReturn.name.slice(0, 5);
        }
      } catch (error) {
        console.error('Error fetching user information:', error.message);
      }
      for (let j = 0; j < arrayExport.length; j++){
        if (arrayExport[j].uid === uids[i]){
          arrayExport[j]["username"] = name;
        }
      }
    }
    return arrayExport;
  }

  static async deletePlaylist(key){
    await deleteDoc(doc(db, "playlists", key));
  }
}

class Concerts {
  static async getExactConcerts(semester) {
    const concertsCollectionRef = collection(db, 'concerts');
    const orderedQuery = query(concertsCollectionRef, where("semesterSelected", "==", semester), where("toPublic", "==", true), orderBy('concertDateTime', 'desc'));
    const querySnapshot = await getDocs(orderedQuery);

    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      arrayExport.push(doc.data());
    });

    return arrayExport;
  }

  static async getExactConcert(key) {
    const docRef = doc(db, "concerts", key);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  static async getNewestConcert() {
    const concertsCollectionRef = collection(db, 'concerts');
    const orderedQuery = query(concertsCollectionRef, where("toPublic", "==", true), orderBy('concertDateTime', 'desc'), limit(1));
    const querySnapshot = await getDocs(orderedQuery);

    let objExport = {};
    querySnapshot.forEach((doc) => {
      objExport = doc.data();
    });
    return objExport;
  }

  static async getUncheckedConcerts() {
    const concertsCollectionRef = collection(db, 'concerts');
    const orderedQuery = query(concertsCollectionRef, where("toPublic", "==", false));
    const querySnapshot = await getDocs(orderedQuery);

    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      arrayExport.push(doc.data());
    });
    // console.log(arrayExport);
    return arrayExport;
  }

  static async getTechCheckConcerts() {
    const concertsCollectionRef = collection(db, 'concerts');
    const orderedQuery = query(concertsCollectionRef, where("techCheck", "==", false), orderBy('concertDateTime', 'desc'));
    const querySnapshot = await getDocs(orderedQuery);
    
    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      arrayExport.push(doc.data());
    });

    return arrayExport;
  }

  static async getProgramlessConcerts() {
    const concertsCollectionRef = collection(db, 'concerts');
    const orderedQuery = query(concertsCollectionRef, where("programLink", "==", ""), orderBy('concertDateTime', 'desc'));
    const querySnapshot = await getDocs(orderedQuery);

    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      arrayExport.push(doc.data());
    });

    return arrayExport;
  }

  static async getEverySingleConcert() {
    let docs = await getDocs(collection(db, "concerts"));
    let arrayExport = [];
    docs.forEach((doc) => {
      arrayExport.push(doc.data());
    });

    return arrayExport;
  }

  static async getDatabaseConcerts() {
    const concertsCollectionRef = collection(db, 'concerts');
    const orderedQuery = query(concertsCollectionRef, where("programLink", "!=", ""), limit(2));
    const querySnapshot = await getDocs(orderedQuery);

    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      data["key"] = doc.id;
      arrayExport.push(data);
    });

    return arrayExport;
  }

  static async searchConcerts(tag) {
    const concertsCollectionRef = collection(db, 'concerts');
    const orderedQuery = query(concertsCollectionRef, where("tags", "array-contains", tag), orderBy('concertDateTime', 'desc'));
    const querySnapshot = await getDocs(orderedQuery);

    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      arrayExport.push(doc.data());
    });

    return arrayExport;
  }

  static async getRecentConcerts() {
    const concertsCollectionRef = collection(db, 'concerts');
    const newDate = new Date();
    newDate.setDate(newDate.getDate() - 30);
    let checkString = newDate.toISOString();

    const orderedQuery = query(concertsCollectionRef, where("timestampUpload", ">=", checkString), orderBy('timestampUpload', 'desc'));
    const querySnapshot = await getDocs(orderedQuery);

    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      arrayExport.push(doc.data());
    });

    return arrayExport;
  }
  
  static async getOldConcerts() {
    const concertsCollectionRef = collection(db, 'concerts');
    const newDate = new Date();
    newDate.setDate(newDate.getDate() - 40);
    let checkString = newDate.toISOString();
  
    const orderedQuery = query(concertsCollectionRef, where("timestampUpload", "<=", checkString), where("techCheck", "==", false), orderBy('timestampUpload', 'desc'));
    const querySnapshot = await getDocs(orderedQuery);
  
    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      arrayExport.push(doc.data());
    });
  
    return arrayExport;
  }

  static async getByMonthConcerts(month, year) {
    const concertsCollectionRef = collection(db, 'concerts');
    const newDate = new Date();
    newDate.setUTCHours(0, 0, 0, 0); 
    newDate.setMonth(month, 1);
    newDate.setFullYear(year);
    let startDate = newDate.toISOString();

    const date = new Date(newDate.setMonth(month + 1));
    let endDate = date.toISOString();

    console.log(startDate);
    console.log(endDate);

    const orderedQuery = query(concertsCollectionRef, where("concertDateTime", ">=", startDate), where("concertDateTime", "<=", endDate), where("toPublic", "==", true), orderBy('concertDateTime', 'desc'));
    const querySnapshot = await getDocs(orderedQuery);

    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      arrayExport.push(doc.data());
    });

    return arrayExport;
  }

  // Update tech check values
  static async updateTechCheckConcert(concertID, newObjectValues, removeFromCheck) {
    await updateDoc(doc(db, 'concerts', concertID), {
      techsWhoChecked: newObjectValues.techsWhoChecked,
      techsWhoCommented: newObjectValues.techsWhoCommented,
      techCheck: removeFromCheck,
      toPublic: true
    });
  }
  
  static async removeTechCheckConcert(concertID){
    await updateDoc(doc(db, 'concerts', concertID), {
      techCheck: true,
      toPublic: true
    });
  }

  // Update Sherry Concert
  static async updateProgramValue(concertID, value){
    await updateDoc(doc(db, 'concerts', concertID), {
      programLink: value
    });
  }


  // Upload a concert
  static async uploadConcertDocument(concertObject) {
    let fileTitle = concertObject.fileTitle;
    let currentTime = new Date().toISOString();

    await setDoc(doc(db, "concerts", fileTitle), {
      accompanistsArray: concertObject.accompanistsArray,
      backupGoogleLink: concertObject.backupGoogleLink,
      concertDateTime: concertObject.concertDateTime,
      concertDescription: concertObject.concertDescription,
      concertGrouping: concertObject.concertGrouping,
      concertTitle: concertObject.concertTitle,
      databaseLink: concertObject.databaseLink,
      directorsArray: concertObject.directorsArray,
      extraMarketingLink: concertObject.extraMarketingLink,
      featuredGuestsArray: concertObject.featuredGuestsArray,
      featuredSoloistsArray: concertObject.featuredSoloistsArray,
      fileTitle: concertObject.fileTitle,
      groupsArray: concertObject.groupsArray,
      performersArray: concertObject.performersArray,
      programLink: concertObject.programLink,
      semesterSelected: concertObject.semesterSelected,
      tags: concertObject.tags,
      techCheck: concertObject.techCheck,
      techsWhoChecked: concertObject.techsWhoChecked,
      techsWhoCommented: concertObject.techsWhoCommented,
      timestampUpload: currentTime,
      toPublic: concertObject.toPublic,
      totalDuration: concertObject.totalDuration,
      trackLengths: concertObject.trackLengths,
      trackURLs: concertObject.trackURLs,
      trackTalking: concertObject.trackTalking,
      videoLink: concertObject.videoLink
    });
    console.log("SILDebug: Completed upload.");
  }

  // Delete concert
  static async deleteConcert(key) {
    await deleteDoc(doc(db, "concerts", key));
  }


  // Update after upload
  static async updateInitialConcertValues(array) {
    const docRef = doc(db, "initialize", "semesters");
    await updateDoc(docRef, array);
  }

  static async updateConcertDocument(concertObject) {
    let fileTitle = concertObject.fileTitle;

    await updateDoc(doc(db, "concerts", fileTitle), {
      accompanistsArray: concertObject.accompanistsArray,
      backupGoogleLink: concertObject.backupGoogleLink,
      concertDateTime: concertObject.concertDateTime,
      concertDescription: concertObject.concertDescription,
      concertGrouping: concertObject.concertGrouping,
      concertTitle: concertObject.concertTitle,
      databaseLink: concertObject.databaseLink,
      directorsArray: concertObject.directorsArray,
      extraMarketingLink: concertObject.extraMarketingLink,
      featuredGuestsArray: concertObject.featuredGuestsArray,
      featuredSoloistsArray: concertObject.featuredSoloistsArray,
      fileTitle: concertObject.fileTitle,
      groupsArray: concertObject.groupsArray,
      performersArray: concertObject.performersArray,
      programLink: concertObject.programLink,
      semesterSelected: concertObject.semesterSelected,
      tags: concertObject.tags,
      techCheck: concertObject.techCheck,
      toPublic: concertObject.toPublic,
      totalDuration: concertObject.totalDuration,
      trackLengths: concertObject.trackLengths,
      trackURLs: concertObject.trackURLs,
      trackTalking: concertObject.trackTalking,
      videoLink: concertObject.videoLink
    });
    console.log("SILDebug: Concert updated.");
  }
}

class Scores {
  static async getSpecificScores(group){
    let arrayExport = [];
    const concertsCollectionRef = collection(db, 'scores');
    const orderedQuery = query(concertsCollectionRef, where("group", "==", group), orderBy("title", "asc"));
    const querySnapshot = await getDocs(orderedQuery);

    querySnapshot.forEach((doc) => {
      let obj = doc.data();
      obj["key"] = doc.id;
      arrayExport.push(obj);
    });
    return arrayExport;
  }

  static async updateOrAddScore(score) {
    if ("key" in score) {
      await updateDoc(doc(db, 'scores', score.key), score);
      return score.key;
    } else {
      let docRef = await addDoc(collection(db, "scores"), score);
      return docRef.id;
    }
  }

  static async adjustCheckedOut(key, string) {
    await updateDoc(doc(db, 'scores', key), {checkedOut: string});
  }

  static async removeScore(key) {
    await deleteDoc(doc(db, "scores", key));
  }
}

// Dangerous function, not really to be used
// But can be used to reset the key of a concert, if you don't want to type it up again. 
async function adjustConcertName(){
  // const docRef = doc(db, "concerts", "Undesired Name");
  const docRef = doc(db, "concerts", "2024-04-20 Aiden SR");
  const docSnap = await getDoc(docRef);

  let docObject = docSnap.data();
  console.log(docObject);

  // await setDoc(doc(db, "concerts", "Desired Name"), docObject);
  await setDoc(doc(db, "concerts", "2024-04-20 Radium Duo"), docObject);
}

export { GetSemesterInfo, CalendarEvents, LessonRequests, PipelineRequests, AdminChecks, AdminRequests, Concerts, AuthService, Playlists,
  Init, StorageService, Scores, adjustConcertName };