import React from 'react';
import { GetSemesterInfo, Concerts, Playlists } from '../firebase';
import { Helmet } from 'react-helmet';
import playButton from '../Play button.png';
import { AppContext } from './_Context';
import { sumTimestamps } from './Home';


export class PlaylistGeneration extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      concertData: {},
      selectedPlaylist: {},
      semesterOptions: [],
      playlists: [],
      selectedSemester: "",
      playlistIsOpened: false,
      isNewPlaylist: false,
      canMakeMorePlaylists: true
    }
  }
  static contextType = AppContext;


  async componentDidMount(){
    const context = this.context;
    try {
      const semesters = await GetSemesterInfo.getPossibleSemesters();
      const playlists = await Playlists.getUserPlaylists(context.user.uid);
      let data = {};
      semesters.forEach(semester => {
        data[semester] = [];
      });
      this.setState({concertData: data, semesterOptions: semesters, playlists: playlists});
    } catch {
      console.error("Error initializing concert info.");
    }
  }

  handleOnChange = async () => {
    let optionPicked = document.querySelector('#selectedSemester').value;
    this.setState({selectedSemester: optionPicked});
    if (JSON.stringify(this.state.concertData[optionPicked]) === '[]'){
      let arrayImport = await Concerts.getExactConcerts(optionPicked);
      let obj = this.state.concertData;
      obj[optionPicked] = arrayImport;
      this.setState({concertData: obj});
    }
  }
  
  handlePlaylistImport = (object) => {
    if (this.state.playlistIsOpened && this.state.selectedPlaylist.trackURLs.length < 1000){
      let trackURLs = this.state.selectedPlaylist.trackURLs;
      let trackLengths = this.state.selectedPlaylist.trackLengths;
      let trackTitles = this.state.selectedPlaylist.trackTitles;
      trackURLs.push(object.url);
      trackLengths.push(object.length);
      trackTitles.push(object.string);
      let playlist = this.state.selectedPlaylist;
      playlist["trackURLs"] = trackURLs;
      playlist["trackLengths"] = trackLengths;
      playlist["trackTitles"] = trackTitles;
      this.setState({selectedPlaylist: playlist});
    }
  }

  handleNewPlaylist = () => {
    const context = this.context;
    let playlist = {};
    playlist["title"] = "Lorem Title";
    playlist["description"] = "Lorem Description";
    playlist["totalDuration"] = "0:00:00";
    playlist["trackURLs"] = [];
    playlist["trackLengths"] = [];
    playlist["trackTitles"] = [];
    playlist["uid"] = context.user.uid;
    this.setState({selectedPlaylist: playlist, playlistIsOpened: true, isNewPlaylist: true});
  }
  
  handlePlaylistChange = () => {
    let optionPicked = document.querySelector('#selectedPlaylist').value;
    this.setState({playlistIsOpened: true});
    for (let i = 0; i < this.state.playlists.length; i++){
      if (optionPicked === this.state.playlists[i].key){
        this.setState({selectedPlaylist: this.state.playlists[i]});
      }
    }
  }

  handleTitleChange = () => {
    let title = document.querySelector('#playlistTitle').value;
    let playlist = this.state.selectedPlaylist;
    playlist["title"] = title;
    this.setState({selectedPlaylist: playlist});
    console.log(this.state);
  }

  removeTrack = (index) => {
    const { selectedPlaylist } = this.state;

    // Create new arrays with removed elements
    const newTrackURLs = [...selectedPlaylist.trackURLs];
    const newTrackLengths = [...selectedPlaylist.trackLengths];
    const newTrackTitles = [...selectedPlaylist.trackTitles];
  
    newTrackURLs.splice(index, 1);
    newTrackLengths.splice(index, 1);
    newTrackTitles.splice(index, 1);
  
    // Create a new playlist object with the modified arrays
    const newPlaylist = {
      ...selectedPlaylist,
      trackURLs: newTrackURLs,
      trackLengths: newTrackLengths,
      trackTitles: newTrackTitles,
    };
    this.setState({selectedPlaylist: newPlaylist});
  }

  savePlaylist = async () => {
    const context = this.context;
    let playlist = {};
    playlist["uid"] = context.user.uid;
    playlist["title"] = this.state.selectedPlaylist.title;
    playlist["description"] = document.querySelector('#playlistDescription').value;
    playlist["trackURLs"] = this.state.selectedPlaylist.trackURLs;
    playlist["trackLengths"] = this.state.selectedPlaylist.trackLengths;
    playlist["trackTitles"] = this.state.selectedPlaylist.trackTitles;
    let lengths = [];
    for (let i = 0; i < playlist.trackLengths.length; i++){
      lengths.push(playlist.trackLengths[i]);
    }
    playlist["totalDuration"] = sumTimestamps(lengths);

    if ("key" in this.state.selectedPlaylist){
      playlist["key"] = this.state.selectedPlaylist.key;
    } 
    await Playlists.updateOrAddPlaylist(playlist);
    window.location.reload();
  }

  deletePlaylist = async () => {
    if ("key" in this.state.selectedPlaylist){
      await Playlists.deletePlaylist(this.state.selectedPlaylist.key)
    }
    window.location.reload();
  }
  
  render() {
    const context = this.context;
    let possibleSemesterOptions = [];
    if (this.state.semesterOptions.length > 0){
      for (let i = 0; i < this.state.semesterOptions.length; i++){
        possibleSemesterOptions.push(
          <option value={this.state.semesterOptions[i]} key={this.state.semesterOptions[i]}>{this.state.semesterOptions[i]}</option>
        )
      }
    }
    let concertsFromSemester = [];
    if (this.state.selectedSemester !== "" && this.state.concertData[this.state.selectedSemester].length > 0){
      for (let i = 0; i < this.state.concertData[this.state.selectedSemester].length; i++){
        concertsFromSemester.push(
          <ShortenedConcert concert={this.state.concertData[this.state.selectedSemester][i]} handlePlaylistImport={this.handlePlaylistImport} adjustQueue={this.props.adjustQueue} key={this.state.concertData[this.state.selectedSemester][i].fileTitle}/>
        )
      }
    }

    let playlistDisplay;
    if (this.state.playlistIsOpened){
      let playlist = this.state.selectedPlaylist;
      let playlistItems= [];
      for (let i = 0; i < playlist.trackURLs.length; i++){
        let uniqueID = playlist.trackTitles[i] + "" + i;
        playlistItems.push(
          <div className='playlistListItem' key={uniqueID}> 
            <input type='text' name={"trackTitle" + uniqueID}  value={playlist.trackTitles[i]} readOnly/>
            <input type='text' name={"trackURL" + uniqueID}  value={playlist.trackURLs[i]} readOnly/>
            <input type='text' name={"trackLength" + uniqueID}  value={playlist.trackLengths[i]} readOnly/>
            <button onDoubleClick={() => {this.removeTrack(i)}}>Remove Track (double click)</button>
          </div>
        );
      }
      playlistDisplay = (
        <div id='playlistDisplay'>
          <input type='hidden' name='uid' id='uid' defaultValue={playlist.uid}/>
          <div className='centeredInputs'>
            <label htmlFor='playlistTitle'>Playlist Title: </label>
            <input type='text' name='playlistTitle' id='playlistTitle' defaultValue={playlist.title} onBlur={this.handleTitleChange}/> 
          </div>
          <div className='centeredInputs'>
            <label htmlFor='playlistDescription'>Playlist Description: </label>
            <textarea name='playlistDescription' id='playlistDescription' defaultValue={playlist.description}/>
          </div>
          <p>Playlist Length: {playlist.totalDuration} (only updated after saving)</p>
          <p>Playlist Count: {playlist.trackURLs.length}</p>
          {playlistItems}
        </div>
      )
    }

    let playlistOptions = [];
    for (let i = 0; i < this.state.playlists.length; i++){
      playlistOptions.push(
        <option value={this.state.playlists[i].key} key={this.state.playlists[i].key}>{this.state.playlists[i].title}</option>
      )
    }

    return (
      <div id='playlistGenerationPage'>
        <Helmet>
          <title>Playlists</title>
        </Helmet>
        {context.user.sl >= 1 && (
        <>
          <div id='concertDisplayArea'>
            <h1>Playlist Generation</h1>
            <select id='selectedSemester' onChange={this.handleOnChange}>
              <option value="">Pick a semester</option>
              {possibleSemesterOptions}
            </select>
            <p>Semester: {this.state.selectedSemester}</p>
            {concertsFromSemester}
          </div>
          <div id='playlistDisplayArea'>
            <h2>Playlists</h2>
            {!this.state.playlistIsOpened ? (
            <>
              {(playlistOptions.length < (context.user.sl * 3)) && (<button onClick={this.handleNewPlaylist}>Create New Playlist</button>)}
              <select id='selectedPlaylist' onChange={this.handlePlaylistChange}>
                  <option value="">Update a Playlist</option>
                  {playlistOptions}
              </select>
            </>
            ) : (
              <div id='playlistOpenArea'>
                {this.state.isNewPlaylist && (<p>New Playlist</p>)}
                <button onClick={this.savePlaylist}>Save Playlist</button>
                <button onDoubleClick={this.deletePlaylist}>Delete Playlist (double click)</button>
                {playlistDisplay}
              </div>
            )}
          </div>
        </>
        )}
      </div>
    );
  }
}


class ShortenedConcert extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isOpened: false
    }
  }

  toggleOpenedState = () => {
    let toggle = !this.state.isOpened;
    this.setState({
      isOpened: toggle
    })
  }

  render() {
    let concert = this.props.concert;
    let arrayTracks = [];
    for (let i = 0; i < concert.trackURLs.length; i++){
      let className = 'shortenedIndividualTracks';
      if (concert.trackTalking[i]) {className += ' talking';}
      let track = [{ trackNumber: i + 1, url: concert.trackURLs[i], concert: concert.fileTitle }];
      let importObject = { length: concert.trackLengths[i], url: concert.trackURLs[i], string: concert.fileTitle + "_" + (i + 1)}
      arrayTracks.push(
        <div className={className} key={concert.fileTitle + (i+1)}>
          <p className='pButton' onClick={() => this.props.adjustQueue(track, false)}><img src={playButton} alt='Play' className='pButtonImage' name="Play" /></p>
          <p>{i + 1}</p>
          <p>Length: {concert.trackLengths[i]}</p>
          <button onClick={() => {this.props.handlePlaylistImport(importObject)}}>Add to Playlist</button>
        </div>
      )
    }
    return (
      <div className='shortenedConcert'>
        <div className='shortenedConcertInfo' onClick={this.toggleOpenedState}> 
          <p>{concert.fileTitle}</p>
          <p>{concert.trackURLs.length}</p>
          <p>{concert.totalDuration}</p>
        </div>
        {this.state.isOpened && (
          <div className='innerShortenedConcertInfo'>
            {arrayTracks}
          </div>
        )}
      </div>
    )
  }
}