

function formDataToObject(form: FormData): Object{
  let data = {};
  form.forEach((value, key) => {
    data[key] = value;
  });
  return data;
}

/**
 * @param {number} x - Start value
 * @param {number} y - End value
 */
function randNumberBetween(x: number, y: number): Number{
  return Math.floor(Math.random() * (y - x)) + x;
}

/**
 * @param {Array<Object>} data - Array of data
 * @param {string} filter - Value to compare
 * @param {Boolean} ascBool - True: Z-A
 */
function sortData(data, filter, ascBool) : Array<Object>{
  let work = data;
  let output: Array<Object> = [];
  if (ascBool){
    while (work.length > 0){
      let value = work[0][filter];
      let index = 0;
      for (let i = 1; i < work.length; i++) {
        if (value < work[i][filter]) {
          value = work[i][filter];
          index = i;
        }
      }
      if (work[index][filter] !== ""){
        output.push(work[index]);
      }
      work.splice(index, 1);
    }
  } else {
    while (work.length > 0){
      let value = work[0][filter];
      let index = 0;
      for (let i = 1; i < work.length; i++) {
        if (value > work[i][filter]) {
          value = work[i][filter];
          index = i;
        }
      }
      if (work[index][filter] !== ""){
        output.push(work[index]);
      }
      work.splice(index, 1);
    }
  }
  return output;
}

/**
 * @param {Array<Object>} arr - Array of data
 * @abstract Sorts A-Z, then by length
 * @description Primarily used in Instruments. Each object must have a "Label" field.
 */
function sortObjects(arr) : Array<Object>{
    // Sort alphabetically by label
    const sortedAlphabetically = arr.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
    });

    // Concatenate all labels into a single string and sort by length
    const sortedByLength = sortedAlphabetically.sort((a, b) => {
        const lengthA = a.label.length;
        const lengthB = b.label.length;
        return lengthA - lengthB;
    });

    return sortedByLength;
}

export { formDataToObject, randNumberBetween, sortData }