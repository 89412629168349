import React from 'react';
import { Helmet } from 'react-helmet';
import { Init } from '../firebase';

export class ProductionVideos extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      date: "Unknown"
    }
  }

  async componentDidMount(){
    let string = await Init.getProductionInfo();
    this.setState({date: string});
  }

  render() {
    let date = this.state.date.timestamp;
    if (date === "Unknown"){
      date = "sometime, unsure of when yet";
    } else {
      // console.log(new Date(date));
      date = new Date(date).toLocaleDateString();

    }
    return (
      <div id='productionPage'>
        <Helmet>
          <title>Production</title>
        </Helmet>
        <h1>Production</h1>
        <p>Hello! This page hosts YouTube videos made by production students here at Snow College. We hope you enjoy!</p>
        <p>The next production showcase will be {date}. Please check the calendar.</p>
        <p>This page is still under construction. </p>
      </div>
    );
  }
}
