import React from 'react';
import { AppContext } from './_Context';
import { AdminChecks, AdminRequests, CalendarEvents, GetSemesterInfo, Init } from '../firebase';
import { sumTimestamps } from './Home';
import { sortData } from './_Tiny Functions.ts';

export class MinorUpdates extends React.Component {
  constructor(props){
    super(props);
    let currentDateTime = new Date();
    currentDateTime.setHours(currentDateTime.getHours() - 7);
    this.state = {
      currentDateTime: currentDateTime.toISOString().slice(0, 16),
      option: "",
      data: []
    }
  }
  static contextType = AppContext;

  handleOptionChange = async () => {
    let option = document.querySelector("#optionSelect").value;
    if (option === "calendar"){
      let calendarItems = await CalendarEvents.getCalendarEvents();
      this.setState({option: option, data: calendarItems});
    } else if (option === "production") {
      let production = await Init.getProductionInfo();
      if (production.timestamp === "Unknown"){
        this.setState({option: option, data: "Unknown"});
      } else {
        this.setState({option: option, data: new Date(production.timestamp)});
      }
    } else if (option === "semesters") {
      let semesterItems = await GetSemesterInfo.getAllSemesterInformation();
      this.setState({option: option, data: semesterItems});
    } else if (option === "directorMessages") {
      let messages = await AdminChecks.getDirectorMessages();
      this.setState({option: option, data: messages});
    } else if (option === "announcement") {
      const context = this.context;
      this.setState({option: option, data: context.messages});
    } else {
      this.setState({option: "", data: []});
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const form = document.getElementById('calendarRequestForm');
    const formData = new FormData(form);

    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });

    await CalendarEvents.addDirectEvent(data);
    form.reset();
    let events = this.state.data;
    events.push(data);
    this.setState({data: events});
  }

  removeCalendarEvent = async (index, data) => {
    let newArray = [...data];
    let key = newArray[index].key;
    newArray.splice(index, 1);
    await CalendarEvents.removeCalendarEvent(key);
    this.setState({data: newArray});
  }

  handleProductionAdjustment = async (event) => {
    event.preventDefault();
    const form = document.getElementById('productionForm');
    const formData = new FormData(form);

    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });

    if ("isNextKnown" in data){await Init.writeProductionInfo(data["isNextDate"]);} 
    else {await Init.writeProductionInfo("Unknown");}
    window.location.reload();
  }

  handleNewSemester = async () => {
    let semesterName = document.querySelector('#semesterName').value;
    if (semesterName !== ""){
      let data = this.state.data;
      data.array.push(semesterName);
      data.concertCount.push(0);
      data.duration.push("0:00:00");
      data.trackCount.push(0);
      await GetSemesterInfo.writeAllSemesterInfo(data);
      window.location.reload();
    }
  }

  removeDirectorMessage = async (index) => {
    let newArray = [...this.state.data];
    let key = newArray[index].key;
    newArray.splice(index, 1);
    await AdminRequests.removeAdminCheck(key);
    this.setState({data: newArray});
  }

  updateAnnouncements = async () => {
    let obj = {};
    obj["directors"] = document.querySelector('#directors').value;
    obj["home"] = document.querySelector('#home').value;
    await Init.setAnnouncements(obj);
    window.location.reload();
  }

  render() {
    const context = this.context;
    let renderItems = [];
    if (this.state.option === "calendar"){
      renderItems.push(
        <div>
          <h2>Calendar Form</h2>
          <p>Form for immediately creating new calendar events for the public. </p>
          <form id='calendarRequestForm'>
              <label htmlFor='title'>Name of Concert: </label>
              <input type='text' name='title' placeholder='Orchestra Concert' required/>
              <br/>
              <label htmlFor='start'>Date: </label>
              <input type='datetime-local' name='start' defaultValue={this.state.currentDateTime} required/>
              <br/>
              <label htmlFor='classNames'>Location: </label>
              <select name='classNames' required>
                <option value={"Concert Hall"}>Concert Hall</option>
                <option value={"Recital Hall"}>Recital Hall</option>
                <option value={"Black Box"}>Black Box Theatre</option>
                <option value={"Theatre"}>Jorgensen Theatre</option>
                <option value={"Other"}>Other</option>
              </select>
              <br/>

              <button type='submit' onClick={this.handleSubmit}>Submit</button>
            </form>
        </div>
      )
      let data = sortData(this.state.data, "start", false);
      for (let i = 0; i < data.length; i++){
        renderItems.push(
          <div className='calendarOptions' key={data[i].title + "" + data[i].start}>
            <p>{data[i].title}</p>
            <p>{new Date(data[i].start).toLocaleString()}</p>
            <p>{data[i].classNames}</p>
            <button onDoubleClick={() => this.removeCalendarEvent(i, data)}>Remove (double-click)</button>
          </div>
        )
      }
    } else if (this.state.option === "production"){
      renderItems.push(
        <div>
          <h2>Production Form</h2>
          <p>Allows you to adjust the timestamp of the next concert, if there is a next concert. </p>
          <form id='productionForm'>
            <label htmlFor='isNextKnown'>Know Date?: </label>
            <input type='checkbox' name='isNextKnown' id='isNextKnown' defaultChecked={this.state.data === "Unknown" ? false : true} value={true}/>
            <label htmlFor='isNextDate'>Date:  </label>
            <input type='datetime-local' name='isNextDate' id='isNextDate' defaultValue={this.state.data === "Unknown" ? new Date() : this.state.data}/>
            <button onClick={this.handleProductionAdjustment}>Submit</button>
          </form>
        </div>
      )
    } else if (this.state.option === "semesters"){
      let dataObject = this.state.data;
      let semesterArray = [];
      let concertCount = 0;
      let trackCount = 0;
      let totalDuration = [];
      for (let i = 0; i < dataObject.array.length; i++){
        semesterArray.push(
          <div key={i} className='semesterRow'>
            <span>{dataObject.array[i]}</span>
            <span>{dataObject.concertCount[i]}</span>
            <span>{dataObject.duration[i]}</span>
            <span>{dataObject.trackCount[i]}</span>
          </div>
        );
        concertCount += dataObject.concertCount[i];
        trackCount += dataObject.trackCount[i];
        totalDuration.push(dataObject.duration[i]);
      }
      renderItems.push(
        <div>
          <h2>Semester Form</h2>
          <p>This is a WRITE-ONLY, NO DELETE OPERATION. It's too difficult to worry about logic for deleting semesters (since they could contain now-invisble concerts or break the order of the semesters), so don't create a semester you want to get rid of. 
            This will show you a list of what semesters you have, all their information, and you can push something onto the end. </p>
          <label htmlFor='semesterName'>New Semester Name: </label>
          <input type='text' name='semesterName' id='semesterName' placeholder='Spring/Fall 20XX'/>
          <button onDoubleClick={this.handleNewSemester}>Submit (double-click)</button>
          <br/>
          <br/>
          <p>Current Concert Count: {concertCount}</p>
          <p>Current Total Duration: {sumTimestamps(totalDuration)}</p>
          <p>Current Track Count: {trackCount}</p>
          <div className='semesterRow'>
            <p>Semester Name</p>
            <p>Concert Count</p>
            <p>Duration Sum</p>
            <p>Track Count</p>
          </div>
          {semesterArray}
        </div>
      )
    } else if (this.state.option === "directorMessages"){
      let dataObject = this.state.data;
      let dataArray = [];
      for (let i = 0; i < dataObject.length; i++){
        dataArray.push(
          <div className='directorMessages' key={i}>
            <p>Name: {dataObject[i].name}</p>
            <p>Title: {dataObject[i].header}</p>
            <p>Message: {dataObject[i].body}</p>
            <button onClick={() => {this.removeDirectorMessage(i)}}>Remove</button>
          </div>
        )
      }
      renderItems.push(
        <div key="directorMessage">
          <h2>Director Messages</h2>
          {dataArray}
        </div>
      )
    } else if (this.state.option === "announcement"){
      let style = {"width": "80%", "height": "50px"}
      renderItems.push(
        <div key="announcements">
          <h2>Director's Announcement</h2>
          <textarea name='directors' id='directors' defaultValue={this.state.data.directors} style={style} /><br/>
          <h2>Home Page Announcement</h2>
          <textarea name='home' id='home' defaultValue={this.state.data.home} style={style} /><br/>
          <button onClick={this.updateAnnouncements}>Submit</button>
        </div>
      )
    }

    // FINDING RETURN LINE OF GREEN CODE
    return (
      <div id='adminMinorUpdates'>
        <h1>Minor Updates</h1>
        {context.user.sl >= 9 ? (
          <>
            <p>This page will host most of the minor updates that go on. Such as: </p>
            <ul>
              <li>Removing or adding a single calendar event (it's very quick and cheap to do that instead of adjusting some)</li>
              <li>Adjusting the Production date</li>
              <li>Adding a new semester</li>
            </ul>
            <p>Just select what you're going to do in the option select, it'll grab the info, and away you go. </p>
            <select name='optionSelect' id='optionSelect' onChange={this.handleOptionChange}>
              <option value="">Select an option</option>
              <option value="calendar">Adjust Calendar Events</option>
              <option value="production">Adjust Production Date</option>
              <option value="announcement">Adjust Announcements</option>
              <option value="semesters">Adjust Semester Info</option>
              <option value="directorMessages">Read/Remove Director Messages</option>
            </select>
            <div id='renderedObjects'>
              {renderItems}
            </div>
          </>
        ) : (
          <p>You don't have access for this page. </p>
        )}
      </div>
    );
  }
}

