import React from 'react';
import { AppContext } from './_Context';
import { Link } from 'react-router-dom';
import { Playlists } from '../firebase';
import playButton from "../Media Play Button.png";
import queueButton from "../Add to Queue.png";
import { Helmet } from 'react-helmet';
import downloadButton from "../Download Symbol.png";

export class Playlist extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      playlists: []
    }
  }
  static contextType = AppContext;

  async componentDidMount(){
    Playlists.getAllPlaylists()
    .then(playlistList => this.setState({ playlists: playlistList }))
    .catch(error => console.error('Error fetching semester information:', error.message));
  }

  render() {
    const context = this.context;
    let playlists = this.state.playlists;
    let playlistDisplay = [];
    if (playlists.length > 0){
      for (let i = 0; i < playlists.length; i++){
        playlistDisplay.push(
          <IndividualPlaylist playlist={playlists[i]} key={playlists[i].key} queue={this.props.adjustQueue}/>
        )
      }
    }
    return (
      <div id='playlistPage'>
        <Helmet>
          <title>Playlists</title>
        </Helmet>
        <h1>Playlists</h1>
        {context.user.sl >= 1 && (
            <div id='fullButtons'>
              <Link to="/playlist/generation"><div><p>Playlist Making</p></div></Link>
            </div>
          )}
        <p>This page will hold playlists already created (some maintained by automation/admin), and if you have signed in, you can get access to make your own. There's a cap of 1,000 tracks.  </p>
        {playlistDisplay}
      </div>
    );
  }
}

class IndividualPlaylist extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isOpened: false
    }
  }

  openInside = () => {
    let other = !this.state.isOpened;
    this.setState({isOpened: other});
  }

  render() {
    let playlist = this.props.playlist;
    let tracks = [];
    let outerTracks = [];
    for (let i = 0; i < playlist.trackURLs.length; i++){
      let uniqueID = playlist.trackTitles[i] + "" + i;
      const splitArray = playlist.trackTitles[i].split('_');
      let innerTrack = { trackNumber: splitArray[1], url: playlist.trackURLs[i], concert: splitArray[0] };
      tracks.push(
        <div className='individualTracks' key={uniqueID}>
          <p className='pButton' onClick={() => this.props.queue([innerTrack], false)}><img src={playButton} alt='Play' className='pButtonImage' name="Play" /></p>
          <p className='pButton' onClick={() => this.props.queue([innerTrack])}><img src={queueButton} alt='Add to Queue' className='pButtonImage' /></p>
          <p>{playlist.trackTitles[i]}</p>
          <p>{playlist.trackLengths[i]}</p>
          <a href={playlist.trackURLs[i]}><img src={downloadButton} alt='downloadButton'/></a>
        </div>
      )
      outerTracks.push(innerTrack);
    }
    return (
      <div className='individualPlaylist'>
        <div className='outerPlaylistOption' onClick={this.openInside}>
          <p className='title'>{playlist.title}</p>
          <p>{playlist.totalDuration}</p>
          <p>Tracks: {playlist.trackURLs.length}</p>
        </div>
        {this.state.isOpened && (
          <div className='innerPlaylistOption'>
            <p>Created by: {playlist.username}</p>
            <p className=''>Options for the full playlist: Play, Add to Queue, and (later) Shuffle in!</p>
            <div className='buttonArea'>
              <p className='pButton' onClick={() => this.props.queue(outerTracks, false)}><img src={playButton} alt='Play' className='pButtonImage' name="Play" /></p>
              <p className='pButton' onClick={() => this.props.queue(outerTracks)}><img src={queueButton} alt='Add to Queue' className='pButtonImage' /></p>
            </div>
            <p>{playlist.description}</p>
            <div className='trackList'>
              {tracks}
            </div>

          </div>
        )}
      </div>
    )
  }
}