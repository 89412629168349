import React from 'react';
import { Helmet } from 'react-helmet';

export class Articles extends React.Component {
  render() {
    return (
      <div id='articlesPage'>
        <Helmet>
          <title>Articles</title>
        </Helmet>
        <h1>Articles</h1>
        <p>Currently under construction. Check back later!</p>
      </div>
    );
  }
}
