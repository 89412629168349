import React from "react";
import mediaPlayButton from "../Media Play Button.png";
import mediaPauseButton from "../Media Pause Button.png";
import mediaFastForward from "../Media Fast Forward.png";

export class LowerThird extends React.Component {
  // Passes in the array of tracks as a prop, adjusted from on high
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
    this.timeInterval = setInterval(this.updateCurrentTime, 500);
    this.state = {
      isPlaying: true,
      currentTime: 0,
      playString: ""
    }
  }

  updateCurrentTime = () => {
    const audio = this.audioRef.current;

    if (audio) {
      audio.addEventListener('loadstart', this.handleLoadStart);
      audio.addEventListener('error', this.handleError);
      audio.addEventListener('loadeddata', this.handleLoaded);
      this.setState({
        currentTime: this.audioRef.current.currentTime,
      });
    }
  };

  componentWillUnmount() {
    clearInterval(this.timeInterval);
    const audio = this.audioRef.current;
    console.log(audio);
    
    audio.removeEventListener('loadstart', this.handleLoadStart);
    audio.removeEventListener('error', this.handleError);
    audio.removeEventListener('loadeddata', this.handleLoaded);
  }

  handleLoadStart = () => {
    this.setState({ playString: "Loading" });
  }
  handleLoaded = () => {
    this.setState({ playString: "" });
  }
  handleError = () => {
    this.setState({ playString: "Error loading. Go to Google Drive." });
  }

  handleAudioEnded = () => {
    this.props.removeTrackFromState();
    this.setState({ currentTime: 0 });
  };

  togglePlay = () => {
    if (this.audioRef.current.src === "" && this.props.tracks.length > 0) {
      let string = String(this.props.tracks[0].url).replace(/&/g, '%26');
      this.audioRef.current.src = `https://retrieveaudio-wdrq7uehjq-uc.a.run.app?fileUrl=${string}`;
      if (this.state.isPlaying) {
        this.audioRef.current.pause();
      } else {
        this.audioRef.current.play();
      }
      this.setState({ isPlaying: !this.state.isPlaying });
    } else if (this.audioRef.current.src !== "") {
      if (this.state.isPlaying) {
        this.audioRef.current.pause();
      } else {
        this.audioRef.current.play();
      }
      this.setState({ isPlaying: !this.state.isPlaying });
    }
  };

  handleScrub = (e) => {
    const newTime = e.target.value;
    this.audioRef.current.currentTime = newTime;
    this.setState({ currentTime: newTime });
  };

  convertSecondsToHMS = (seconds) => { // Written by ChatGPT, of course
    if (typeof seconds !== 'number' || isNaN(seconds)) {
      return "X:XX:XX";
    }

    // Calculate hours, minutes, and remaining seconds
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.round(seconds % 60);

    // Format the result as "h:mm:ss"
    const formattedTime = `${hours}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;

    return formattedTime;
  }

  render() {
    const tracks = this.props.tracks;

    let max = 0;
    if (this.audioRef.current && !isNaN(this.audioRef.current.duration)) {
      max = this.audioRef.current.duration;
    }
    let string;
    if (this.props.tracks.length > 0) {
      string = String(this.props.tracks[0].url).replace(/&/g, '%26');
      string = `https://retrieveaudio-wdrq7uehjq-uc.a.run.app?fileUrl=${string}`;
    }

    return (<>
      {tracks.length > 0 ? (
        <div id='lowerThird'>
          <div id='musicPlayer'>
            <audio ref={this.audioRef} src={string} onEnded={this.handleAudioEnded} autoPlay />
            {this.audioRef.current && (
              <input
                id='audioPlayerSlider'
                type="range"
                min={0}
                max={max}
                value={this.state.currentTime}
                onChange={this.handleScrub}
              />
            )}
            <span id='concertTitle'>{this.state.playString === "" ? (tracks[0].concert) : (this.state.playString)}</span>
            {this.audioRef.current && (
              <span id='timecode'>{this.convertSecondsToHMS(this.state.currentTime)}</span>
            )}
            <span id='trackNumber'>Track: {tracks[0].trackNumber}</span>
            <span id='queueCount'>In Queue: {tracks.length}</span>
            <div id='buttonSection'>
              {this.state.isPlaying === false ? (<img src={mediaPlayButton} alt='mediaPlayButton' onClick={this.togglePlay} />) : (<img src={mediaPauseButton} alt='mediaPauseButton' onClick={this.togglePlay} />)}
              <img src={mediaFastForward} alt='mediaSkipButton' onClick={this.handleAudioEnded} />
            </div>
          </div>
        </div>
      ) : (
        <div id='lowerThird'>
          <p>No tracks in the queue.</p>
        </div>
      )}
    </>)
      ;
  }
}