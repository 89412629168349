import React from 'react';
import { GetSemesterInfo, Concerts, Init, adjustConcertName } from '../firebase';
import { sumTimestamps } from './Home';

export class AdminLargeButtons extends React.Component {

  handleConcertInitializationRecalculation = async () => {
    let concerts = await Concerts.getEverySingleConcert();
    let semesterInfo = await GetSemesterInfo.getAllSemesterInformation();
    let durations = [];
    let concertCount = [];
    let trackCount = [];
    for (let i = 0; i < semesterInfo.array.length; i++) {
      durations.push("0:00:00")
      concertCount.push(0);
      trackCount.push(0);
    }
    for (let i = 0; i < concerts.length; i++) {
      for (let j = 0; j < semesterInfo.array.length; j++) {
        if (semesterInfo.array[j] === concerts[i]["semesterSelected"]) {
          let addArray = [...concerts[i].trackLengths, durations[j]];
          let finalDurationString = sumTimestamps(addArray);

          durations[j] = finalDurationString;
          trackCount[j] += parseInt(concerts[i].trackURLs.length);
          concertCount[j]++;
          break;
        }
      }
    }
    let obj = {};
    obj["array"] = semesterInfo.array;
    obj["concertCount"] = concertCount;
    obj["duration"] = durations;
    obj["trackCount"] = trackCount;

    await Concerts.updateInitialConcertValues(obj);
    window.location.reload();
  }

  hanldeMostRecentUploads = async () => {
    let obj = await Concerts.getRecentConcerts();

    const now = new Date();
    const thirty = new Date();
    const fifteen = new Date();
    const five = new Date();
    thirty.setDate(now.getDate() - 30);
    fifteen.setDate(now.getDate() - 15);
    five.setDate(now.getDate() - 5);

    let thirtyarray = [];
    let fifteenarray = [];
    let fivearray = [];
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].timestampUpload >= five.toISOString()) {
        fivearray.push(`${obj[i].fileTitle}~${obj[i].timestampUpload}`);
      } else if (obj[i].timestampUpload >= fifteen.toISOString()) {
        fifteenarray.push(`${obj[i].fileTitle}~${obj[i].timestampUpload}`);
      } else {
        thirtyarray.push(`${obj[i].fileTitle}~${obj[i].timestampUpload}`);
      }
    }

    let setObj = {};
    setObj["timestamp"] = now.toISOString();
    setObj["fiveArray"] = fivearray;
    setObj["fifteenArray"] = fifteenarray;
    setObj["thirtyArray"] = thirtyarray;

    await Init.setRecentConcerts(setObj);
    window.location.reload();
  }

  removeOldConcerts = async () => {
    // Time length is 40 days and older
    let concerts = await Concerts.getOldConcerts();
    let adjustedConcerts = [];
    for (let i = 0; i < concerts.length; i++) {
      adjustedConcerts.push(concerts[i]);
    }
    for (let i = 0; i < adjustedConcerts.length; i++) {
      await Concerts.removeTechCheckConcert(adjustedConcerts[i].fileTitle);
    }
    window.location.reload();
  }

  render() {
    return (
      <div id='largeButtonsPage'>
        <h1>Some large buttons here</h1>
        <p>ALL BUTTONS are Double Click. You shouldn't be here anyways if you don't intend on incurring some serious database costs. </p>
        <div id='fullButtons'>
          <div onDoubleClick={this.handleConcertInitializationRecalculation}><p>Recalculate All Concert Initialization</p></div>
          <div onDoubleClick={this.hanldeMostRecentUploads}><p>Update Most Recent Concerts Information</p></div>
          <div onDoubleClick={this.removeOldConcerts}><p>Remove Old Concerts from Tech Check</p></div>
          {/* <div onDoubleClick={adjustConcertName}>Update Aiden's SR</div> BE CAREFUL WHEN UPDATING THIS LINE, check firebase.js at the bottom */}
        </div>
      </div>
    );
  }
}
