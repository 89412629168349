import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Concerts, GetSemesterInfo } from '../firebase';
import { AppContext } from './_Context';
import { Helmet } from 'react-helmet';
// import { Select } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { instruments } from "./_Instruments";
import { IndividualConcert, sumTimestamps } from './Home';

export class ConcertImport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      descriptionOpen: false,
      concert: {
        accompanistsArray: [],
        backupGoogleLink: "",
        concertDateTime: "",
        concertDescription: "",
        concertGrouping: "",
        concertTitle: "",
        databaseLink: "",
        directorsArray: [],
        extraMarketingLink: "",
        featuredGuestsArray: [],
        featuredSoloistsArray: [],
        fileTitle: "",
        groupsArray: [],
        performersArray: [],
        programLink: "",
        semesterSelected: "",
        tags: [],
        techCheck: false,
        techsWhoChecked: [],
        techsWhoCommented: [],
        timestampUpload: "",
        toPublic: false,
        totalDuration: "",
        trackLengths: [],
        trackTalking: [],
        trackURLs: [],
        videoLink: ""
      }
    }
  }
  static contextType = AppContext;

  handleOpenDescription = () => {
    this.setState({ descriptionOpen: !this.state.descriptionOpen })
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    let concert = this.state.concert;

    for (let i = 0; i < concert.trackURLs.length; i++){
      concert.trackURLs[i] = extractFileIdFromUrl(concert.trackURLs[i]);
      // console.log(concert.trackURLs[i]);
    }

    await Concerts.uploadConcertDocument(concert);
    // console.log(concert);
    console.log("SilDEBUG: Concert uploaded.");

    var semesterInfo = await GetSemesterInfo.getAllSemesterInformation(); // Updates the array read off of in Home for 1 document read.
    // console.log(semesterInfo);
    for (let i = 0; i < semesterInfo.array.length; i++){
      if (semesterInfo.array[i] === concert["semesterSelected"]){
        console.log("Adjusting semester info");
        var durationAddition = [semesterInfo.duration[i]];
        durationAddition.push(concert.totalDuration);
        let finalDurationString = sumTimestamps(durationAddition);
        semesterInfo.duration[i] = finalDurationString;
        semesterInfo.trackCount[i] += parseInt(concert.trackURLs.length); 
        semesterInfo.concertCount[i]++;
      }
    }

    await Concerts.updateInitialConcertValues(semesterInfo);
    // console.log(semesterInfo);
    window.location.reload();
  }

  updateConcert = (inConcert) => {
    this.setState({ concert: inConcert });
  }

  render() {
    let style = { "height": "70px", "overflowY": "hidden", "cursor": "pointer" };
    if (this.state.descriptionOpen) { style.height = "auto"; }

    const context = this.context;
    return (
      <div id='concertImportOutside'>
        <Helmet>
          <title>Admin Concert Import</title>
        </Helmet>
        {context.user.sl >= 9 && (
          <>
            <h1>Concert Import</h1>
            <p><Link to="/admin">Back to Admin</Link></p>

            <div id='adminImportInformation' style={style} onClick={this.handleOpenDescription}>
              <p>Some important notes (click to expand/contract). File Title will be cut off from the numbers, so just make sure the timestamp is correct and the filename given makes sense to an average user. It will also become the key, so DON'T MESS IT UP.
                The other thing you can't change is the semester it's in. <br /> The description will show up in it's own unique box. I think I set up set innerHTML for it, so be careful what you put in there :) But you can put in anchors and line breaks and stuff like that. <br />
                Speaking of which, I believe the line break for descriptions are the semicolon (;). Also new to be implemented is the Video Reference, which will take in a timestamp in the description
                and convert it into an &lt;a&gt; element with the timestamp built in to take them to that exact point in the video. The format is |h:mm:ss|, and make sure all fields are filled in.   <br /><br />
                Directors, groups, and featured people are comma (,) separated. In Performers, some students have hyphens in their name, so we will instead use the grave (~) symbol to convert into a hyphen upon display. <br />
                This is a new feature I built after typing in the first hundred by hand. It has a lookup table of all the instruments you might use (though you can create you own), and syncs with the groups tab as well.
                So, here's how it works. <br /> Start by inputting the groups in the Groups input below. Make sure they all have unique, not-bad first-letter identifiers (Snow College Wind Symphony -&gt; SCWS, and I've dealt
                with Central Utah Master Chorale -&gt; CUMC, which I let slide). Then, go into Person, type the name, and enter to make a new person. Tab, enter all the instruments that you can track, tab, enter the shortened group(s)
                they were in, tab, enter (to Submit), and then it should take you back to Person to type the next name. It should be pretty fast. AND, if you see that a person's name is coming up again (or they complete in the
                Person dropdown), just add the information you see, and it will automatically add it to that person, while deleting any duplicate data. I'm really happy with it.<br />
                So! Next thing about Groupings. They should all have titles as such: 20XX-XX Group Title (if they span multiple days), or 20XX-XX-XX if they are all on a single day. Assuming you're uploading them all at once, you can keep track
                of what group name you're giving it; you can go back and fix it if you need to. I might attempt to get all grouping names upon updating the semester you're in. Maybe not worth. <br />
                Here are some important characters you might run into to copy in: &ntilde;
              </p>
            </div>

            <ConcertImportForm concert={this.state.concert} updateConcert={this.updateConcert} />
            <button type="submit" onDoubleClick={this.handleSubmit} style={{ display: 'block' }}>(Double-Click) Submit</button>

            <IndividualConcert concert={this.state.concert} talking={true} />

            <textarea id='JSONdisplay' readOnly value={JSON.stringify(this.state.concert)} />

          </>)}
      </div>
    );
  }
}

class ConcertImportForm extends React.Component {
  constructor(props) {
    super(props);
    const currentDateTime = new Date();
    currentDateTime.setHours(currentDateTime.getHours() - 6);

    this.selectRef1 = React.createRef();
    this.selectRef2 = React.createRef();
    this.selectRef3 = React.createRef(); // Three references for the three Select options. 

    this.state = {
      currentDateTime: currentDateTime.toISOString().slice(0, 16),
      trackNumber: 0,
      possibleSemesters: [],
      descriptionOpen: false,
      performers: [],
      performerNames: [],
      currentPerformer: { name: "", instruments: "", groups: "" },
      groups: [],
      concert: {
        accompanistsArray: [],
        backupGoogleLink: "",
        concertDateTime: currentDateTime.toISOString().slice(0, 16),
        concertDescription: "",
        concertGrouping: "",
        concertTitle: "",
        databaseLink: "",
        directorsArray: [],
        extraMarketingLink: "",
        featuredGuestsArray: [],
        featuredSoloistsArray: [],
        fileTitle: "",
        groupsArray: [],
        performersArray: [],
        programLink: "",
        semesterSelected: "",
        tags: [],
        techCheck: false,
        techsWhoChecked: [],
        techsWhoCommented: [],
        timestampUpload: "",
        toPublic: false,
        totalDuration: "",
        trackLengths: [],
        trackTalking: [],
        trackURLs: [],
        videoLink: ""
      }
    }
  }

  async componentDidMount() {
    try {
      const semesterList = await GetSemesterInfo.getPossibleSemesters();
      this.setState({
        possibleSemesters: semesterList
      });
    } catch (error) {
      console.error('Error fetching semesters:', error.message);
    }
    this.setState({ concert: this.props.concert });
  }

  renderForms = () => {
    const forms = [];

    for (let i = 1; i <= this.state.trackNumber + 1; i++) {
      // Generate a unique identifier for each form element
      const uniqueId = `form-${i}`;
      forms.push(
        <div key={uniqueId} className='trackFormElement'>
          <label htmlFor={uniqueId + "url"}>Database Line {i}:</label>
          <input type="url" id={uniqueId + "url"} name={uniqueId + "url"} onBlur={() => this.updateTrackForms(i - 1, "url")} />
          <label htmlFor={uniqueId + "length"}>Length {i}:</label>
          <input type="text" id={uniqueId + "length"} name={uniqueId + "length"} onBlur={() => this.updateTrackForms(i - 1, "length")} />
          <label htmlFor={uniqueId + "talking"}>Talking {i}:</label>
          <input type="checkbox" id={uniqueId + "talking"} name={uniqueId + "talking"} onClick={() => this.updateTrackForms(i - 1, "talking")} />
        </div>
      );
    }

    return forms;
  }

  updateConcertForm = () => {
    let string = document.querySelector('#JSONimportValue').value;
    string = JSON.parse(string);
    this.setState({ concert: string });
    document.querySelector('#JSONimportValue').value = "";
  }

  handleName = (name) => {
    let obj = this.state.currentPerformer;
    obj["name"] = name.value;
    this.setState({ currentPerformer: obj }); // Update the state with the new selected option
  };

  handleInstruments = (instr) => {
    let obj = this.state.currentPerformer;
    let instruments = [];
    for (let i = 0; i < instr.length; i++) {
      instruments.push(instr[i].value);
    }
    let string = instruments.join("/");
    obj["instruments"] = string;
    this.setState({ currentPerformer: obj }); // Update the state with the new selected option
  };

  handleGroups = (groups) => {
    let obj = this.state.currentPerformer;
    let groupNames = [];
    for (let i = 0; i < groups.length; i++) {
      groupNames.push(groups[i].value);
    }
    let string = groupNames.join("/");
    obj["groups"] = string;
    this.setState({ currentPerformer: obj }); // Update the state with the new selected option
  };

  inputPerformer = () => {
    let concert = this.state.concert;
    let performers = [...this.state.performers];
    let performerName = [...this.state.performerNames];
    if (!(performerName.includes(this.state.currentPerformer.name))) {
      let string = `${this.state.currentPerformer.name} - ${this.state.currentPerformer.instruments} - ${this.state.currentPerformer.groups}`;
      performers.push(string);
      performerName.push(this.state.currentPerformer.name);
      concert.performersArray.push(string);
      this.setState({ performers: performers });
      this.setState({ performerNames: performerName });
      this.setState({ concert: concert });
      this.setState({ currentPerformer: { name: "", instruments: "", groups: "" } });
    } else {
      this.updateName(performers);
    }
    this.props.updateConcert(concert);

    this.selectRef1.current.setValue("");
    this.selectRef2.current.setValue("");
    this.selectRef3.current.setValue("");
    this.selectRef1.current.focus();
  }

  updateName = (performers) => {
    for (let i = 0; i < performers.length; i++) {
      let instrument, name, group;
      [name, instrument, group] = performers[i].split(' - ');
      if (name === this.state.currentPerformer.name) {
        let instruments = instrument.split('/');
        let groups = group.split('/');
        let currentInstruments = this.state.currentPerformer.instruments.split("/");
        let currentGroups = this.state.currentPerformer.groups.split("/");
        for (let j = 0; j < currentInstruments.length; j++) {
          if (!(instruments.includes(currentInstruments[j]))) { instruments.push(currentInstruments[j]); }
        }
        for (let j = 0; j < currentGroups.length; j++) {
          if (!(groups.includes(currentGroups[j]))) { groups.push(currentGroups[j]); }
        }
        let string = `${name} - ${instruments.join("/")} - ${groups.join("/")}`
        performers[i] = string;
        this.setState({ performers: performers });
      }
    }
  }

  updateGroups = () => {
    let string = document.querySelector('#groupsArray').value;
    let array = string.split(", ");
    let groups = [];
    for (let i = 0; i < array.length; i++) {
      let string = array[i];
      let innerArray = string.split(" ");
      let outString = "";
      for (let j = 0; j < innerArray.length; j++) {
        outString += innerArray[j][0];
      }
      let obj = {};
      obj["value"] = outString;
      obj["label"] = array[i];
      obj["isFixed"] = true;
      groups.push(obj);
    }
    let concert = this.state.concert;
    concert["groupsArray"] = array;
    this.setState({ groups: groups });
    this.setState({ concert: concert });
    this.props.updateConcert(concert);
  }

  removePerformer = (i) => {
    let array = [...this.state.performers];
    array.splice(i, 1);
    this.setState({ performers: array });
  }

  updateTags = (option) => {
    let string = option.target.name;
    let concert = this.state.concert;
    if (option.target.checked) {
      if (!concert.tags.includes(string)) {
        concert.tags.push(string);
      }
    } else {
      if (concert.tags.includes(string)) {
        concert.tags.splice(concert.tags.indexOf(string), 1);
      }
    }
    this.setState({ concert: concert });
    this.props.updateConcert(concert);
  }

  updateTextField = (option) => {
    let concert = this.state.concert;
    concert[option.target.name] = option.target.value;
    this.props.updateConcert(concert);
  }

  updateArrays = (option) => {
    let concert = this.state.concert;
    let string = option.target.value;
    let array = string.split(', ');
    concert[option.target.name] = array;
    this.props.updateConcert(concert);
  }

  updateTrackForms = (index, type) => {
    let concert = this.state.concert;
    let value = document.querySelector(`#form-${index + 1}` + type);
    if (type === "talking") { value = value.checked; }
    else { value = value.value; }
    if (value === "") { return; }
    let array;
    switch (type) {
      case "talking":
        array = concert.trackTalking;
        array[index] = value;
        for (let i = array.length - 1; i >= 0; i--) {
          if (array[i] === null || array[i] === '' || array[i] === undefined) {
            array[i] = false;
          }
        }
        concert.trackTalking = array;
        break;
      case "length":
        if (validateTimespan(value)) {
          array = concert.trackLengths;
          array[index] = value;
          concert.trackLengths = array;
        }
        break;
      case "url":
        array = concert.trackURLs;
        array[index] = value;
        concert.trackURLs = array;
        break;
      default:
        console.log("Broken");
        break;
    }
    let newNumber = concert.trackURLs.length;
    concert.totalDuration = sumTimestamps(concert.trackLengths);
    this.setState({ trackNumber: newNumber });
    this.setState({ concert: concert });
    this.props.updateConcert(concert);
  }

  // LONG GREEN LINE ----------------------------------------------------------------------------------
  render() {
    const semesterOptions = [];
    for (let i = this.state.possibleSemesters.length - 1; i >= 0; i--) {
      let semester = this.state.possibleSemesters[i];
      semesterOptions.push(
        <option key={i} value={semester}>{semester}</option>
      )
    }
    let performers = [];
    for (let i = 0; i < this.state.performerNames.length; i++) {
      let obj = { value: this.state.performerNames[i], label: this.state.performerNames[i] };
      performers.push(obj);
    }
    let completedPerformers = [];
    for (let i = 0; i < this.state.concert.performersArray.length; i++) {
      completedPerformers.push(<span key={i + "performer"}>{this.state.concert.performersArray[i]} <button onClick={() => this.removePerformer(i)}>Remove</button><br /></span>);
    }
    return (
      <div id='concertImport'>
        <div id='JSONimport'>
          <p>Import a JSON here!</p>
          <input type='text' id='JSONimportValue' name='JSONimportValue' />
          <button onDoubleClick={this.updateConcertForm}>Update</button>
        </div>

        <label htmlFor='fileTitle'>File Title: </label>
        <input type='text' id='fileTitle' name='fileTitle' placeholder='20XX-XX-XX Concert Title' onBlur={this.updateTextField} defaultValue={this.state.concert.fileTitle}/>

        <div id='tagsCheckboxImport'>
          <h3>Tags:  </h3>
          <form id='tagCheckboxImportForm' onClick={this.updateTags}>
            <div className='individualTags'>
              <label htmlFor='Commercial-Music-Ensemble'>Commercial Music Ensemble</label>
              <input type='checkbox' className='tagCheckboxImport' id='Commercial-Music-Ensemble' name='Commercial Music Ensemble' value="true" checked={this.state.concert.tags.includes("Commercial Music Ensemble")} />
            </div>
            <div className='individualTags'>
              <label htmlFor='Orchestral'>Orchestral</label>
              <input type='checkbox' className='tagCheckboxImport' id='Orchestral' name='Orchestral' value="true" checked={this.state.concert.tags.includes("Orchestral")} />
            </div>
            <div className='individualTags'>
              <label htmlFor='Spotlight'>Spotlight</label>
              <input type='checkbox' className='tagCheckboxImport' id='Spotlight' name='Spotlight' value="true" checked={this.state.concert.tags.includes("Spotlight")} />
            </div>
            <div className='individualTags'>
              <label htmlFor='Jazz'>Jazz</label>
              <input type='checkbox' className='tagCheckboxImport' id='Jazz' name='Jazz' value="true" checked={this.state.concert.tags.includes("Jazz")} />
            </div>
            <div className='individualTags'>
              <label htmlFor='Chamber'>Chamber</label>
              <input type='checkbox' className='tagCheckboxImport' id='Chamber' name='Chamber' value="true" checked={this.state.concert.tags.includes("Chamber")} />
            </div>
            <div className='individualTags'>
              <label htmlFor='Large-Ens'>Large Ens.</label>
              <input type='checkbox' className='tagCheckboxImport' id='Large-Ens' name='Large Ens.' value="true" checked={this.state.concert.tags.includes("Large Ens.")} />
            </div>
            <div className='individualTags'>
              <label htmlFor='Band'>Band</label>
              <input type='checkbox' className='tagCheckboxImport' id='Band' name='Band' value="true" checked={this.state.concert.tags.includes("Band")} />
            </div>
            <div className='individualTags'>
              <label htmlFor='Recital'>Recital</label>
              <input type='checkbox' className='tagCheckboxImport' id='Recital' name='Recital' value="true" checked={this.state.concert.tags.includes("Recital")} />
            </div>
            <div className='individualTags'>
              <label htmlFor='Choral'>Choral</label>
              <input type='checkbox' className='tagCheckboxImport' id='Choral' name='Choral' value="true" checked={this.state.concert.tags.includes("Choral")} />
            </div>
            <div className='individualTags'>
              <label htmlFor='Opera_Musical'>Opera/Musical</label>
              <input type='checkbox' className='tagCheckboxImport' id='Opera_Musical' name='Opera/Musical' value="true" checked={this.state.concert.tags.includes("Opera/Musical")} />
            </div>
            <div className='individualTags'>
              <label htmlFor='Local'>Local</label>
              <input type='checkbox' className='tagCheckboxImport' id='Local' name='Local' value="true" checked={this.state.concert.tags.includes("Local")} />
            </div>
            <div className='individualTags'>
              <label htmlFor='Faculty'>Faculty</label>
              <input type='checkbox' className='tagCheckboxImport' id='Faculty' name='Faculty' value="true" checked={this.state.concert.tags.includes("Faculty")} />
            </div>
            <div className='individualTags'>
              <label htmlFor='Special'>Special</label>
              <input type='checkbox' className='tagCheckboxImport' id='Special' name='Special' value="true" checked={this.state.concert.tags.includes("Special")} />
            </div>
            <div className='individualTags'>
              <label htmlFor='Soloist'>Soloist</label>
              <input type='checkbox' className='tagCheckboxImport' id='Soloist' name='Soloist' value="true" checked={this.state.concert.tags.includes("Soloist")} />
            </div>
            <div className='individualTags'>
              <label htmlFor='Artist'>Artist</label>
              <input type='checkbox' className='tagCheckboxImport' id='Artist' name='Artist' value="true" checked={this.state.concert.tags.includes("Artist")} />
            </div>
          </form>
        </div>


        <h2>Main Concert Info:</h2>
        <div id='mainConcertInfo'>
          <form id='mainConcertInfoForm'>
            <div>
              <label htmlFor='concertTitle'>Concert Title:  </label>
              <input type='text' id='concertTitle' name='concertTitle' placeholder='Specific concert title' onBlur={this.updateTextField} defaultValue={this.state.concert.concertTitle} />
            </div>
            <div>
              <label htmlFor='semesterSelected'>Semester:  </label>
              <select id='semesterSelected' name='semesterSelected' onBlur={this.updateTextField} defaultValue={this.state.concert.semesterSelected}>
                {semesterOptions}
              </select>
            </div>
            <div>
              <label htmlFor='concertDateTime'>Date:  </label>
              <input type='datetime-local' id='concertDateTime' name='concertDateTime' defaultValue={this.state.concert.concertDateTime} onBlur={this.updateTextField} /><br />
            </div>
            <div>
              <label htmlFor='concertDescription'>Description:  </label>
              <textarea id='concertDescription' name='concertDescription' placeholder='Generic description text with (;) linebreaks and |h:mm:ss| timecodes.' onBlur={this.updateTextField} /><br />
            </div>
            <div>
              <label htmlFor='backupGoogleLink'>Google Drive folder backup link:  </label>
              <input type='url' id='backupGoogleLink' name='backupGoogleLink' placeholder='IMPORTANT drive.google.com/d/' onBlur={this.updateTextField} /><br />
            </div>
            <div>
              <label htmlFor='videoLink'>Video Link:  </label>
              <input type='url' id='videoLink' name='videoLink' placeholder='drive.google.com/d/' onBlur={this.updateTextField} /><br />
            </div>
            <div>
              <label htmlFor='programLink'>Program Link:  </label>
              <input type='url' id='programLink' name='programLink' placeholder='drive.google.com/d/' onBlur={this.updateTextField} /><br />
            </div>
            <div>
              <label htmlFor='concertGrouping'>Grouping (for festivals, etc.):  </label>
              <input type='text' id='concertGrouping' name='concertGrouping' placeholder='Specific Group Name' onBlur={this.updateTextField} /><br />
            </div>
            <div>
              <label htmlFor='extraMarketingLink'>Extra Marketing:  </label>
              <input type='url' id='extraMarketingLink' name='extraMarketingLink' placeholder='drive.google.com/d/' onBlur={this.updateTextField} /><br />
            </div>
          </form>
        </div>


        <h2>People:</h2>
        <div id='peopleSection'>
          <div>
            <button onClick={() => console.log(this.state)}>See State</button>
            <div id='selectForms'>
              <CreatableSelect options={performers} onChange={this.handleName} ref={this.selectRef1} className='selectForm' placeholder='Person' />
              <CreatableSelect isMulti options={instruments} onChange={this.handleInstruments} ref={this.selectRef2} className='selectForm' placeholder='Instruments' />
              <CreatableSelect isMulti options={this.state.groups} onChange={this.handleGroups} ref={this.selectRef3} className='selectForm' placeholder='Groups' />
            </div>
            <button onClick={this.inputPerformer}>Submit</button> <br />
            <label htmlFor='performersArray'>Performers:  (count: {this.state.performers.length})</label>
            {this.state.performers.length > 0 && (<div id='completedPerformersArea'>{completedPerformers}</div>)}
          </div>
          <p>Make sure not to leave any open commas after an item (so something like Dr. Brent Smith, ), as that will add more entries than needed.
            This applies to all people inputs. </p>
          <div>
            <label htmlFor='directorsArray'>Directors:  </label>
            <input type='text' id='directorsArray' name='directorsArray' placeholder='Director I, Director II' onBlur={this.updateArrays} /><br />
          </div>
          <div>
            <label htmlFor='groupsArray'>Groups:  </label>
            <input type='text' id='groupsArray' name='groupsArray' placeholder='Group I, Group II' onBlur={this.updateGroups} /><br />
          </div>
          <div>
            <label htmlFor='featuredGuestsArray'>Featured Guests:  </label>
            <input type='text' id='featuredGuestsArray' name='featuredGuestsArray' placeholder='Guest I, Guest II' onBlur={this.updateArrays} /><br />
          </div>
          <div>
            <label htmlFor='featuredSoloistsArray'>Featured Soloists:  </label>
            <input type='text' id='featuredSoloistsArray' name='featuredSoloistsArray' placeholder='Soloist I, Soloist II' onBlur={this.updateArrays} /><br />
          </div>
          <div>
            <label htmlFor='accompanistsArray'>Accompanists:  </label>
            <input type='text' id='accompanistsArray' name='accompanistsArray' placeholder='Accomp. I, Accomp. II' onBlur={this.updateArrays} /><br />
          </div>
        </div>

        <div id='bottomCheckboxes'>
          <div>
            <label htmlFor='toPublic'>Show to public immediately?</label>
            <input type='checkbox' id='toPublic' name='toPublic' /><br />
          </div>
          <div>
            <label htmlFor='techCheck'>Mark as completed by techs?</label>
            <input type='checkbox' id='techCheck' name='techCheck' /><br />
          </div>
        </div>

        <br />

        <div id='formsArray'>
          {this.renderForms()}
        </div>
      </div>
    )
  }
}

function validateTimespan(input) {
  // Regular expression to match MM:SS or H:MM:SS format
  var regex = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/;

  if (regex.test(input)) {
    return true;
  } else {
    return false;
  }
}

function extractFileIdFromUrl(url) {
  const regex = /\/d\/([^/]+)\/view\?/;
  const match = url.match(regex);

  if (match && match[1]) {
    const fileId = match[1];
    const newUrl = `https://drive.google.com/uc?export=download&id=${fileId}`;
    return newUrl;
  } else {
    return null; // You can customize this part based on your requirements
  }
}