import React from "react";
import { concertOptions } from "./_Popup Data";
import { AdminChecks } from "../firebase";
import { AppContext } from "./_Context";
import { formDataToObject } from "./_Tiny Functions.ts";

export class ReportPopup extends React.Component {
    constructor(props) {
        super(props);
        let currentTime = new Date().toISOString();
        this.state = {
            isOther: false,
            currentOption: null, 
            time: currentTime
        }
    }
    static contextType = AppContext;


    handleSubmission = async (event) => {
        event.preventDefault();
        let context = this.context;
        const form = document.getElementById('reportForm');
        const formData = new FormData(form);
    
        let data = formDataToObject(formData);

        if (context.user.email !== ""){data["user"] = context.user.email;}
        else {data["user"] = "Anonymous";}

        data["time"] = this.state.time;
        
        if ("options" in data){
            if (!("otherOption" in data)){data["otherOption"] = "";}
            await AdminChecks.addUserReport(data); 
        }        
        this.props.removeElement();
    }

    updateStateOption = (option) => {
        this.setState({currentOption: option});
    }
    
    
    render() {
        let options = [];
        for (let i = 0; i < concertOptions.length; i++){
            options.push(
                <span key={concertOptions[i].value}>
                    <input type="radio" name="options" id={concertOptions[i].value} value={concertOptions[i].value} onChange={() => {this.updateStateOption(concertOptions[i].value)}}/>
                    <label htmlFor={concertOptions[i].value}>{concertOptions[i].string}</label>
                    <br/>
                </span>
            )
        }
        let concertString = this.props.concertName;
        if (this.props.trackNumber > 0){
            concertString += `, track number ${this.props.trackNumber}`;
        }
        return (
            <div className="reportPopup">
                <h1>Report</h1>
                <p>Here, you can quickly send a report to me about a concert or an individual track. You're reporting <i>{concertString}</i>, what can I do? {this.props.trackNumber === 0 && (<><br/>
                You can also report a specific track. </>)}</p>
                <form id="reportForm">
                    <input type="hidden" name="concertString" id="concertString" value={concertString}/>
                    {options}
                    {this.state.currentOption === "other" && (<textarea name="otherOption" id="otherOption" />)}
                </form>
                <button onClick={this.handleSubmission}>Submit</button>
                <button onClick={this.props.removeElement}>Exit</button>
            </div>
        )
    }
}