import React from 'react';
import { PipelineRequests, Concerts, Init } from '../firebase';
import { Helmet } from 'react-helmet';
import { sumTimestamps } from './Home';
import { RecentConcerts } from './RecentConcerts';

export class Pipeline extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      inProgressItems: [],
      showInProgress: true, 
      uncheckedConcerts: [], 
      semesters: {}
    }
  }

  async componentDidMount(){
    try {
      const inProgressEvents = await PipelineRequests.getInProgressItems();
      const uncheckedConcertEvents = await Concerts.getUncheckedConcerts();
      this.setState({
          inProgressItems: inProgressEvents,
          uncheckedConcerts: uncheckedConcertEvents,
      });
    } catch (error) {
        console.error('Error fetching progress information:', error.message);
    }
    const interval = setInterval(async () => {
      if (JSON.stringify(this.props.initialSemesterInfo) !== "{}") {
        this.setState({ semesters: this.props.initialSemesterInfo });
        clearInterval(interval);
      }
    }, 500);
  }

  toggleInProgress = () => {
    this.setState({showInProgress: !this.state.showInProgress})
  }

  render() {
    let inProgressItemsArray = [];
    if (this.state.inProgressItems.length > 0){
      for (let i = 0; i < this.state.inProgressItems.length; i++){
          inProgressItemsArray.push(
              <div className='inProgressItem' key={this.state.inProgressItems[i].string}>{this.state.inProgressItems[i].string}</div>
          );
        }
    }

    let uncheckedConcertsArray = [];
    let durations2 = [];
    if (this.state.uncheckedConcerts.length > 0){
      for (let i = 0; i < this.state.uncheckedConcerts.length; i++){
        uncheckedConcertsArray.push(
            <div className='uncheckedConcert' key={i}>{this.state.uncheckedConcerts[i].fileTitle}</div>
        );
        durations2.push(this.state.uncheckedConcerts[i].totalDuration);
      }
    }
    let duration = sumTimestamps(durations2);
    return (
      <div id='pipelinePage'>
        <Helmet>
          <title>Pipeline</title>
        </Helmet>
        <h1>Pipeline</h1>
        <p>This page shows you what is currently being edited (called In Progress), and the names of any concerts that are awaiting audit (called Unchecked Concerts). <br/>
          New, there's also a calendar of when the most recent concerts were uploaded, so you can see what is new on the site through this. </p>
        {this.state.inProgressItems.length > 0 && (<div id='inProgressArea' onClick={this.toggleInProgress}><b>In Progress:</b> {this.state.showInProgress && (inProgressItemsArray)}</div>)}
        {this.state.uncheckedConcerts.length > 0 && (<div id='uncheckedArea'><b>Unchecked Concert Titles:  {duration}</b> {uncheckedConcertsArray}</div>)}
        <RecentConcerts concerts={this.state.recentConcerts}/>
      </div>
    );
  }
}

