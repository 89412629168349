import React from "react";
import { Concerts } from "../firebase";
import { AppContext } from './_Context';

export class AuditTotals extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      concerts: []
    }
  }

  async componentDidMount() {
    let checkingConcerts = await Concerts.getTechCheckConcerts();
    this.setState({concerts: checkingConcerts});
  }

  getPeopleCounts = () => {
    let concerts = this.state.concerts;
    let people = {};
    for (let i = 0; i < concerts.length; i++){
      let concert = concerts[i];
      for (let j = 0; j < concert.techsWhoChecked.length; j++){
        let name = concert.techsWhoChecked[j];
        if (people[name]){
          people[name]++;
        } else {
          people[name] = 1;
        }
      }
    }
    return people;
  }


  render() {
    let people = this.getPeopleCounts();
    let keys = Object.keys(people);
    let peopleDisplay = [];
    for (let i = 0; i < keys.length; i++){
      let person = keys[i];
      peopleDisplay.push(<p key={person}>{person}: {people[person]}</p>)
    }

    return (
      <div id='auditTotals'>
        <h1>Audit Totals</h1>
        {peopleDisplay}
      </div>
    )
  }
}