import React from 'react';
import { Scores } from '../firebase';
import { AppContext } from './_Context';
import { Helmet } from 'react-helmet';
import { sortData } from './_Tiny Functions.ts';

export class ScoreLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: "",
      count: 0,
      key: "",
      filter: "title",
      aIsBottom: false,
      scores: [
        { group: "Orchestra", data: [] },
        { group: "Jazz", data: [] },
        { group: "Band", data: [] },
        { group: "Choral", data: [] },
        { group: "Solo & Ensemble", data: [] },
        { group: "Method", data: [] },
        { group: "Percussion", data: [] },
        { group: "Percussion Solos", data: [] }
      ],
      form: {
        title: "",
        composer: "",
        arranger: "",
        instrumentation: "",
        notes: "",
        numOfCopies: "",
        dateAquired: "",
        missingParts: "",
        lastPlayed: "",
        publisher: "",
        boxNumber: "",
        location: "",
        checkedOut: "",
        IMSLPlink: "",
        youTubeLinks: "",
        group: ""
      }
    }
  }
  static contextType = AppContext;

  // Ensure someone on a low sl can't do anything to the database (I think I'm good, but it's late)
  // Code the input box (what does this mean?)

  handleSelectChange = async (value) => {
    let string = value.target.value;
    this.setState({ option: string });
    for (let i = 0; i < this.state.scores.length; i++) {
      if (this.state.scores[i].group === string) {
        if (this.state.scores[i].data.length < 1) {
          let data = await Scores.getSpecificScores(string);
          let array = [...this.state.scores];
          for (let j = 0; j < array.length; j++) {
            if (array[i].group === string) {
              if (data.length < 1) {
                array[i].data = ["No Scores"];
              } else {
                array[i].data = data;
              }
            }
          }
          this.setState({ scores: array })
        }
        break;
      }
    }
  }

  updateFormInfo = (data) => {
    let value = data.target.value;
    let key = data.target.id;
    let obj = this.state.form;
    obj[key] = value;
    this.setState({ form: obj });
  }

  replaceForm = (score) => {
    let form = this.state.form;
    for (let key in form) {
      form[key] = score[key];
    }
    this.setState({ form: form });
    this.setState({ key: score.key });
  }

  uploadForm = async () => {
    let input = this.state.form;
    let form = {};
    for (let key in input) {
      form[key] = input[key];
    }
    // Small function to see if at least 2 options are filled
    let count = 0;
    for (let key in form) {
      if (form[key] !== "") { count++; }
    }
    if (count < 2 || form.title === "") {
      return;
    }

    form["group"] = this.state.option;
    if (this.state.key !== "") { form["key"] = this.state.key; }
    let reference = await Scores.updateOrAddScore(form);
    if ("key" in form) {
      let array = [...this.state.scores];
      for (let i = 0; i < this.state.scores.length; i++) {
        if (this.state.scores[i].group === this.state.option) {
          for (let j = 0; j < array[i].data.length; j++) {
            if (array[i].data[j].key === form.key) {
              array[i].data[j] = form;
              this.setState({ scores: array })
              break;
            }
          }
          break;
        }
      }
    } else {
      form["key"] = reference;
      this.setState({ count: this.state.count + 1 });
      for (let i = 0; i < this.state.scores.length; i++) {
        if (this.state.scores[i].group === this.state.option) {
          let array = [...this.state.scores];
          if (array[i].data[0] === "No Scores") {
            console.error("Inside");
            array[i].data = []; // Wipe array if it was empty
          }
          array[i].data.push(form);
          console.log(array);
          this.setState({ scores: array })
          break;
        }
      }
    }
  }

  removeItem = async () => {
    let userInput = window.prompt('Type anything to delete this item.');
    if (userInput !== "" && userInput !== null) {
      await Scores.removeScore(this.state.key);
      for (let i = 0; i < this.state.scores.length; i++) {
        if (this.state.scores[i].group === this.state.option) {
          let array = [...this.state.scores];
          for (let j = 0; j < array[i].data.length; j++) {
            if (array[i].data[j].title === this.state.form.title &&
              array[i].data[j].composer === this.state.form.composer) {
              array[i].data.splice(j, 1);
            }
          }
          this.setState({ scores: array })
          break;
        }
      }
      this.clearForm();
    } else {
      console.error("Did not delete.");
    }
  }

  clearForm = () => {
    let obj = this.state.form;
    for (const key in obj) {
      obj[key] = "";
    }
    this.setState({ form: obj });
    this.setState({ key: "" });
  }

  adjustCheckedOut = async (score) => {
    if (score.checkedOut === "") {
      await Scores.adjustCheckedOut(score.key, this.context.user.name);
    } else {
      await Scores.adjustCheckedOut(score.key, "");
    }
    for (let i = 0; i < this.state.scores.length; i++) {
      if (this.state.scores[i].group === this.state.option) {
        let array = [...this.state.scores];
        for (let j = 0; j < array[i].data.length; j++) {
          if (score.key === array[i].data[j].key) {
            if (array[i].data[j].checkedOut === "") {
              array[i].data[j].checkedOut = this.context.user.name;
            } else {
              array[i].data[j].checkedOut = "";
            }
          }

        }
        this.setState({ scores: array })
        break;
      }
    }
    this.clearForm();
  }

  handleCSVInput = async (boolean) => {
    // Meant to be hand-crafted every time it's used, not meant generally.
    let value = document.getElementById('csvinput').value;
    let outputArray = [];
    let lines = value.split('\n');
    for (let i = 0; i < lines.length; i++) {
      let values = lines[i].split(',');
      for (let i = 0; i < values.length; i++) {
        let value = values[i];
        while (value.includes('*')) {
          value = value.replace('*', ",");
        }
        value = value.trim();
        values[i] = value;
      }

      let obj = {};
      obj["title"] = values[0];
      obj["composer"] = values[1];
      obj["arranger"] = values[2];
      obj["instrumentation"] = "";
      obj["notes"] = values[5];
      obj["numOfCopies"] = "";
      obj["dateAquired"] = "";
      obj["missingParts"] = values[3];
      obj["lastPlayed"] = values[4];
      obj["publisher"] = "";
      obj["boxNumber"] = "";
      obj["location"] = "";
      obj["checkedOut"] = "";
      obj["IMSLPlink"] = "";
      obj["youTubeLinks"] = "";
      obj["group"] = this.state.option;
      outputArray.push(obj);
    }
    if (boolean) {
      let promises = [];
      for (let obj of outputArray) {
        if (obj.title !== "" && obj.group !== "") {
          // console.log(obj);
          promises.push(Scores.updateOrAddScore(obj));
        }
      }
      await Promise.all(promises);
      window.location.reload();
    } else {
      console.log(outputArray);
    }
  }

  updateFilterValues = (filter, ascBool) => {
    this.setState({ filter: filter, aIsBottom: ascBool });
  }

  render() {
    const context = this.context;
    let options = [];
    let data = [];
    let formData = this.state.form;
    for (let i = 0; i < this.state.scores.length; i++) {
      let collection = JSON.parse(JSON.stringify(this.state.scores[i])); // I dislike writing things like this.
      options.push(
        <option value={collection.group} key={collection.group}>{collection.group}</option>
      );
      if (collection.group === this.state.option && collection.data.length > 0 && collection.data[0] !== "No Scores") {
        let sortedData = sortData(collection.data, this.state.filter, this.state.aIsBottom);
        for (let j = 0; j < sortedData.length; j++) {
          data.push(<IndividualScore data={sortedData[j]} index={j} update={this.replaceForm} filter={formData} checkOut={this.adjustCheckedOut} />);
        }
      }
    }
    return (
      <div id='scoreLibraryPage'>
        <Helmet>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/PapaParse/5.3.0/papaparse.min.js"></script>
        </Helmet>
        <h1>Score Library</h1>
        <div id='introText'>
          <p>Welcome to the Snow College Score Library! Select a group from the dropdown, and it'll pull up all the scores we
            have for that group. You can browse all of them and checkout any that you intend to use in the next semester, so we
            generally have an idea of where they are and who has what.<br /> <b>Double-click the button to check out a score</b>; it'll take the name from
            your Account (request that first) and put your name in. Only you can check-in a score when you're done (or make the library
            faculty do it themselves). If you run into issues, contact Cody. </p>
          <p>Use the form to check for specific scores. It will filter out any that don't match your criterion exactly. Start by selecting a group. </p>
          <select onChange={this.handleSelectChange}>
            <option value="">No Group Selected</option>
            {options}
          </select>
          {/* This form is to be updated with the function it calls to upload many scores at once. Only uncomment when needed. */}
          {/* <div>
            <textarea name='csvinput' id='csvinput' style={{"width": "95%"}}/>
            <button onClick={() => this.handleCSVInput(false)}>Log Scores</button>
            <button onClick={() => this.handleCSVInput(true)}>Upload Scores</button>
          </div> */}
          {this.context.user.sl >= 8 && (<p>You can also add new things in and update existing items! The form below will allow you to add items in (just make sure
            the correct group is selected in the option select), and it'll make a little tally of how many you've added in this session. The title is required.
            <br />To update or remove an item, double click on it, and it'll fill into the form above to be filled out again. So you know, the YouTube link field relies on
            having a comma and space after to get the values in-between. Also, Checked Out only checks the name of the person that checked it out, and if it doesn't match,
            they can't check it back in. You can empty it if you need to, though. <b>Total added/adjusted this session: {this.state.count}</b></p>)}
          <form id='inputForm'>
            <label htmlFor='title'>Title<span style={{ "color": "#750901" }}>*</span>: </label>
            <input type='text' name='title' id='title' value={formData.title} onChange={this.updateFormInfo} placeholder='What a Difference a Day Made' />
            <label htmlFor='composer'>Composer: </label>
            <input type='text' name='composer' id='composer' value={formData.composer} onChange={this.updateFormInfo} placeholder='Adams, Stanley & Grever, Maria' />
            <label htmlFor='arranger'>Arranger: </label>
            <input type='text' name='arranger' id='arranger' value={formData.arranger} onChange={this.updateFormInfo} placeholder='Richins, Levi' />
            <label htmlFor='instrumentation'>Instrumentation: </label>
            <input type='text' name='instrumentation' id='instrumentation' value={formData.instrumentation} onChange={this.updateFormInfo} placeholder='Violin, Viola, Cello, Bass' />
            <label htmlFor='notes'>Notes: </label>
            <input type='text' name='notes' id='notes' value={formData.notes} onChange={this.updateFormInfo} placeholder='Very old and torn. ' />
            <label htmlFor='missingParts'>Missing Parts: </label>
            <input type='text' name='missingParts' id='missingParts' value={formData.missingParts} onChange={this.updateFormInfo} placeholder='Violin I, Timpani' />
            <label htmlFor='dateAquired'>Date Aquired: </label>
            <input type='date' name='dateAquired' id='dateAquired' value={formData.dateAquired} onChange={this.updateFormInfo} />
            <label htmlFor='lastPlayed'>Last Played: </label>
            <input type='date' name='lastPlayed' id='lastPlayed' value={formData.lastPlayed} onChange={this.updateFormInfo} />
            <label htmlFor='numOfCopies'># of Copies: </label>
            <input type='number' name='numOfCopies' id='numOfCopies' value={formData.numOfCopies} onChange={this.updateFormInfo} placeholder='5' />
            <label htmlFor='publisher'>Publisher: </label>
            <input type='text' name='publisher' id='publisher' value={formData.publisher} onChange={this.updateFormInfo} placeholder='Steve Weiss' />
            <label htmlFor='boxNumber'>Box Number: </label>
            <input type='number' name='boxNumber' id='boxNumber' value={formData.boxNumber} onChange={this.updateFormInfo} placeholder='156' />
            <label htmlFor='location'>Location: </label>
            <input type='text' name='location' id='location' value={formData.location} onChange={this.updateFormInfo} placeholder='Left 05' />
            <label htmlFor='IMSLPlink'>IMSLP Link: </label>
            <input type='text' name='IMSLPlink' id='IMSLPlink' value={formData.IMSLPlink} onChange={this.updateFormInfo} placeholder='https://imslp.org/wiki/...' />
            <label htmlFor='youTubeLinks'>YouTube Links: </label>
            <input type='text' name='youTubeLinks' id='youTubeLinks' value={formData.youTubeLinks} onChange={this.updateFormInfo} placeholder='https://www.youtube.com/watch?v=..., https://www.youtube.com/watch?v=...' />
            <label htmlFor='checkedOut'>Checked Out: </label>
            <input type='text' name='checkedOut' id='checkedOut' value={formData.checkedOut} onChange={this.updateFormInfo} placeholder='Dr. Fullmer' />
          </form>
          <IndividualScore data={formData} index={-1} update={() => console.log(this.state)} filter={formData} checkOut={this.adjustCheckedOut} />
          <button onClick={this.clearForm}>Reset Form</button>
          {this.state.option !== "" && context.user.sl > 7 && (<button onDoubleClick={this.uploadForm}>Upload/Update Entry (Double Click)</button>)}
          {this.state.key !== "" && context.user.sl > 7 && (<button onDoubleClick={this.removeItem} style={{ "backgroundColor": "#ee2222" }}>Remove Item (Double Click)</button>)}
          {/* <button onClick={() => {console.log(this.state)}}>See State</button> */}
        </div>
        <div id='filterSelection'>
          <FilterSelection formSorting={this.state.form} updateFilterState={this.updateFilterValues} />
        </div>
        <div id='scoreCollection'>
          <p>Count: {data.length}</p>
          {data}
        </div>
      </div>
    );
  }
}

class IndividualScore extends React.Component {
  static contextType = AppContext;

  render() {
    let data = this.props.data;
    if (checkFilter(data, this.props.filter)) {
      let instrumentation = [];
      let instruments = data.instrumentation.split(', ');
      for (let i = 0; i < instruments.length; i++) {
        if (i !== instruments.length - 1) {
          instrumentation.push(<span key={i + " instrument"}>{instruments[i]}, </span>)
        } else { // Second case to get rid of a final comma
          instrumentation.push(<span key={i + " instrument"}>{instruments[i]}</span>)
        }
      }
      let youtubeLinks = [];
      let links = data.youTubeLinks.split(', ');
      for (let i = 0; i < links.length; i++) {
        youtubeLinks.push(<span key={i + "youTubeLinks"}><a href={links[i]} target='_blank' rel='noreferrer'>Link{i + 1}</a>  </span>);
      }

      return (
        <div className='individualScore' key={"Score " + this.props.index} onDoubleClick={() => this.props.update(data)}>
          {data.boxNumber !== "" && (<p>Box #: {data.boxNumber}</p>)}
          {data.location !== "" && (<p>Location: {data.location}</p>)}
          {data.title !== "" && (<p>Title: {data.title}</p>)}
          {data.composer !== "" && (<p>Composer: {data.composer}</p>)}
          {data.arranger !== "" && (<p>Arranger: {data.arranger}</p>)}
          {data.instrumentation !== "" && (<p>Instrumentation: {instrumentation}</p>)}
          {data.missingParts !== "" && (<p>Missing Parts: {data.missingParts}</p>)}
          {data.numOfCopies !== "" && (<p>Num. of Copies: {data.numOfCopies}</p>)}
          {data.dateAquired !== "" && (<p>Date Aquired: {new Date(data.dateAquired).toDateString()}</p>)}
          {data.lastPlayed !== "" && (<p>Last Played: {new Date(data.lastPlayed).toDateString()}</p>)}
          {data.checkedOut !== "" && (<p>Checked Out: {data.checkedOut}</p>)}
          {data.publisher !== "" && (<p>Publisher: {data.publisher}</p>)}
          {data.notes !== "" && (<p>Notes: {data.notes}</p>)}
          {data.IMSLPlink !== "" && (<p>IMSLP Link: <a href={data.IMSLPlink} target='_blank' rel='noreferrer'>Link</a></p>)}
          {data.youTubeLinks !== "" && (<p>YouTube Links: {youtubeLinks}</p>)}
          {data.checkedOut === "" && this.props.index >= 0 && this.context.user.name !== "" && this.context.user.sl >= 5 && (<button onDoubleClick={() => this.props.checkOut(data)}>Check Out</button>)}
          {data.checkedOut === this.context.user.name && data.checkedOut !== "" && this.props.index >= 0 && this.context.user.sl >= 5 && (<button onDoubleClick={() => this.props.checkOut(data)}>Check In</button>)}
        </div>
      );
    }
  }
}

class FilterSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilter: "title",
      aIsBottom: false,
      showFilters: false
    }
  }

  handleFilterClick = (filter) => {
    let newFilter = this.state.selectedFilter;
    let newDirection = false;
    let currentDirection = this.state.aIsBottom;
    if (newFilter === filter) {
      newDirection = !currentDirection;
    } else {
      newFilter = filter;
    }
    this.setState({ selectedFilter: newFilter, aIsBottom: newDirection });

    this.props.updateFilterState(newFilter, newDirection);
  }

  handleShowFeatures = () => {
    this.setState({ showFilters: !this.state.showFilters });
  }

  render() {
    let keys = [];
    for (let key in this.props.formSorting) {
      if (key === "group") { continue; }
      keys.push(key);
    }
    let displayKeys = [];
    for (let obj of keys) {
      let arrow;
      let className = "filterKey";
      if (obj === this.state.selectedFilter) {
        className += " isSelected";
        if (this.state.aIsBottom) {
          arrow = `\u2191`;
        } else {
          arrow = `\u2193`;
        }
      }
      let displayValue = obj[0].toUpperCase() + obj.slice(1, obj.length);
      displayKeys.push(<span key={obj} className={className} onClick={() => this.handleFilterClick(obj)}>{displayValue} {arrow}</span>);
    }
    return (
      <>
        <p onClick={this.handleShowFeatures}>Sort By</p>
        {this.state.showFilters && (
          <div id='filterKeyContainer'>
            {displayKeys}
          </div>
        )}
      </>
    );
  }
}

function checkFilter(original, filter) {
  for (let key in original) {
    if (key !== "key" && filter[key] !== "") {
      if (!(original[key].includes(filter[key]))) {
        return false;
      }
    }
  }
  return true;
}
