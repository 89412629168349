import React from 'react';
import { LessonRequests, AdminChecks } from '../firebase';
import { Helmet } from 'react-helmet';
import { AppContext } from './_Context';

export class Lessons extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      lessons: []
    }
  }
  static contextType = AppContext;

  async componentDidMount(){
    try {
      const lessonEvents = await LessonRequests.getLessonEvents();
      this.setState({
          lessons: lessonEvents
      });
    } catch (error) {
        console.error('Error fetching lesson information:', error.message);
    }
  }

  submitForm = async (event) => {
    event.preventDefault();
    let formObject = {};
    formObject["name"] = document.querySelector('#requestName').value;
    formObject["title"] = document.querySelector('#requestTitle').value;
    formObject["youtubeLink"] = document.querySelector('#requestURL').value;
    formObject["introText"] = document.querySelector('#requestDescription').value;
    formObject["dateCreated"] = new Date().toISOString();
    formObject["tag"] = document.querySelector('#tag').value;
    formObject["type"] = "lessonRequestForm";
    await AdminChecks.importAdminCheckLesson(formObject);

    window.location.reload();
  }

  render() {
    let lessons = [];
    if (this.state.lessons.length > 0){
      for (let i = 0; i < this.state.lessons.length; i++){
        let uniqueID = this.state.lessons[i].title;
        const inputLink = this.state.lessons[i].youtubeURL;
        const regex = /youtu\.be\/([^?]+)/;
        const videoId = inputLink.match(regex)[1];
        const embedLink = `https://www.youtube.com/embed/${videoId}`;
        const timestamp = new Date(this.state.lessons[i].dateCreated).toLocaleDateString('en-US', {year: 'numeric',month: 'long',day: 'numeric'});
        lessons.push(
          <IndividualLesson key={uniqueID} lesson={this.state.lessons[i]} timestamp={timestamp} embedLink={embedLink}/>
        )
      }
    }
    const context = this.context;
    return (
      <div id='lessonPage'>
        <Helmet>
          <title>Lessons</title>
        </Helmet>
        <h1>Lessons</h1>
        {lessons}

        {context.user.sl >= 1 && (
        <div id='requestForm'>
          <h2>Request Form</h2>
          <p>Please put your name, the title you want to be shown, the video link you're using, and a short description of what it's for, something like "This video shows the proper
            technique to play [specific section of piece or movement]." <br/> The link should be in the format, https://youtu.be/dQw4w9WgXcQ?si=zvC-P0KS7JO805iW (a youtu.be link, just ask Youtube to "share"). <br/>
            Finally, the tag (new). There is General (for anyone), then some theory, then a massive list of all of the classic instruments. Pick what best applies. (and you can message me if you want a new tag listed).
          </p>
          <form id='lessonRequestForm'>
            <label htmlFor='name'>Your Name: </label>
            <input type='text' name='name' id='requestName' />

            <br/> 
            <label htmlFor='title'>Lesson Title: </label>
            <input type='text' name='title' id='requestTitle' />

            <br/> 
            <label htmlFor='youtubeLink'>YouTube URL: </label>
            <input type='url' name='youtubeLink' id='requestURL' />
            
            <br/> 
            <label htmlFor='description'>Description: </label>
            <textarea name='description' id='requestDescription' />

            <br/>
            <label htmlFor='tag'>Tag: </label>
            <select name='tag' id='tag'>
              <option value="General" className='bolded'>General</option>
              <option value="Music Theory">Music Theory</option>
              <option value="Web Theory">Web Theory</option>
              <option value="Woodwinds" className='bolded'>Woodwinds</option>
              <option value="Flute">Flute</option>
              <option value="Clarinet">Clarinet</option>
              <option value="Oboe">Oboe</option>
              <option value="Bassoon">Bassoon</option>
              <option value="Saxophone">Saxophone</option>
              <option value="Brass" className='bolded'>Brass</option>
              <option value="Trumpet">Trumpet</option>
              <option value="Horn">Horn</option>
              <option value="Trombone">Trombone</option>
              <option value="Euphonium">Euphonium</option>
              <option value="Tuba">Tuba</option>
              <option value="Percussion" className='bolded'>Percussion</option>
              <option value="Drumset">Drumset</option>
              <option value="Piano">Piano</option>
              <option value="Mallets">Mallets</option>
              <option value="Xylophone">Xylophone</option>
              <option value="Marimba">Marimba</option>
              <option value="Snare Drum">Snare Drum</option>
              <option value="Cymbals">Cymbals</option>
              <option value="Timpani">Timpani</option>
              <option value="Hand Percussion">Hand Percussion</option>
              <option value="Marching Percussion">Marching Percussion</option>
              <option value="Strings" className='bolded'>Strings</option>
              <option value="Guitar">Guitar</option>
              <option value="Bass">Bass</option>
              <option value="Violin">Violin</option>
              <option value="Viola">Viola</option>
              <option value="Cello">Cello</option>
              <option value="String Bass">String Bass</option>
              <option value="Vocalist" className='bolded'>Vocalist</option>
              <option value="Lead Vocal">Lead Vocal</option>
              <option value="Soprano">Soprano</option>
              <option value="Alto">Alto</option>
              <option value="Tenor">Tenor</option>
              <option value="Bass">Bass</option>
              <option value="Tech" className='bolded'>Tech</option>
              <option value="Audio">Audio</option>
              <option value="Video">Video</option>
              <option value="Lights">Lights</option>
            </select>

            <button type='submit' onClick={this.submitForm}>Submit</button> 
          </form>
        </div>

        )}

      </div>
    );
  }
}

class IndividualLesson extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      opened: false
    }
  }

  toggleOpen = () => {
    this.setState({opened: !this.state.opened});
  }

  render() {
    return (
      <div key={this.props.uniqueID} className='lessons' onClick={this.toggleOpen}>
        <div className='outerRow' >
            <h2>{this.props.lesson.title}</h2>
            <p className='date'>Date: {this.props.timestamp}</p>
            <p className='tag'>Tag: {this.props.lesson.tag}</p>
            <p className='introText'>{this.props.lesson.introText}</p>
            <p className='personWhoSubmitted'>By: {this.props.lesson.name}</p>
        </div>
        {this.state.opened && (
          <div className='innerRow'>
              <iframe className='youtubeVideo' width="560" height="315" src={this.props.embedLink} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              <iframe className='mobileYouTubeVideo' width="200" height="130" src={this.props.embedLink} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        )}
      </div>
    );
  }
}