import React from 'react';
import { Init } from '../firebase';

export class RecentConcerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      recentConcerts: {}
    };
  }

  async componentDidMount() {
    const recentConcerts = await Init.getRecentConcerts();
    this.setState({ recentConcerts: recentConcerts });
  }

  handleOpen = () => {
    this.setState({isOpen: !this.state.isOpen});
  }

  render() {
    let concerts = this.state.recentConcerts;
    let recentUpdatedTimestamp = new Date(concerts.timestamp);
    let renderItems = [];

    if (concerts.fiveArray){
      renderItems.push(<h2 key="fiveArrayTag">Within 5 Days</h2>);
      for (let i = 0; i < concerts.fiveArray.length; i++){
        let title, time;
        [title, time] = concerts.fiveArray[i].split('~');
        renderItems.push(
          <p key={`five${i}`}>Concert: {title}. <span className='separator'>Uploaded at: {new Date(time).toLocaleString()}</span></p>
        )
      }
    }
    if (concerts.fifteenArray){
      renderItems.push(<h2 key="fifteenArrayTag">Within 15 Days</h2>);
      for (let i = 0; i < concerts.fifteenArray.length; i++){
        let title, time;
        [title, time] = concerts.fifteenArray[i].split('~');
        renderItems.push(
          <p key={`fifteen${i}`}>Concert: {title}. <span className='separator'>Uploaded at: {new Date(time).toLocaleString()}</span></p>
        )
      }
    }
    if (concerts.thirtyArray){
      renderItems.push(<h2 key="thirtyArrayTag">Within 30 Days</h2>);
      for (let i = 0; i < concerts.thirtyArray.length; i++){
        let title, time;
        [title, time] = concerts.thirtyArray[i].split('~');
        renderItems.push(
          <p key={`thirty${i}`}>Concert: {title}. <span className='separator'>Uploaded at: {new Date(time).toLocaleString()}</span></p>
        )
      }
    }

    return (
      <div id='recentConcerts'>
        <p><b>These groups are EXCLUSIVE, so concerts uploaded 6 days ago only go into the "Within 15 days" category. </b></p>
        <div id='outerInfo' onClick={this.handleOpen}>
          <p>Most recently updated: {recentUpdatedTimestamp.toLocaleString("en-US", {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
          })}</p> 
          {concerts.fiveArray && concerts.fiveArray.length > 0 && (
            <p>|  Within five days: {concerts.fiveArray.length}</p>
          )}
          {concerts.fifteenArray && concerts.fifteenArray.length > 0 && (
            <p>|  Within fifteen days: {concerts.fifteenArray.length} (total: {concerts.fiveArray.length + concerts.fifteenArray.length})</p>
          )}
          {concerts.thirtyArray && concerts.thirtyArray.length > 0 && (
            <p>|  Within thirty days: {concerts.thirtyArray.length} (total: {concerts.fiveArray.length + concerts.fifteenArray.length + concerts.thirtyArray.length})</p>
          )}
        </div>
        {this.state.isOpen && (<div id='innerInfo'>
          {renderItems}
        </div>)}
      </div>
    );
  }
}
