import React from "react";
import { IndividualConcert } from "./Home";
import { Concerts } from "../firebase";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';

type HomeCalendarViewProps = {
  talking: boolean,
  adjustQueue: Function
}

type HomeCalendarViewState = {
  concerts: Array<ConcertType>,
  strings: Array<{ month: number, year: number }>,
  month: number, 
  year: number,
  currentConcert: ConcertType | null
}

export class HomeCalendarView extends React.Component<HomeCalendarViewProps, HomeCalendarViewState> {
  constructor(props) {
    super(props);
    this.state = {
      concerts: [],
      strings: [],
      month: 0, 
      year: 2000,
      currentConcert: null
    }
  }

  async componentDidMount() {
    let currentDate = new Date();
    // let currentConcerts = await Concerts.getByMonthConcerts(currentDate.getMonth(), currentDate.getFullYear()) as Array<ConcertType>;
    // // let currentConcerts = [];

    // let concerts = this.state.concerts;
    // for (let i = 0; i < currentConcerts.length; i++) {
    //   concerts.push(currentConcerts[i]);
    // }

    // this.addNewString(currentDate.getMonth(), currentDate.getFullYear());
    console.log(currentDate);

    this.setState({ month: currentDate.getMonth(), year: currentDate.getFullYear() });
  }

  updateMonth = async () => {
    let monthSelector = document.querySelector('#monthSelector') as HTMLSelectElement;
    let month = parseInt(monthSelector.value);
    let yearSelector = document.querySelector('#yearSelector') as HTMLSelectElement;
    let year = parseInt(yearSelector.value);

    if (this.checkStringState(month, year)){
      let currentConcerts = await Concerts.getByMonthConcerts(month, year) as Array<ConcertType>;
      let concerts = this.state.concerts;
      for (let i = 0; i < currentConcerts.length; i++) {
        concerts.push(currentConcerts[i]);
      }

      this.setState({concerts: concerts});
    }
    this.addNewString(month, year);
  }

  addNewString = (month: number, year: number) => {
    let strings = this.state.strings;
    for (let i = 0; i < strings.length; i++){
      if (strings[i].month === month && strings[i].year === year){
        return;
      }
    }

    strings.push({ month: month, year: year });
    this.setState({strings: strings});
  }

  checkStringState = (month: number, year: number): boolean => {
    let strings = this.state.strings;
    for (let i = 0; i < strings.length; i++) {
      if (strings[i].month === month && strings[i].year === year) {
        return false;
      }
    }
    return true;
  }

  handleEventClick = (event) => {
    let key = event.event._def.publicId;
    this.setState({currentConcert: this.state.concerts[key]});
  };

  render() {
    let yearOptions: React.ReactNode[] = [];

    for (let i = this.state.year; i >= 2008; i--){
      yearOptions.push(<option value={i} key={"yearSelector" + i}>{i}</option>)
    }

    let events: Array<any> = [];
    let concerts = this.state.concerts;
    for (let i = 0; i < concerts.length; i++){
      events.push({
        id: i,
        title: concerts[i].fileTitle.slice(11),
        start: concerts[i].fileTitle.slice(0, 10)
      })
    }

    return (
      <div id='homeCalendarView'>
        <p>Select a year and month, click Load Month, and navigate there on the calendar. The concert you click will appear below it.  </p>
        <label htmlFor="yearSelector">Year: </label>
        <select name="yearSelector" id="yearSelector" defaultValue={this.state.year}>
          {yearOptions}
        </select>
        <label htmlFor="monthSelector">Month: </label>
        <select name="monthSelector" id="monthSelector" defaultValue={this.state.month}>
          <option value={0}>January</option>
          <option value={1}>February</option>
          <option value={2}>March</option>
          <option value={3}>April</option>
          <option value={4}>May</option>
          <option value={5}>June</option>
          <option value={6}>July</option>
          <option value={7}>August</option>
          <option value={8}>September</option>
          <option value={9}>October</option>
          <option value={10}>November</option>
          <option value={11}>December</option>
        </select>
        <button onClick={this.updateMonth}>Load Month</button>
        <div id='forDesktopUsers'>
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView='dayGridMonth'
            weekends={true}
            events={events}
            eventContent={renderEventContent}
            eventClick={this.handleEventClick}
            contentHeight={"800px"}
            />
        </div>
        <div id='forMobileUsers'>
          <p>Please use the desktop site for a full calendar view. This is the list of events for the semester. </p>
          <FullCalendar
            plugins={[listPlugin]}
            initialView='listMonth'
            weekends={true}
            events={events}
            eventClick={this.handleEventClick}
            eventContent={renderEventContent}
          />
        </div>
        <hr/>
        {this.state.currentConcert !== null && (<IndividualConcert concert={this.state.currentConcert} queue={this.props.adjustQueue} talking={this.props.talking} />)}
      </div>
    )
  }
}

function renderEventContent(eventInfo) {
  return (
    <span><b>{eventInfo.timeText}</b>  <span>{eventInfo.event.title}</span></span>
  )
}