import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { AppContext } from './_Context';

export class Tools extends React.Component {
  static contextType = AppContext;
  render() {
    const context = this.context;
    return (
      <div id='techPage'>
        <Helmet>
          <title>Tech</title>
        </Helmet>
        <h1>Tech</h1>
        <div id='fullButtons'>
          <Link to="stagebuilder"><div><p>Stage Builder</p></div></Link>
        </div>
        {context.user.sl > 1 && (
          <div id='calendarSection'>
            <h2>Studio Calendars</h2>
            <h3>Eccles Calendar</h3>
            <iframe src='https://calendar.google.com/calendar/u/0/embed?src=psbj0etie4hba30s42vr51ri20@group.calendar.google.com&ctz=America/Denver&fbclid=IwAR2tj_whBc7FuvGsWCl90bKj4UQpVn4ckwwLiw_vjko9CT-vGBRDK9WQn78' title='Studio A' width="100%" height="500px" />
            <h3>Studio A Calendar</h3>
            <iframe src='https://calendar.google.com/calendar/u/0/embed?src=snowcollegemusictech@gmail.com&ctz=America/Denver&fbclid=IwAR3V4H_x7dMG5akswjchogg1P7bC-3NULApWFNvu0yttbkbuoDBszoo1Z9c&pli=1' title='Studio A' width="100%" height="500px" />
            <h3>Studio B Calendar</h3>
            <iframe src='https://calendar.google.com/calendar/u/0/embed?src=lncg1uk8npnq197gv934hr7jak@group.calendar.google.com&ctz=America/Denver&fbclid=IwAR2bA195P_7xl4jX3L6YJHEu4YfwSg9xI5fdCJbWVQpXzbfXlfWCGaNbCHI' title='Studio A' width="100%" height="500px" />
          </div>
        )}
      </div>
    );
  }
}
