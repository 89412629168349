import React from "react";
import { ArrowBool } from "./TinyArrow.tsx";

let octoberObject: MonthDisplayProps = {
  month: "October", 
  year: 2024, 
  title: "A Single Scale",
  driveLink: "https://drive.google.com/drive/folders/18Y4cGAQLi4l6a7TvdhCGAqQsSljhJ8dS?usp=drive_link",
  explanation: `Start with a note. In your scale, you get the 1, b2, 5, and b6 of that note. After you play at least 2 notes from that range, you can change your base note to a new "scale". You can repeat this as often as you want to.  
You can also invert it if you don't want the 5th in a particular spot, just by using 1, b2, 4, b5, which is the above scale with the lower two notes moved an octave higher, so it still counts. 
And that's all! This month will be more piano/keyboard focused, November will be more soloist-focused, and December will be vocal-focused.  `
}

export class CompChallenge extends React.Component {
  componentDidMount() {
    document.title = "Composition Challenge";
  }

  render() {
    return (
      <div id='compChallengePage'>
        <h1>Composition Challenge</h1>
        <p>Make sure to send your final draft to <u>composition@codydhowell.com</u>, with a required name, and a score and audio if you have it.
          If you haven't heard from me in a couple days (that it was added to the drive), reach out to Cody Howell directly, prefarably through Facebook. </p>
        
        <MonthDisplay chal={octoberObject}/>
      </div>
    )
  }
}

type MonthDisplayProps = {
  month: string, 
  year: number, 
  title: string, 
  driveLink: string,
  explanLink?: string,
  sampleVideo?: string,
  explanation: string
}

class MonthDisplay extends React.Component<{chal: MonthDisplayProps}, {open: boolean}> {
  constructor(props){
    super(props);
    this.state = {
      open: false
    }
  }

  toggleOpen = () => {
    this.setState({open: !this.state.open});
  }

  render() {
    let props = this.props.chal;
    return(
      <div className="monthlyChallenge">
        <h2 onClick={this.toggleOpen} style={{cursor: "pointer"}}>{props.month} {props.year} - {props.title} <ArrowBool up={this.state.open}/></h2>
        {this.state.open && (
          <div className="insideMonthlyChallenge">
            <p><a href={props.driveLink} target="_blank" rel="noreferrer">Drive Link (completed compositions)</a></p>
            {props.explanLink !== undefined && (<p><a href={props.explanLink} target="_blank" rel="noreferrer">Explanation Video</a></p>)}
            {props.sampleVideo !== undefined && (<p><a href={props.sampleVideo} target="_blank" rel="noreferrer">Sample Video</a></p>)}
            <h3>Rules</h3>
            <pre style={{fontFamily: "museo", textWrap: "wrap"}}>
              {props.explanation}
            </pre>
          </div>
        )}
      </div>
    )
  }
}