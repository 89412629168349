import React from 'react';
import { AppContext } from './_Context';
import { Helmet } from 'react-helmet';
import { Concerts, StorageService } from '../firebase';

export class Sherry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      concerts: [],
    }
  }
  static contextType = AppContext;

  componentDidMount() {
    Concerts.getProgramlessConcerts()
      .then(concertList => this.setState({ concerts: concertList }))
      .catch(error => console.error('Error fetching programless concerts:', error.message));
  }

  // handleFileUpload = (title, event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //       const formData = new FormData();
  //       formData.append('file', file);
  //       formData.append('uid', this.context.user.uid); // Replace with actual UID WORKS
  //       formData.append('title', title);

  //       formData.forEach(e => {
  //         console.log(e);
  //       })

  //       console.log(formData);

  //       // fetch('https://us-central1-snowcollegeconcerts.cloudfunctions.net/uploadFile', {
  //       //     method: 'POST',
  //       //     body: formData,
  //       // })
  //       // .then(response => response.json())
  //       // .then(data => {
  //       //     console.log('Success:', data);
  //       // })
  //       // .catch((error) => {
  //       //     console.error('Error:', error);
  //       // });
  //   }
  // }

  updateConcert = async (concertID, value) => {
    await Concerts.updateProgramValue(concertID, value);
    let concerts = this.state.concerts; 
    for (let i = 0; i < concerts.length; i++){
      if (concerts[i].fileTitle === concertID){
        concerts.splice(i, 1);
        break;
      }
    }
    this.setState({concerts: concerts});
  }

  render() {
    let concertList = [];
    if (this.state.concerts.length > 0) {
      for (let i = 0; i < this.state.concerts.length; i++) {
        let uniqueID = this.state.concerts[i].fileTitle;
        concertList.push(
          <div className='individualConcert' key={uniqueID}>
            <p className='title'>{this.state.concerts[i].fileTitle}</p>
            <div>
              <button onDoubleClick={() => this.updateConcert(uniqueID, "In Progress")}>Set as In Progress</button>
              <button onDoubleClick={() => this.updateConcert(uniqueID, "No Program")}>Set as Doesn't Exist</button>
            </div>
          </div>
        );
      }
    }
    const context = this.context;
    return (
      <div id='sherrysPage'>
        <Helmet>
          <title>Sherry's Page</title>
        </Helmet>
        {(context.user.sl === 7 || context.user.sl === 9) ? (
          <>
            <h1>Sherry</h1>
            <p>Hi, Sherry! This is a page only you and I have access to. I hope this page will help in getting programs and keeping things organized.
              You can let me know if there's something you want me to change about it. </p>
            <p>For now, until we get a better solution, there's <a href='https://drive.google.com/drive/folders/1mafnRsanpEzmohxmaIOY3WBtm5iQ93IS?usp=sharing' target='_blank' rel='noreferrer'>this Google Drive link</a>.
              As long as you're signed into a Google account, you can send me files directly. I'll check it every once in a while and update all
              the concerts inside. </p>
            <p>Below, there will be a list of every concert since Spring 2022 (the last update I have from you), and if you send me a program, 
              you can mark it off of this list. All the buttons are double click, and the item should disappear once it does it. </p>

            {concertList.length > 0 && (
              <div id='concertDisplay'>
                <p>These concerts don't currently have programs attached to them. </p>
                {concertList}
              </div>
            )}
          </>
        ) : (
          <p>This page is not for you. </p>
        )}
      </div>
    );
  }
}
