import React from 'react';
import { Pipeline } from './Pipeline';
import { AdminChecks, AdminRequests, Init } from '../firebase';
import { AppContext } from './_Context';
import { Link } from 'react-router-dom';
import { text } from 'd3';
import { Helmet } from 'react-helmet';

export class Directors extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      formCount: 0, 
      string: ""
    }
  }
  static contextType = AppContext;

  handleSubmit = async (event) => {
    event.preventDefault();
    const form = document.getElementById('calendarRequestForm');
    const formData = new FormData(form);

    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });

    data["type"] = "calendarEventRequest";

    await AdminChecks.importAdminCheckCalendar(data);
    window.location.reload();
  }

  handleDirectorMessage = async (event) => {
    event.preventDefault();
    const context = this.context;

    let obj = {};
    obj["type"] = "directorMessage";
    obj["name"] = context.user.name;
    obj["title"] = document.querySelector('#header').value;
    obj["body"] = document.querySelector('#body').value;

    // console.log(obj);
    await AdminChecks.addDirectorMessage(obj);
    window.location.reload();
  }

  render() {
    const context = this.context;
    let textareaPlaceholder = [
      'Use this area to send me messages. Here are some ideas: ',
      'Can you edit the concert on April 23rd, 2015?',
      'Can you add _____ new filter to the Database view (not yet available, by the way)',
      'Can you give me all the data we have for _____ concert?',
      'Can I request you re-edit _____ concert?',
      'This is something that I want to be able to do... _____'
    ];
    const placeholderText = textareaPlaceholder.join('\n');
    return (
      <div id='directorsPage'>
        <Helmet>
          <title>Directors</title>
        </Helmet>
        <h1>Directors</h1>
        {context.user.sl >= 5 ? (
        <>
        <p>Hello! This will be a spot that I'll leave updates directly with you, and you can do a couple things: </p>
        <ul>
          <li>See the current concerts awaiting audit</li>
          <li>Quickly request new calendar events</li>
          <li>See the current working spreadsheet of the concerts (please don't judge my notes)</li>
          <li>Send a message directly to me, requesting something like:
            <ul>
              <li>A specific concert from the spreadsheet be edited</li>
              <li>An update on an upcoming concert</li>
              <li>A feature request</li>
            </ul>
          </li>
        </ul>
        <p><b>Current announcement:</b> {context.messages.directors}</p>
        <div id='pages'>
          <div className='extraPage'><Pipeline/></div>
          <div className='extraPage'>
            <div id='calendarRequest'>
              <h1>Calendar Request</h1>
                <p>Is there an event that isn't on the calendar? Please request it using this form: </p>
                <form id='calendarRequestForm'>
                  <label htmlFor='submitter'>Your Name: </label>
                  <input type='text' name='submitter' id='submitter' defaultValue={context.user.name} placeholder='Req. your name in Account!' />
                  <br/>
                  <label htmlFor='title'>Name of Concert: </label>
                  <input type='text' name='title' required/>
                  <br/>
                  <label htmlFor='start'>Date: </label>
                  <input type='datetime-local' name='start' required/>
                  <br/>
                  <label htmlFor='location'>Location: </label>
                  <select name='location' required>
                    <option value={"Concert Hall"}>Concert Hall</option>
                    <option value={"Recital Hall"}>Recital Hall</option>
                    <option value={"Black Box"}>Black Box Theatre</option>
                    <option value={"Theatre"}>Jorgensen Theatre</option>
                    <option value={"Other"}>Other</option>
                  </select>
                  <br/>

                  <button type='submit' onClick={this.handleSubmit}>Submit</button>
                </form>
                {this.state.formCount > 0 && (
                  <p>Number of Concerts Requested: {this.state.formCount}</p>
                )}

            </div>   
          </div>
          <div className='extraPage spreadsheet'><iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSiXSBf1-coR1mg04zNLS9bvKmZJ9TBHT7r8ONcUXPBKH369x36HhstJQCL3vO5UFvgzj2J7ziS7WEH/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false" height={"98%"} width={"99%"} title='Audio Spreadsheet'></iframe></div>
          <div className='extraPage requestForm'>
            <h1>Message the Admin</h1>
              <label htmlFor='header'>Your Name:</label>
              <input type='text' name='name' id='name' defaultValue={context.user.name} placeholder='Request your name in Account!'/>
              <label htmlFor='header'>Title/Subject Line:</label>
              <input type='text' name='header' id='header' placeholder='Title/Subject Line'/>
              <label htmlFor='boyd'>Body:</label>
              <textarea height="100%" width="100%" name='body' id='body' placeholder={placeholderText}/>
              <button onClick={this.handleDirectorMessage}>Submit</button>
          </div>
        </div>
        </>) : (
          <p>You can't be here.</p>
        )}
      </div>
    );
  }
}
