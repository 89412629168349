import { Link } from 'react-router-dom';
import React from 'react';

export class ExpandingSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      mouseOverNewItem: false,
      newItemPosition: { top: 0, left: 0 } // Positioning of new item
    };
    this.leaveTimeout = null;
  }
  
  componentWillUnmount() {
    clearTimeout(this.leaveTimeout);
  }
  
  mousedOver = (e) => {
    this.setState({ expanded: true });
    const rect = e.target.getBoundingClientRect();
    this.setState({
      newItemPosition: {
        top: rect.top - 10,
        left: rect.left + 3,
      },
    });
  };
  mousedOff = () => {
    this.leaveTimeout = setTimeout(() => {
      // Check if the mouse is over the new item before hiding
      if (!this.state.mouseOverNewItem) {
        this.setState({ expanded: false });
      }
    }, 100);
  };
  
  handleMouseEnterNewItem = () => {
    this.setState({ mouseOverNewItem: true });
  };
  
  handleMouseLeaveNewItem = () => {
    this.setState({ mouseOverNewItem: false });
    this.setState({ expanded: false });
  };

  render() {
    let expandedItems = [];
    if (this.props.mPath.length > 0) {
      for (let i = 0; i < this.props.mPath.length; i++) {
        expandedItems.push(
          <div key={this.props.mLabel[i]} className='expandedLink'><Link to={this.props.mPath[i]}><li>{this.props.mLabel[i]}</li></Link></div>
        );
      }
    }
    return (
      <>
        <Link to={this.props.Mpath} onMouseEnter={this.mousedOver} onMouseLeave={this.mousedOff}><li>{this.props.Mlabel}</li></Link>
        {this.state.expanded && this.props.mLabel.length > 0 && (
          <div className='expandedElements'
            onMouseEnter={this.handleMouseEnterNewItem}
            onMouseLeave={this.handleMouseLeaveNewItem}
            style={{
              top: this.state.newItemPosition.top,
              left: this.state.newItemPosition.left + 100, // Offset the left position
            }}>{expandedItems}</div>
        )}
      </>
    );
  }
}
